import React from 'react'
import PropType from 'prop-types';

import {
    Star,
    District,
    PriceLabel,
    MerchantImage
} from '../../assets/'

import './Merchant.css';

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';
import { t } from '../../miscellaneous/language';
import ReactGA from "react-ga4";
import { getMerchantDetails } from '../../application/actions/merchantActions';
import { useDispatch } from 'react-redux';

const FilterDistrictMerchantItem = ({ merchants }) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const clickMerchant = (id, partner_level) => {
        dispatch(getMerchantDetails(id));
        history.push(`/merchant/${id}`)
        console.log(partner_level);
        ReactGA.event(`Click ${partner_level === 1 ? 'partner': 'non-partner'}`, {
            category: "Click Merchant",
            action: "Click Merchant",
            label: "Click Merchant", // optional
            value: 1, // optional, must be a number
        });
    }

    return (
        <>
            {merchants.map((merchant, index) => (
                <div key={index} className="merchant-list-card " onClick={(e) => { merchant.is_closed === 1 ? alert(t.alert.merchantClose) : clickMerchant(merchant.id, merchant.partner_level) }}>
                    {merchant.is_closed === 0 ?
                        <div className="img-container merchant-list-img">
                            <img src={merchant.image} className="merchant-list-img" onError={(event) => event.target.src = MerchantImage} alt="" />
                            <span className={merchant.partner_level == 0 ? "badge-np" : "badge"}>
                                {merchant.partner_level == 0 ? "non-partner" : "Partner"}
                            </span>
                            {/* <div className="merchant-list-img-status">status</div> */}
                        </div>
                        :
                        <div className="img-container merchant-list-img" style={{ backgroundImage: "url(" + merchant.image + ")" }} >
                            <p className="merchant-list-img-status" style={{ color: 'white', textAlign: 'center', paddingTop: 40, fontWeight: 'bold', fontSize: 20 }}>{merchant.is_closed === 0 ? t.status.Open : t.status.Close}</p>
                            <span className={merchant.partner_level == 0 ? "badge-np" : "badge" }>
                                {merchant.partner_level == 0 ? "non-partner" : "Partner"}
                            </span>
                        </div>
                    }
                    <div className="merchant-list-description-container">
                        <span className="merchant-list-name">{merchant.name}</span>
                        <span className="merchant-list-desc">{merchant.desc}</span>
                        <div className="border"></div>
                        <div className="merchant-list-detail-container">
                            <div className="merchant-list-detail"><Star id="merchant-icon-info" fill="#FF6000" /> <span>{merchant.avg_rating}</span></div>
                            <div className="merchant-list-detail"><District id="merchant-icon-info" /> <span>{merchant.district}</span></div>
                            <div className="merchant-list-detail"><PriceLabel id="merchant-icon-info" /> <span>{formatCurrencyKStyle(merchant.min)} - {formatCurrencyKStyle(merchant.max)}</span></div>
                            {/* <div className="merchant-list-detail"><span className="merchant-list-detail-halal">NON HALAL</span></div> */}
                            {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? <HalalLogo id="halal-logo" /> : ""}</span></div> : ''} */}
                            {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? "halal" : "non-halal"}</span></div> : ''} */}
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

FilterDistrictMerchantItem.propTypes = {
    merchants: PropType.array.isRequired
}

export default FilterDistrictMerchantItem;
