import React from 'react'
import PropType from 'prop-types';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { GoPlus, GoDash } from "react-icons/go";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { removeFromCart, addQtyItem, removeQtyItem } from '../../application/actions/cartActions';

import { useCart } from '../../application/hooks';

import { formatCurrency, generateUUID } from '../../miscellaneous/helpers/utils';

import './Discount.css'
import '../Merchant/MerchantNewUI.css'
import { ItemImage } from '../../assets';
import { t } from '../../miscellaneous/language';
import { useHistory } from 'react-router-dom';

const MerchantDetailDiscountItem = ({ item, detailFunction, typeUI }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const store = useSelector((state) => ({
        merchants: state.merchants.details
    }), shallowEqual);

    const { addToCart, checkItemOnCart, checkItemOnCartNoAddon, itemQtyCounter } = useCart(item.id);
    
    const MainFunction = {
        AddonList: (data) => {
            history.push(`/merchant/${store?.merchants?.id}/addon`, data);
        }
    }

    const onItemAddToCart = (id) => {
        if (itemQtyCounter(id) >= 1) {
            dispatch(addQtyItem(id));
        } else {
            addToCart({ ...item, qty: 1, orderDesc: null, uuid_cart: generateUUID() }, store.merchants);
        }
    }

    const onItemRemoveFromCart = (id) => {
        if (itemQtyCounter(id) > 1) {
            dispatch(removeQtyItem(id));
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="alert">
                            <h1>{t.alert.alert}</h1>
                            <p>{t.alert.cartEmpty}</p>
                            <div className="alert-button-container">
                                <button
                                    class="yes-button"
                                    onClick={() => {
                                        dispatch(removeFromCart(id));
                                        onClose();
                                    }}
                                >
                                    {t.btn.yes}
                                </button>
                                <button
                                    class="no-button"
                                    onClick={onClose}
                                >
                                    {t.btn.no}
                                </button>
                            </div>
                        </div>
                    )
                }
            })
        }
    }

    return (
        <div className="merchant-menu-list-container merchant-menu-list-container-slider">
            <div className="merchant-menu-list-wrapper" onClick={(e) => detailFunction(item, "None", 'item', item.merchants_id)}>
                <div className="merchant-menu-list-header-slider">
                    <img src={item?.image ? item.image : "https://id-marketplace.sgp1.cdn.digitaloceanspaces.com/files/merchant/70Ht3pFljemj63FACWmkjUitnLzBKHFo0RtzzYoz.png"} onError={(e) => (e.target.src = ItemImage)} alt={'Image for item: ' + item?.name} className='' />
                </div>
                <div className="merchant-menu-list-footer-slider">
                    <span className='merchant-menu-list-footer-title'>{item?.name}</span>
                    <span className='merchant-menu-list-footer-desc'>{item?.desc === null || item?.desc?.length <= 0 ? <div>&nbsp;</div> : item?.desc}</span>
                    {
                        item.is_empty !== 1 ?
                            <div className="button-add-item-center-disabled">
                                <p className="text-sold-out">{t.text.empty}</p>
                            </div> 
                            :
                            (checkItemOnCartNoAddon(item.id) && item.item_cat_addon.length == 0) ?
                                (<div className='content-merchant-menu-list-bottom-button'>
                                    <button
                                        className='rectangel-minus'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onItemRemoveFromCart(checkItemOnCartNoAddon(item.id)?.uuid_cart);
                                        }}
                                    >
                                        <GoDash className="content-merchant-menu-list-button-counter-icon" />
                                    </button>
                                    <div className='price-pcs'>
                                        <p align='center' style={{ fontSize: 12 }}>{itemQtyCounter(checkItemOnCartNoAddon(item.id)?.uuid_cart) === undefined ? 0 : itemQtyCounter(checkItemOnCartNoAddon(item.id)?.uuid_cart)}</p>
                                        <p align='center' style={{ fontSize: 12, color: "#969696" }}>{formatCurrency(item?.total * (itemQtyCounter(checkItemOnCartNoAddon(item.id)?.uuid_cart) === undefined ? 0 : itemQtyCounter(checkItemOnCartNoAddon(item.id)?.uuid_cart)))}</p>
                                    </div>
                                    <button
                                        className='rectangel-plus'
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onItemAddToCart(checkItemOnCartNoAddon(item.id)?.uuid_cart)
                                        }}
                                    >
                                        <GoPlus className="content-merchant-menu-list-button-counter-icon" />
                                    </button>
                                </div>)
                                :
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        item.item_cat_addon.length > 0 ? 
                                        MainFunction.AddonList(item):
                                        onItemAddToCart()
                                    }}
                                    className="button-add-item-center">
                                    <p className="text-selling-price">{formatCurrency(item?.total)}</p>
                                    {item.discount === 1 && <p className="text-actual-price ">{formatCurrency(item?.price)}</p>}
                                </div>
                    }
                </div>
            </div>
        </div>
    )
}

MerchantDetailDiscountItem.propTypes = {
    item: PropType.shape({
        image: PropType.string,
        name: PropType.string
    }).isRequired
}

export default MerchantDetailDiscountItem
