import miscReducer from './miscReducer';
import merchantReducer from './merchantReducer';
import exploreReducer from './exploreReducer';
import promoReducer from './promoReducer';
import historyReducer from './historyReducer';
import cartReducer from './cartReducer';
import authReducer from './authReducer';

const rootReducer = {
    app: miscReducer,
    merchants: merchantReducer,
    explore: exploreReducer,
    promo: promoReducer,
    history: historyReducer,
    cart: cartReducer,
    auth: authReducer
}

export default rootReducer;