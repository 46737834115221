import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropType from 'prop-types';
import { BiSearch } from "react-icons/bi";

import { searchMerchantCategoryKeyword } from '../../../../application/actions/merchantActions';

import './SearchBar.css'
import { t } from '../../../../miscellaneous/language';

const MerchantSearch = () => {
    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = React.useState('');

    const { isLoading } = useSelector((state) => ({
        isLoading: state.app.loading
    }));

    const onSearchChange = (e) => {
        setSearchInput(e.target.value);
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();

            dispatch(searchMerchantCategoryKeyword(searchInput.trim().toLowerCase()));
        }
    }

    return (
        <div className="search-input-container">
            <input
                type="text"
                className="search-input"
                placeholder={t.placeholder.searchCategory}
                onChange={onSearchChange}
                onKeyUp={onKeyUp}
                readOnly={isLoading}
                value={searchInput}
            />
            <div className='search-input-button-wrapper'>
                <BiSearch size={25} className="search-input-button" />
            </div>
        </div>
    )
}

MerchantSearch.defaultProps = {
    requestStatus: null
}

MerchantSearch.propTypes = {
    merchants: PropType.object,
    isLoading: PropType.bool,
    requestStatus: PropType.string
}

export default MerchantSearch