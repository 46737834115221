import React, { useState } from "react";
import MerchantMenuList from "./MerchantMenuList";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "./Merchant.css";
import { t } from "../../miscellaneous/language";
import { Chip } from "@material-ui/core";
import { Swiper, SwiperSlide } from 'swiper/react';

const MerchantMenuCategory = ({ items, detailFunction, onClick, active }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
        <Chip onClick={onClick} variant={'outlined'} style={{ marginLeft:10, display:'flex', flexDirection:'row', flexWrap:'wrap', alignItems:'center', borderColor: active && 'rgba(255,201,0,1)' }} label={items.name} />        
    </>
  );
};

export default MerchantMenuCategory;
