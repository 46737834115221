import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import PropType from 'prop-types';
import { BiSearch } from "react-icons/bi";

import { searchMerchantItem } from '../../../../application/actions/merchantActions';

import './SearchBar.css'
import {t} from '../../../../miscellaneous/language'

const MerchantItemSearch = () => {
    const dispatch = useDispatch();

    const [searchInput, setSearchInput] = React.useState('');
    
    const { isLoading } = useSelector((state) => ({ 
        isLoading: state.app.loading
    }));

    const onSearchChange = (e) => {
        setSearchInput(e.target.value);
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            
            console.log(searchInput);

            dispatch(searchMerchantItem(searchInput.trim().toLowerCase()));
        }
    }

    return (
        <div className="search-input-container">
            <input 
                type="text" 
                className="search-input merchant-detail-search" 
                onChange={onSearchChange}
                onKeyUp={onKeyUp}
                placeholder={t.placeholder.searchItem}
                readOnly={isLoading}
                value={searchInput}
            />
            <div className='search-input-button-wrapper'>
                <BiSearch size={25} className="search-input-button" />
            </div>
        </div>
    )
}

MerchantItemSearch.defaultProps = {
    requestStatus: null
}

MerchantItemSearch.propTypes = {
    merchants: PropType.object,
    isLoading: PropType.bool,
    requestStatus: PropType.string
}

export default MerchantItemSearch