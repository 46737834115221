import {
    AUTHENTICATE_USER,
    SET_USERDATA,
    UPDATE_NAME
} from '../../miscellaneous/constants';

export const updateName = (userID, lat, lon, city, name, host, addressName) => ({
    type: UPDATE_NAME,
    payload: { userID, lat, lon, city, name, host, addressName }
});

export const setUserdata = (userID, lat, lon, city, host, name, addressName, running_order) => ({
    type: SET_USERDATA,
    payload: { userID, lat, lon, city, host, name, addressName, running_order }
});

export const authenticateUser = () => ({
    type: AUTHENTICATE_USER
})