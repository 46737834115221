import  { GET_HISTORY, UPDATE_HISTORY_RATING } from '../../../miscellaneous/constants';
import {
    select,
    call,
    put
} from 'redux-saga/effects';

import { actionMessage } from '../../../miscellaneous/helpers/utils';

import { setLoading, setRequestStatus } from '../../actions/miscActions';
import { 
    getHistorySuccess
} from '../../actions/historyActions';

import { getHistoryAPI, updateRatingAPI } from '../../../infrastructure/services/api/History';

import { history } from '../../routers/Router';

export const getUserData = ((state) => state.auth.userData);

function* initRequest() {
    yield put(setLoading(true));
    yield put(setRequestStatus(null));
}

function* handleError(e) {
    yield put(setLoading(false));
    yield put(setRequestStatus(e?.message || 'Failed to fetch data history'));
    console.log('ERROR: ', e);
}

function* handleAction(location, message, status) {
    if (location) yield call(history.push, location);
    yield call(actionMessage, message, status);
}

function* historySaga({ type, payload }) {
    const userData = yield select(getUserData);

    switch (type) {
        case GET_HISTORY:
            try {
                yield initRequest();

                const response = yield call(getHistoryAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city,
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.pageSize
                );
                console.log(response);

                if (response.result.length === 0) {
                    handleError('No data found.');
                } else {
                    yield put(getHistorySuccess({
                        history: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        case UPDATE_HISTORY_RATING:
            try {
                yield initRequest();

                const response = yield call(updateRatingAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city,
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.orderId,
                    payload.starValue
                );
                console.log(response);

                if (response.success) {
                    const secondCall = yield call(getHistoryAPI,
                        userData.userID, 
                        userData.lat, 
                        userData.lon, 
                        userData.city,
                        userData.host,
                        userData.name,
                        userData.addressName, 
                        payload.pageSize
                    );
                    console.log(secondCall);
    
                    if (secondCall.result.length === 0) {
                        handleError('No data found.');
                    } else {
                        yield put(getHistorySuccess({
                            history: secondCall.result,
                            total: secondCall.result.length
                        }));
                        yield put(setRequestStatus(''));
                    }
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        default: { throw new Error(`Unexpected action type ${type}`); }
    }
}

export default historySaga;