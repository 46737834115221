import {
    GET_PROMO_MERCHANTS,
    GET_PROMO_MERCHANTS_SUCCESS,
    GET_PROMO_MERCHANT_ITEMS,
    GET_PROMO_MERCHANT_ITEMS_SUCCESS,
    GET_PROMO_NEW_MERCHANT,
    GET_PROMO_NEW_MERCHANT_SUCCESS
} from '../../miscellaneous/constants';

export const getPromoMerchants = (pageSize = 1) => ({
    type: GET_PROMO_MERCHANTS,
    payload: { pageSize }
});

export const getPromoMerchantsSuccess = (merchants) => ({
    type: GET_PROMO_MERCHANTS_SUCCESS,
    payload: merchants
});

export const getPromoMerchantItems = (pageSize = 1) => ({
    type: GET_PROMO_MERCHANT_ITEMS,
    payload: { pageSize }
});

export const getPromoMerchantItemsSuccess = (merchants) => ({
    type: GET_PROMO_MERCHANT_ITEMS_SUCCESS,
    payload: merchants
});

export const getPromoNewMerchant = (pageSize = 1) => ({
    type: GET_PROMO_NEW_MERCHANT,
    payload: { pageSize }
});

export const getPromoNewMerchantSuccess = (merchants) => ({
    type: GET_PROMO_NEW_MERCHANT_SUCCESS,
    payload: merchants
});