import React from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { GoPlus, GoDash } from "react-icons/go";

import {
  removeFromCart,
  addQtyItem,
  removeQtyItem,
} from "../../application/actions/cartActions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useCart } from "../../application/hooks";

import {
  formatCurrency,
  generateUUID,
} from "../../miscellaneous/helpers/utils";

import PromoBadge from "../components/common/Badge/PromoBadge";
import { ItemImage } from "../../assets";
import PizzaDummy from "../../assets/images/pizza-dummy.png";
import PizzaDummy2 from "../../assets/images/pizza-dummy2.png";

import "./Merchant.css";
import { t } from "../../miscellaneous/language";

import { useHistory } from "react-router-dom";
import MerchantItemAddExisting from "../components/common/Modal/MerchantItemAddExisting";

const MerchantMenuList = ({
  item,
  detailFunction,
  categoryName,
  loadFunction,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const store = useSelector(
    (state) => ({
      merchants: state.merchants.details,
      cart: state.cart,
      auth: state.auth,
    }),
    shallowEqual
  );

  const { addToCart, checkItemOnCart, itemQtyCounter, checkItemOnCartNoAddon } =
    useCart(item.id);

  const MainFunction = {
    AddonList: (data) => {
      history.push(`/merchant/${store?.merchants?.id}/addon`, data);
    },
  };

  const onItemAddToCart = (id) => {
    if (itemQtyCounter(id) >= 1) {
      dispatch(addQtyItem(id));
    } else {
      addToCart(
        { ...item, qty: 1, orderDesc: null, uuid_cart: generateUUID() },
        store.merchants
      );
    }

    console.log(checkItemOnCart(id));
  };

  const onItemRemoveFromCart = (id) => {
    if (itemQtyCounter(id) > 1) {
      dispatch(removeQtyItem(id));
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert">
              <h1>{t.alert.cart}</h1>
              <p>{t.alert.cartEmpty}</p>
              <div className="alert-button-container">
                <button
                  class="yes-button"
                  onClick={() => {
                    dispatch(removeFromCart(id));
                    onClose();
                  }}
                >
                  {t.btn.yes}
                </button>
                <button class="no-button" onClick={onClose}>
                  {t.btn.no}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  return (    
      <div className="merchant-menu-list-container-main merchant-menu-list-container-main-top20">
        <div
          className="merchant-menu-list-wrapper"
          onClick={() => detailFunction(item, categoryName, "item")}
        >
          <div className="merchant-menu-list-header-main">
            {loadFunction?.isLoaded ? (
              <img
                src={
                  item?.image
                    ? item.image
                    : "https://id-marketplace.sgp1.cdn.digitaloceanspaces.com/files/merchant/70Ht3pFljemj63FACWmkjUitnLzBKHFo0RtzzYoz.png"
                }
                onError={(e) => (e.target.src = ItemImage)}
                alt={"Image for item: " + item?.name}
                className=""
              />
            ) : (
              <img src={ItemImage} />
            )}
            <img
              src={
                item?.image
                  ? item.image
                  : "https://id-marketplace.sgp1.cdn.digitaloceanspaces.com/files/merchant/70Ht3pFljemj63FACWmkjUitnLzBKHFo0RtzzYoz.png"
              }
              onError={(e) => (e.target.src = ItemImage)}
              alt={"Image for item: " + item?.name}
              className=""
              onLoad={loadFunction?.handleImageLoad}
              style={{ display: "none" }}
            />
          </div>
          <div className="merchant-menu-list-footer-main">
            <span className="merchant-menu-list-footer-title">
              {item?.name}
            </span>
            <span className="merchant-menu-list-footer-desc">
              {item?.desc === null || item?.desc?.length <= 0 ? (
                <div>&nbsp;</div>
              ) : (
                item?.desc
              )}
            </span>
            {item.is_empty !== 1 ? (
              <div className="button-add-item-center-disabled">
                <p className="text-sold-out">{t.text.empty}</p>
              </div>
            ) : // conditional has item addon
            item.item_cat_addon.length > 0 ? (
              // conditional store cart item
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  item.item_cat_addon.length > 0
                    ? checkItemOnCartNoAddon(item?.id)
                      ? detailFunction(item, categoryName, "itemaddexsisting")
                      : MainFunction.AddonList(item)
                    : onItemAddToCart();
                }}
                className="button-add-item-center"
              >
                <p className="text-selling-price">
                  {formatCurrency(item?.total)}
                </p>
                {item.discount === 1 && (
                  <p className="text-actual-price ">
                    {formatCurrency(item?.price)}
                  </p>
                )}
              </div>
            ) : checkItemOnCartNoAddon(item.id) ? (
              <div className="content-merchant-menu-list-bottom-button">
                <button
                  className="rectangel-minus"
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemRemoveFromCart(
                      checkItemOnCartNoAddon(item.id)?.uuid_cart
                    );
                  }}
                >
                  <GoDash className="content-merchant-menu-list-button-counter-icon" />
                </button>
                <div className="price-pcs">
                  <p align="center" style={{ fontSize: 12 }}>
                    {itemQtyCounter(
                      checkItemOnCartNoAddon(item.id)?.uuid_cart
                    ) === undefined
                      ? 0
                      : itemQtyCounter(
                          checkItemOnCartNoAddon(item.id)?.uuid_cart
                        )}
                  </p>
                  <p align="center" style={{ fontSize: 12, color: "#969696" }}>
                    {formatCurrency(
                      item?.total *
                        (itemQtyCounter(
                          checkItemOnCartNoAddon(item.id)?.uuid_cart
                        ) === undefined
                          ? 0
                          : itemQtyCounter(
                              checkItemOnCartNoAddon(item.id)?.uuid_cart
                            ))
                    )}
                  </p>
                </div>
                <button
                  className="rectangel-plus"
                  onClick={(e) => {
                    e.stopPropagation();
                    onItemAddToCart(checkItemOnCartNoAddon(item.id)?.uuid_cart);
                  }}
                >
                  <GoPlus className="content-merchant-menu-list-button-counter-icon" />
                </button>
              </div>
            ) : (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  item.item_cat_addon.length > 0
                    ? MainFunction.AddonList(item)
                    : onItemAddToCart();
                }}
                className="button-add-item-center"
              >
                <p className="text-selling-price">
                  {formatCurrency(item?.total)}
                </p>
                {item.discount === 1 && (
                  <p className="text-actual-price ">
                    {formatCurrency(item?.price)}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>    
  );
};

export default MerchantMenuList;
