import { takeLatest } from 'redux-saga/effects';

import * as ACTION from '../../../miscellaneous/constants';
import merchantSaga from './merchantSaga';
import exploreSaga from './exploreSaga';
import promoSaga from './promoSaga';
import historySaga from './historySaga';
import cartSaga from './cartSaga';
import authSaga from './authSaga';

function* rootSaga() {
    yield takeLatest([
        ACTION.GET_MERCHANTS,
        ACTION.GET_MERCHANT_DETAILS,
        ACTION.SEARCH_MERCHANT,
        ACTION.FILTER_MERCHANT_NEAR_ME,
        ACTION.FILTER_MERCHANT_DISTRICT,

        ACTION.GET_CATEGORY_MERCHANTS,
        ACTION.SEARCH_MERCHANT_CATEGORY,
        ACTION.FILTER_MERCHANT_CATEGORY_NEAR_ME,
        ACTION.FILTER_MERCHANT_CATEGORY_DISTRICT
    ], merchantSaga);
    yield takeLatest([
        ACTION.EXPLORE_MERCHANT
    ], exploreSaga);
    yield takeLatest([
        ACTION.GET_PROMO_MERCHANTS,
        ACTION.GET_PROMO_MERCHANT_ITEMS,
        ACTION.GET_PROMO_NEW_MERCHANT
    ], promoSaga);
    yield takeLatest([
        ACTION.GET_HISTORY,
        ACTION.UPDATE_HISTORY_RATING
    ], historySaga);
    yield takeLatest([
        ACTION.SEND_ORDER,
        ACTION.REFRESH_ORDER_STATUS
    ], cartSaga);
    yield takeLatest([
        ACTION.UPDATE_NAME
    ], authSaga)
}

export default rootSaga;