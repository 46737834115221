import {
    GET_MERCHANTS,
    GET_MERCHANTS_SUCCESS,
    GET_MERCHANT_DETAILS,
    GET_MERCHANT_DETAILS_SUCCESS,
    GET_CATEGORY_MERCHANTS,
    CLEAR_CATEGORY_MERCHANT_STATE,
    GET_CATEGORY_MERCHANTS_SUCCESS,
    CANCEL_GET_MERCHANTS,
    CLEAR_MERCHANT_STATE,
    SEARCH_MERCHANT_KEYWORD,
    SEARCH_MERCHANT,
    SEARCH_MERCHANT_CATEGORY,
    SEARCH_MERCHANT_CATEGORY_SUCCESS,
    SEARCH_MERCHANT_SUCCESS,
    CLEAR_SEARCH_STATE,
    CLEAR_SEARCH_CATEGORY_STATE,
    FILTER_MERCHANT_NEAR_ME,
    FILTER_MERCHANT_CATEGORY_NEAR_ME,
    FILTER_MERCHANT_CATEGORY_NEAR_ME_SUCCESS,
    FILTER_MERCHANT_NEAR_ME_SUCCESS,
    FILTER_MERCHANT_DISTRICT,
    FILTER_MERCHANT_CATEGORY_DISTRICT,
    FILTER_MERCHANT_CATEGORY_DISTRICT_SUCCESS,
    FILTER_MERCHANT_DISTRICT_SUCCESS,
    CLEAR_FILTER_NEAR_ME_CATEGORY_STATE,
    CLEAR_FILTER_DISTRICT_CATEGORY_STATE,
    CLEAR_FILTER_NEAR_ME_STATE,
    CLEAR_FILTER_DISTRICT_STATE,
    SET_TYPE_STATE,
    SET_IS_LOAD_MORE,
    SEARCH_MERCHANT_ITEM,
    SEARCH_MERCHANT_CATEGORY_KEYWORD,
    CLEAR_MERCHANT_DETAILS
} from '../../miscellaneous/constants';

export const getMerchants = (pageSize = 1) => ({
    type: GET_MERCHANTS,
    payload: { pageSize }
});

export const getMerchantsSuccess = (merchants) => ({
    type: GET_MERCHANTS_SUCCESS,
    payload: merchants
});

export const getMerchantDetails = (merchantId) => ({
    type: GET_MERCHANT_DETAILS,
    payload: { merchantId }
})

export const getMerchantDetailsSuccess = (merchants) => ({
    type: GET_MERCHANT_DETAILS_SUCCESS,
    payload: merchants
})

export const getCategoryMerchants = (categoryId, pageSize = 1) => ({
    type: GET_CATEGORY_MERCHANTS,
    payload: { categoryId, pageSize }
});

export const clearCategoryMerchantState = () => ({
    type: CLEAR_CATEGORY_MERCHANT_STATE
});

export const getCategoryMerchantsSuccess = (merchants) => ({
    type: GET_CATEGORY_MERCHANTS_SUCCESS,
    payload: merchants
});

export const cancelGetMerchants = () => ({
    type: CANCEL_GET_MERCHANTS
});

export const clearMerchantState = () => ({
    type: CLEAR_MERCHANT_STATE
});

export const searchMerchantKeyword = (keyword) => ({
    type: SEARCH_MERCHANT_KEYWORD,
    payload: { keyword }
});


export const searchMerchant = (searchKey, pageSize = 1) => ({
    type: SEARCH_MERCHANT,
    payload: { searchKey, pageSize }
});

export const searchMerchantCategoryKeyword = (keyword) => ({
    type: SEARCH_MERCHANT_CATEGORY_KEYWORD,
    payload: { keyword }
});

export const searchMerchantCategory = (searchKey, categoryId, pageSize = 1) => ({
    type: SEARCH_MERCHANT_CATEGORY,
    payload: { searchKey, categoryId, pageSize }
});

export const searchMerchantItem = (searchKey) => ({
    type: SEARCH_MERCHANT_ITEM,
    payload: searchKey
});
  
export const searchMerchantSuccess = (merchants) => ({
    type: SEARCH_MERCHANT_SUCCESS,
    payload: merchants
});

export const searchMerchantCategorySuccess = (merchants) => ({
    type: SEARCH_MERCHANT_CATEGORY_SUCCESS,
    payload: merchants
});
  
export const clearSearchState = () => ({
    type: CLEAR_SEARCH_STATE
});

export const clearSearchCategoryState = () => ({
    type: CLEAR_SEARCH_CATEGORY_STATE
});

export const filterNearestMerchant = (pageSize = 1) => ({
    type: FILTER_MERCHANT_NEAR_ME,
    payload: { pageSize }
});

export const filterNearestMerchantCategory = (categoryId, pageSize = 1) => ({
    type: FILTER_MERCHANT_CATEGORY_NEAR_ME,
    payload: { categoryId, pageSize }
});

export const filterNearestMerchantCategorySuccess = (merchants) => ({
    type: FILTER_MERCHANT_CATEGORY_NEAR_ME_SUCCESS,
    payload: merchants
});

export const filterNearestMerchantSuccess = (merchants) => ({
    type: FILTER_MERCHANT_NEAR_ME_SUCCESS,
    payload: merchants
});

export const filterDistrictMerchant = (districtId, pageSize = 1) => ({
    type: FILTER_MERCHANT_DISTRICT,
    payload: { districtId, pageSize }
});

export const filterDistrictMerchantCategory = (districtId, categoryId, pageSize = 1) => ({
    type: FILTER_MERCHANT_CATEGORY_DISTRICT,
    payload: { districtId, categoryId, pageSize }
});

export const filterDistrictMerchantCategorySuccess = (merchants) => ({
    type: FILTER_MERCHANT_CATEGORY_DISTRICT_SUCCESS,
    payload: merchants
});

export const filterDistrictMerchantSuccess = (merchants) => ({
    type: FILTER_MERCHANT_DISTRICT_SUCCESS,
    payload: merchants
});

export const clearFilterNearMeState = () => ({
    type: CLEAR_FILTER_NEAR_ME_STATE
});

export const clearFilterDistrictState = () => ({
    type: CLEAR_FILTER_DISTRICT_STATE
});

export const clearFilterNearMeCategoryState = () => ({
    type: CLEAR_FILTER_NEAR_ME_CATEGORY_STATE
});

export const clearFilterDistrictCategoryState = () => ({
    type: CLEAR_FILTER_DISTRICT_CATEGORY_STATE
});

export const setTypeState = (type = 'DEFAULT') => ({
    type: SET_TYPE_STATE,
    payload: { type }
});

export const setIsLoadMore = (load = false) => ({
    type: SET_IS_LOAD_MORE,
    payload: { load }
})

export const clearMerchantsDetails = () => ({
    type: CLEAR_MERCHANT_DETAILS
})