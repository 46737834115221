// Misc
export const LOADING = 'LOADING';
export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';

// Auth
export const SET_USERDATA = 'SET_USERDATA';
export const UPDATE_NAME = 'UPDATE_NAME';
export const SET_USERDATA_SUCCESS = 'SET_USERDATA_SUCCESS';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';

// Merchant
export const GET_MERCHANTS = 'GET_MERCHANTS';
export const GET_MERCHANTS_SUCCESS = 'GET_MERCHANTS_SUCCESS';
export const CANCEL_GET_MERCHANTS = 'CANCEL_GET_MERCHANTS';
export const CLEAR_MERCHANT_STATE = 'CLEAR_MERCHANT_STATE';
export const SEARCH_MERCHANT_KEYWORD = 'SEARCH_MERCHANT_KEYWORD';
export const SEARCH_MERCHANT = 'SEARCH_MERCHANT';
export const SEARCH_MERCHANT_SUCCESS = 'SEARCH_MERCHANT_SUCCESS';
export const SEARCH_MERCHANT_ITEM = 'SEARCH_MERCHANT_ITEM';
export const CLEAR_SEARCH_STATE = 'CLEAR_SEARCH_STATE';
export const FILTER_MERCHANT_NEAR_ME = 'FILTER_MERCHANT_NEAR_ME';
export const FILTER_MERCHANT_NEAR_ME_SUCCESS = 'FILTER_MERCHANT_NEAR_ME_SUCCESS';
export const FILTER_MERCHANT_DISTRICT = 'FILTER_MERCHANT_DISTRICT';
export const FILTER_MERCHANT_DISTRICT_SUCCESS = 'FILTER_MERCHANT_DISTRICT_SUCCESS';
export const CLEAR_FILTER_NEAR_ME_STATE = 'CLEAR_FILTER_NEAR_ME_STATE';
export const CLEAR_FILTER_DISTRICT_STATE = 'CLEAR_FILTER_DISTRICT_STATE';
export const SET_TYPE_STATE = 'SET_TYPE_STATE';
export const SET_IS_LOAD_MORE = 'SET_IS_LOAD_MORE';
export const GET_MERCHANT_DETAILS = 'GET_MERCHANT_DETAILS'
export const GET_MERCHANT_DETAILS_SUCCESS = 'GET_MERCHANT_DETAILS_SUCCESS'
export const CLEAR_MERCHANT_DETAILS = 'CLEAR_MERCHANT_DETAILS'

// Merchant Category
export const GET_CATEGORY_MERCHANTS = 'GET_CATEGORY_MERCHANTS';
export const CLEAR_CATEGORY_MERCHANT_STATE = 'CLEAR_CATEGORY_MERCHANT_STATE';
export const GET_CATEGORY_MERCHANTS_SUCCESS = 'GET_CATEGORY_MERCHANTS_SUCCESS';
export const SEARCH_MERCHANT_CATEGORY_KEYWORD = 'SEARCH_MERCHANT_CATEGORY_KEYWORD';
export const SEARCH_MERCHANT_CATEGORY = 'SEARCH_MERCHANT_CATEGORY';
export const SEARCH_MERCHANT_CATEGORY_SUCCESS = 'SEARCH_MERCHANT_CATEGORY_SUCCESS';
export const FILTER_MERCHANT_CATEGORY_NEAR_ME = 'FILTER_MERCHANT_CATEGORY_NEAR_ME';
export const FILTER_MERCHANT_CATEGORY_NEAR_ME_SUCCESS = 'FILTER_MERCHANT_CATEGORY_NEAR_ME_SUCCESS';
export const FILTER_MERCHANT_CATEGORY_DISTRICT = 'FILTER_MERCHANT_CATEGORY_DISTRICT';
export const FILTER_MERCHANT_CATEGORY_DISTRICT_SUCCESS = 'FILTER_MERCHANT_CATEGORY_DISTRICT_SUCCESS';
export const CLEAR_FILTER_NEAR_ME_CATEGORY_STATE = 'CLEAR_FILTER_NEAR_ME_CATEGORY_STATE';
export const CLEAR_FILTER_DISTRICT_CATEGORY_STATE = 'CLEAR_FILTER_DISTRICT_CATEGORY_STATE';
export const CLEAR_SEARCH_CATEGORY_STATE = 'CLEAR_SEARCH_CATEGORY_STATE';

// Explore
export const EXPLORE_MERCHANT = 'EXPLORE_MERCHANT';
export const EXPLORE_MERCHANT_KEYWORD = 'EXPLORE_MERCHANT_KEYWORD';
export const EXPLORE_MERCHANT_SUCCESS = 'EXPLORE_MERCHANT_SUCCESS';
export const CLEAR_EXPLORE_STATE = 'CLEAR_EXPLORE_STATE';

// Promo
export const GET_PROMO_MERCHANTS = 'GET_PROMO_MERCHANTS';
export const GET_PROMO_MERCHANTS_SUCCESS = 'GET_PROMO_MERCHANTS_SUCCESS';
export const GET_PROMO_MERCHANT_ITEMS = 'GET_PROMO_MERCHANT_ITEMS';
export const GET_PROMO_MERCHANT_ITEMS_SUCCESS = 'GET_PROMO_MERCHANT_ITEMS_SUCCESS';
export const GET_PROMO_NEW_MERCHANT = 'GET_PROMO_NEW_MERCHANT';
export const GET_PROMO_NEW_MERCHANT_SUCCESS = 'GET_PROMO_NEW_MERCHANT_SUCCESS';

// History
export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const CLEAR_HISTORY_STATE = 'CLEAR_HISTORY_STATE';
export const UPDATE_HISTORY_RATING = 'UPDATE_HISTORY_RATING';

// Cart
export const ADD_QTY_ITEM = 'ADD_QTY_ITEM';
export const ADD_QTY_ITEM_ON_DETAIL = 'ADD_QTY_ITEM_ON_DETAIL';
export const ADD_QTY_ITEM_ADDON = 'ADD_QTY_ITEM_ADDON';
export const EDIT_QTY_ITEM_ADDON = 'EDIT_QTY_ITEM_ADDON';
export const REMOVE_QTY_ITEM = 'REMOVE_QTY_ITEM';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const SET_NOTE_ITEMS = 'SET_NOTE_ITEMS';
export const CLEAR_CART = 'CLEAR_CART';
export const SEND_ORDER = 'SEND_ORDER';
export const SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS';
export const REFRESH_ORDER_STATUS = 'REFRESH_ORDER_STATUS';
export const REFRESH_ORDER_STATUS_SUCCESS = 'REFRESH_ORDER_STATUS_SUCCESS';

// Addon
export const ROUTE_ADDON = '/merchant/:merchantId/addon'
export const ROUTE_EDIT = '/merchant/:merchantId/addon/edit'

// Routes
export const ROUTE_START = '/startIFMA';
export const ROUTE_HOME = '/home';
export const ROUTE_CATEGORY = '/category/:categoryId/:categoryName/merchants';
export const ROUTE_MERCHANT_DETAIL = '/merchant/:merchantId';
// export const ROUTE_ITEM_DETAIL = '/merchant/:merchantId/detail';
// export const ROUTE_CART = '/cart';
export const ROUTE_CART_DETAIL = '/cart/detail';
export const ROUTE_EXPLORE = '/explore';
export const ROUTE_PROMO = '/promo';

export const ROUTE_ALL_PROMO = '/allitemondiscount';
export const ROUTE_PROMO_DETAIL ='/promo/:promoId';
export const ROUTE_OPENING_PROMO = '/new-merchant';
// export const ROUTE_NEAR_ME = '/NearMe'

// export const ROUTE_PROMO_DETAIL ='/promo/:id';

export const ROUTE_HISTORY = '/history';
export const ROUTE_CART = '/cart/detail';
export const ROUTE_AFTER_ORDER = '/afterorder';
export const ROUTE_HISTORY_DETAILS = '/historydetails/:orderId';
export const ROUTE_RECEIPT = '/receipt/:orderId';

export const ROUTE_CHOOSE_PAYMENT = '/choosepayment'
export const ROUTE_QR_PAYMENT = '/qr_payment'

export const ROUTE_SHARE = '/share/merchant'