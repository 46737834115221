import React from 'react'

const Home = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} viewBox="0 0 128 136">
            <path d="M123.312,51.322,82.859,8.309a25.6,25.6,0,0,0-37.717,0L4.688,51.322A17.44,17.44,0,0,0,0,63.348v55.663c0,9.394,7.163,17.01,16,17.01h96c8.837,0,16-7.616,16-17.01V63.348A17.44,17.44,0,0,0,123.312,51.322ZM80.05,135.961H48V102.375c0-9.394,7.163-17.01,16-17.01s16,7.616,16,17.01Zm37.283-16.95a5.509,5.509,0,0,1-5.333,5.67H90.667V102.375c0-15.657-11.939-28.35-26.667-28.35s-26.667,12.693-26.667,28.35v22.306H16a5.509,5.509,0,0,1-5.333-5.67V63.348a5.9,5.9,0,0,1,1.563-4.009l40.453-43a15.376,15.376,0,0,1,22.635,0l40.453,43.013a5.9,5.9,0,0,1,1.563,3.992Z" transform="translate(0 -0.021)"/>
        </svg>
    )
}

export default Home
