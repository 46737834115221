import React from 'react'

const Order = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} fill={props.fill} viewBox="0 0 39.6 39.599">
            <path id="Path_8" data-name="Path 8" d="M33,0H6.6A6.6,6.6,0,0,0,0,6.6V26.4A6.6,6.6,0,0,0,6.6,33h4.785l7.344,6.209a1.65,1.65,0,0,0,2.132,0L28.215,33H33a6.6,6.6,0,0,0,6.6-6.6V6.6A6.6,6.6,0,0,0,33,0Zm3.3,26.4A3.3,3.3,0,0,1,33,29.7H28.215a3.3,3.3,0,0,0-2.13.78L19.8,35.788,13.518,30.48h0a3.3,3.3,0,0,0-2.133-.78H6.6a3.3,3.3,0,0,1-3.3-3.3V6.6A3.3,3.3,0,0,1,6.6,3.3H33a3.3,3.3,0,0,1,3.3,3.3Z" transform="translate(0 0)" fill="#ff6000"/>
            <path id="Path_9" data-name="Path 9" d="M7.65,8.3H15.9a1.65,1.65,0,0,0,0-3.3H7.65a1.65,1.65,0,1,0,0,3.3Z" transform="translate(3.9 3.25)" fill="#ff6000"/>
            <path id="Path_10" data-name="Path 10" d="M24.15,9H7.65a1.65,1.65,0,1,0,0,3.3h16.5a1.65,1.65,0,1,0,0-3.3Z" transform="translate(3.9 5.85)" fill="#ff6000"/>
            <path id="Path_11" data-name="Path 11" d="M24.15,13H7.65a1.65,1.65,0,0,0,0,3.3h16.5a1.65,1.65,0,1,0,0-3.3Z" transform="translate(3.9 8.45)" fill="#ff6000"/>
        </svg>

    )
}

export default Order
