import React from 'react'

import './Badge.css'

const PromoBadge = () => {
    return (
        <>
            <span className="promo-badge">PROMO</span>   
        </>
    )
}

export default PromoBadge
