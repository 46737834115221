import {
    EXPLORE_MERCHANT,
    EXPLORE_MERCHANT_SUCCESS,
    EXPLORE_MERCHANT_KEYWORD,
    CLEAR_EXPLORE_STATE
} from '../../miscellaneous/constants';

const INITIAL_STATE = {
    total: 0,
    pageSize: 1,
    data: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {
    pageSize: 1,
    keyword: '',
    merchants: INITIAL_STATE,
    items: INITIAL_STATE
}, action) => {
    const { type, payload } = action;

    switch (type) {
        case EXPLORE_MERCHANT:
            return {
                ...state,
                pageSize: payload.pageSize
            }
        case EXPLORE_MERCHANT_KEYWORD:
            return {
                ...state,
                keyword: payload.keyword
            }
        case EXPLORE_MERCHANT_SUCCESS:
            return {
                ...state,
                merchants: {
                    total: payload.explore.merchants?.length,
                    data: payload.explore.merchants
                },
                items: {
                    total: payload.explore.items?.length,
                    data: payload.explore.items
                }
            }
        case CLEAR_EXPLORE_STATE:
            return {
                ...state,
                merchants: INITIAL_STATE,
                items: INITIAL_STATE
            }
        default: return state;
    }
}