const En = {
    btn: {
        saveName: '保存名称',
        yes: '是',
        no: '否',
        add: '添加',
        viewCart: '查看购物车',
        alertOk: '我了解',
        confirmOrder: '确认订单',
        shopping: `让我们去购物吧！`,
        status: {
            orderStatus0: '刷新订单状态',
            orderStatus1: '查看配送状态',
            orderStatus9: '其他商家的订单'
        }
    },
    text: {
        info: '备注',
        nonPartnerInfo: '该商家不是合作商',
        saveName: '您好。继续之前请输入您的姓名……',
        itemitem: '商品',
        item: '商品',
        items: '商品',
        empty: '已出售',
        orderList: '订单列表',
        billDetail: '账单详细信息',
        totalPrice: '总价',
        totalDisc: '折扣总额',
        platformFee: '平台费用',
        parkingFee: '停车费',
        deliveryFee: '送货及手续费',
        takeAwayCharge: "外卖费",
        unavailable: "不可用",
        toBePaid: '待付款',
        emptyCart: '您的购物车仍然为空 :(',
        orderPlaced: {
            title: '已成功下单',
            desc: '正在等待商家确认您的订单'
        },
        driverFound: {
            title: '找到司机',
            desc: '感谢您使用Maxim服务'
        },
        driverResto: {
            title: '司机到达商家',
            desc: '司机已经到达商家'
        },
        merchantCancel: {
            title: '订单已取消',
            desc: '商家取消了您的订单'
        },
        merchantconfirm: {
            title: '订单已确认',
            desc: '商家已确认订单'
        },
        itemPickedUp: {
            title: '您的订单正在发货',
            desc: '您的订单正在进行中'
        },
        completeStatus: {
            title: '订单已完成',
            desc: '感谢您使用Maxim服务'
        },
        userCancel: {
            title: '订单已取消',
            desc: '用户取消了您的订单'
        },
        buyerName: '买家名称',
        receiveAt: '接收于',
        status: '状态',
        paymentType: '支付类型',
        cash: '现金',
        note: '备注： ',
        noNote: '无备注',
        noteMerchant: '给商家备注',
        MerchantDetailDiscountContainer: { recommend: '推荐给你' },
        AddToCart: '添加至购物车',
        deliverTo: '配送到',
        merchantName: '商家名称',
        deliveryAddress: '配送地址',
        phoneNumber: '手机号',
        merchantAddress: '商家地址',
        additional: '额外的',
    },
    label: { user_name: '您的姓名： ' },
    placeholder: {
        name: '请输入您的姓名',
        searchHome: '搜索商家或商品……',
        searchCategory: '搜索商家',
        noteItemDetail: '在此处写下具体信息',
        searchItem: '搜索商品……',
        noteMerchant: '为商家撰写评论或建议'
    },
    alert: {
        orderCancel: '该订单已取消',
        nonPartner: '不是合作商',
        partnerPlus: '订单价格为估价，可能会有变动。请要求司机提供收据作为产品付款参考。',
        noteNonPartner: '该商家不是Maxim的官方合作伙伴，产品供应和价格准确性可能会发生变化并与实际情况不同。是否继续？',
        cart: '购物车',
        cartEmpty: '您的购物车中只有一个该商品/菜单，减少数量意味着从您的购物车中删除商品……继续吗？',
        alert: '警告',
        noteProcessOrder: '服务器正在处理您的请求，请稍候……如果过程需要的时间太长，请重新启动应用或检查互联网连接。',
        confirmOrder: '确认订单',
        noteConfirmOrder: '您确定要下单吗？请在确认之前再次检查订单。',
        noteCountDeliveryPrice: '计算配送费时出错，请重新启动应用以继续。',
        noteDeliveryPrice: '检索您的位置以计算配送费时出错，请重新启动应用并确保您的 GPS 打开。',
        notePlatformPrice: '计算平台费时出错，请重新启动应用以继续。',
        noteNonPartnerReceipt: '该商家不是Maxim的官方合作商，商品有无库存和列出的价格可能变化。 请按照收据上注明的价格付款。',
        merchantClose: '商家已关闭！'
    },
    helmet: {
        title: {
            start: '食物&商品-开始',
            Home: '食品&商品',
            Category: '食品&商品-商家类别',
            History: '食品&商品-历史记录',
            DetailCart: '食品&商品-购物车详情',
            afterOrder: '食物&商品-售后',
            HistoryDetail: '食品&商品-历史详情'
        },
        desc: {
            start: '开始',
            Home: '食品&商品',
            Category: '按类别排列的商家列表',
            History: '历史交易列表',
            DetailCart: '购物车商品详情',
            afterOrder: '我的订单',
            HistoryDetail: '历史详情'
        }
    },
    topNav: { title: '食品&商品' },
    backTopNav: { History: '历史记录' },
    loading: '加载中……',
    data: {
        noDataMerchant: '未找到商家',
        DataMerchant: '发现商家',
        notFound: '找不到'
    },
    bottomNav: {
        Home: '首页',
        Explore: '浏览',
        Promo: '促销活动',
        History: '历史记录',
        Cart: '购物车'
    },
    status: {
        Open: '打开',
        Close: '已关门',
        case0: '等待商店审核',
        case1: '商店批准的订单',
        case2: 'Maxim司机配送了订单',
        case3: '指派Maxim司机接单',
        case4: '司机正在等待',
        case11: '订单已完成',
        case9: '订单已取消',
        caseNotFound: '未找到订单状态',
        orderStatus0: '等待',
        orderStatus1: '商家批准',
        orderStatus2: '司机接单',
        orderStatus3: '司机已指派',
        orderStatus4: '司机正在等待',
        orderStatus9: '商家取消',
        orderStatus11: '完成',
        orderStatus99: '用户取消',
        orderStatusNotFound: '未找到状态',
        itemNotFound: '此类别中未找到商品'
    },
    receiveAt: { statusAccepted: '尚未收到' },
    bottomConfirm: {
        payment: {
            title: '数字支付方式',
            title2: '其他支付方式',
            cash: '现金',
            noncash: '非现金',
            paywith: '付款方式',
            soon: '很快'
        },
        btn: { confirm: '确认并继续订单' }
    },
    itemDetails: { note: '给商家备注' },
    addon: {
        optional: '选择',
        qty: '数量',
        additm: '添加物品',
        edititm: '更新物品',
        max: '选择最大值',
        edit: '编辑',
        require: '要求',
        selectrequire: '选择最小值',
        price: '价格',
        addcustome: '添加其他客户',
        free: '免费'
    },
    promo: { listMerchants: '商家名单' }
};
export default En;