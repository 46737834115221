import React from 'react'

const Confirm = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} viewBox="0 0 39.6 39.6">
            <g id="Group_151" data-name="Group 151" transform="translate(-300 -174)">
                <g id="Ellipse_65" data-name="Ellipse 65" transform="translate(300 174)" fill="#fff" stroke="#ff6000" stroke-width="3">
                <circle cx="19.8" cy="19.8" r="19.8" stroke="none"/>
                <circle cx="19.8" cy="19.8" r="18.3" fill="none"/>
                </g>
                <path id="Path_84" data-name="Path 84" d="M1526.042,2015l7.21,6.434,6.75-7.65,5.974-6.771" transform="translate(-1216 -1820)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="4"/>
            </g>
        </svg>
    )
}

export default Confirm
