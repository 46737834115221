import React, { useMemo } from 'react'
import { useHistory } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {
  Edit, MerchantImage
} from '../../../assets'
import { useCart } from '../../../application/hooks';
import { GoPlus, GoDash, GoTrashcan } from "react-icons/go";
import { formatCurrency } from '../../../miscellaneous/helpers/utils';
import { setNoteItems, removeFromCart, addQtyItem, removeQtyItem } from '../../../application/actions/cartActions';
import { ItemImage } from '../../../assets';
import '../Cart.css';
import { t } from '../../../miscellaneous/language'
import pencil from '../../../assets/icons/pencils.svg';

const ItemsInCart = ({ items, cart, isNotAvailable }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isEditing, toggleEdit] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [notes, setNotes] = React.useState("");

  const { itemQtyCounter } = useCart(items.id);

  const editNotes = (id) => {
    var notes = document.getElementById('notes').value;

    dispatch(setNoteItems(id, notes));    
  }
  const MainFunction = {
    AddonList: (data, status) => {
      if (status === 0) {
        // console.log(data.uuid_cart);
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="modal-cart-notes-detail">
                <h3 style={{ paddingBottom: 10, textAlign: 'center' }}>{t.text.info}</h3>
                <textarea defaultValue={items?.orderDesc} rows="3" placeholder='Note' id="notes" />
                <div className="modal-cart-notes-detail-button">
                  <button
                    class="modal-cart-notes-detail-button-no"
                    onClick={onClose}
                  >
                    {t.btn.no}
                  </button>
                  <button
                    class="modal-cart-notes-detail-button-yes"
                    onClick={() => {
                      editNotes(data?.uuid_cart);
                      onClose();
                    }}
                  >
                    {t.addon.edit}
                  </button>
                </div>
              </div>
            )
          }
        });
      }
      else {
        // console.log(data, 'kirakira')
        history.push(`/merchant/${items.merchants_id}/addon/edit`, data);
      }
    }
  }

  const onItemAddToCart = (id) => {
    if (itemQtyCounter(id) >= 1) {
      dispatch(addQtyItem(id));
    }
  }

  const onItemRemoveFromCart = (id) => {
    if (itemQtyCounter(id) > 1) {
      dispatch(removeQtyItem(id));
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert">
              <h1>{t.alert.cart}</h1>
              <p>{t.alert.cartEmpty}</p>
              <div className="alert-button-container">
                <button
                  class="yes-button"
                  onClick={() => {
                    dispatch(removeFromCart(id));
                    onClose();
                  }}
                >
                  {t.btn.yes}
                </button>
                <button
                  class="no-button"
                  onClick={onClose}
                >
                  {t.btn.no}
                </button>
              </div>
            </div>
          )
        }
      })
    }
  }

  const itemsUnavailable = useMemo(() => {
    return (
      <div className='items-in-cart-main'>
        <div className="items-in-cart-icon-container items-in-cart-flex-1" onClick={(e) => toggleEdit(!isEditing)}>
          <div className="items-in-cart-icon-container-wrapper">
            <img
              src={items.image}
              width={52}
              height={52}
              className="items-in-cart-icon-container-image-unavailable"
              onError={(event) => event.target.src = MerchantImage} />
          </div>
        </div>
        <div className="items-in-cart-name-container items-in-cart-flex-5">
          <span className="items-in-cart-name">{items.name}</span>
          <div className="items-in-cart-notes-container">
            <span className="items-in-cart-notes-title"></span>
            {
              items?.addonChoosed === undefined ?
                <></>
                :
                Object?.keys(items?.addonChoosed).map((item) => {
                  return (
                    <div className="items-in-cart-addon-desc-container-item">
                      <span className="items-in-cart-addon-title">{item.split('_').join(' ').split('-*&id:')[0]} :&nbsp;</span>
                      {
                        items?.addonChoosed[item].map((itm, idx) => {
                          return (
                            <span className="items-in-cart-addon-desc">{(idx ? ', ' : '') + itm.name} </span>
                          )
                        })
                      }
                    </div>
                  )
                })
            }

            {
              items?.orderDesc ?
                // <p>true</p>
                <div className="items-in-cart-addon-desc-wrapper">
                  <div className='items-in-cart-addon-title'><span>Note:&nbsp;</span></div>
                  <span className="items-in-cart-addon-desc">
                    {items.orderDesc}
                  </span>
                </div>
                :
                // <>false</>
                <></>
            }
          </div>
        </div>
        <div className='items-in-cart-name-container'>
          <span className='items-in-cart-name-container-text-unavailable'>{t.text.unavailable}</span>
          <a className="items-in-cart-name-container-button-unavailable" onClick={() => onItemRemoveFromCart(items.uuid_cart)}>
            <GoTrashcan />
          </a>
        </div>
      </div>
    )
  });

  const itemsAvailable = useMemo(() => {
    return (
      <div className='items-in-cart-main'>
        <div className="items-in-cart-icon-container items-in-cart-flex-1" onClick={(e) => toggleEdit(!isEditing)}>
          {
            isLoaded ? (
              <img
                src={items.image}
                width={52}
                height={52}
                className="items-in-cart-icon-container-image"
                onError={(event) => event.target.src = MerchantImage} />
            ) : (
              <img
                src={ItemImage}
                width={50}
                height={50}
                className="items-in-cart-icon-container-image"
              />
            )
          }
          <img
            src={items.image}
            width={52}
            height={52}
            className="items-in-cart-icon-container-image"
            onLoad={() => setIsLoaded(true)}
            onError={(event) => event.target.src = MerchantImage}
            style={{ display: 'none' }}
          />
          <div className="items-in-cart-flex-2">
            <div className="items-in-cart-addon-edit-wrapper"
              onClick={
                () =>
                  items?.addonChoosed ?
                    // change with addon
                    MainFunction.AddonList(items, 1)
                    :
                    // change with no addon
                    MainFunction.AddonList(items, 0)
              }>
              <div className="items-in-cart-addon-edit-icon">
                {
                  items?.addonChoosed ?
                    // <AiFillEdit color="#517C92" />
                    <img src={pencil} />
                    :
                    <img src={pencil} />
                  // <img src={editnotes2}/>
                }
              </div>
              {/* <div className="items-in-cart-addon-edit-text">
                                {
                                    items?.addonChoosed ?
                                        <span>{t.addon.edit}</span>
                                        :
                                        <span>{t.text.info}</span>
                                }
                            </div> */}
            </div>
          </div>
        </div>
        <div className="items-in-cart-name-container items-in-cart-flex-5">
          <span className="items-in-cart-name">{items.name}</span>
          <div className="items-in-cart-notes-container">
            <span className="items-in-cart-notes-title"></span>
            {
              items?.addonChoosed === undefined ?
                <></>
                :
                Object?.keys(items?.addonChoosed).map((item) => {
                  return (
                    <div className="items-in-cart-addon-desc-container-item">
                      <span className="items-in-cart-addon-title">{item.split('_').join(' ').split('-*&id:')[0]} :&nbsp;</span>
                      {
                        items?.addonChoosed[item].map((itm, idx) => {
                          return (
                            <span className="items-in-cart-addon-desc">{(idx ? ', ' : '') + itm.name} </span>
                          )
                        })
                      }
                    </div>
                  )
                })
            }

            {
              items?.orderDesc ?
                // <p>true</p>
                <div className="items-in-cart-addon-desc-wrapper">
                  <div className='items-in-cart-addon-title'><span>Note:&nbsp;</span></div>
                  <span className="items-in-cart-addon-desc">
                    {items.orderDesc}
                  </span>
                </div>
                :
                // <>false</>
                <></>
            }
          </div>
        </div>
        <div className='items-in-cart-name-container items-in-cart-flex-2'>
          {items.discount == 1 &&
            <div className="items-in-cart-price-container">
              <span className="items-in-cart-price red-color">{items.total_with_addon ? formatCurrency(parseFloat(items.total_with_addon)) : formatCurrency(items.total)}</span>
            </div>
          }
          <div className="items-in-cart-price-container">
            <span className={items.discount == 1 ? "items-in-cart-price-discount" : "items-in-cart-price"}>{items.total_with_addon ? formatCurrency(parseFloat(items.price) + (parseFloat(items.total_with_addon) - parseFloat(items.total))) : formatCurrency(items.price)}</span>
          </div>
          <div className='items-in-cart-price-container'>
            <button className="cart-detail-button-counter" onClick={() => onItemRemoveFromCart(items.uuid_cart)}><GoDash className="button-counter-icon" /></button>
            <span className="items-in-cart-counted">&nbsp;{items.qty}&nbsp;</span>
            <button className="cart-detail-button-counter" onClick={() => onItemAddToCart(items.uuid_cart)}><GoPlus className="button-counter-icon" /></button>
          </div>
        </div>
      </div>
    )
  })

  // console.log(items?.addonChoosed?.length, 'ini adalah items');  

  return (
    <>
      <div className="items-in-cart">
        {isNotAvailable ?
          itemsUnavailable
          :
          itemsAvailable
        }
      </div>
    </>
  )
}

export default ItemsInCart
