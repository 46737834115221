import React from 'react'
import { Helmet } from 'react-helmet'
// import { useParams } from 'react-router-dom';

import { usePromoDetail } from '../../application/hooks';

import {
    BackHomeTopNav,
    BottomSpace
} from '../components/common'

import {
    PromoDetail
} from '../'

const PromoDetailContainer = ({ match }) => {
    const { promoId } = match.params;
    const { promoMerchant, isLoading, error } = usePromoDetail(promoId);

    return (
        <>
            <Helmet>
                <title>{`Foods&Goods - ${promoMerchant?.name}`}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={`${promoMerchant?.name}`} />
            </Helmet>
            <BackHomeTopNav />
            {isLoading && <span><center>Loading ...</center></span>}
            {error && <span><center>Oops .. {error}</center></span>}
            {(promoMerchant && !isLoading) && <PromoDetail promo={promoMerchant} />}
            <BottomSpace />
        </>
    )
}

export default PromoDetailContainer
