import React from 'react'
import PropType  from 'prop-types';
import { useHistory } from 'react-router-dom';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import '../Promo.css'
import 'swiper/swiper.min.css';

SwiperCore.use([Autoplay]);

const Banner = ({ merchants }) => {
    const history = useHistory();

    return (
        <div className="banner-container">
            <Swiper
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false
                }}
            >
                {merchants.map((merchant, index) => (
                    <SwiperSlide key={index}>
                        <div className="banner-image-container" onClick={() => {
                            if (merchant.id) {
                                history.push(`/promo/${merchant.id}`);
                            }
                        }}>
                            <img src={merchant.image} alt="" />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

Banner.propTypes = {
    merchants: PropType.array.isRequired
}

export default Banner
