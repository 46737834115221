import React, { useState } from "react";
import MerchantDetailDiscountItem from "./MerchantDetailDiscountItem";
import MerchantMenuListLoading from "../components/common/Loading/MerchantMenuListLoading";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "./Discount.css";

import { t } from "../../miscellaneous/language";

import { ItemImage } from "../../assets";
import { categoryItemShowFilter} from "../../miscellaneous/helpers/utils";

const MerchantDetailDiscountContainer = ({ items, detailFunction, typeUI }) => {
  const SwiperBreakpoints = {
    500: {
      slidesPerView: 3,
      // spaceBetween: 9,
    },
    425: {
      slidesPerView: 3,
      // spaceBetween: 9
    },
    375: {
      slidesPerView: 2,
      // spaceBetween: 9
    },
    320: {
      slidesPerView: 2,
      // spaceBetween: 9
    },
    280: {
      slidesPerView: 2,
      // spaceBetween: 9
    },
  };

  console.log(items?.length, "ini adalah items");

  return (
    <>
      {items?.length != 0 && (
        <>
          <p className="merchant-discount-recommend">
            {t.text.MerchantDetailDiscountContainer.recommend}
          </p>
          <div className="discount-container">
            {items?.filter((item) =>  categoryItemShowFilter({category: item.item_category})).map((discItem) => (
              <MerchantDetailDiscountItem
                detailFunction={detailFunction}
                item={discItem}
                typeUI={typeUI}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default MerchantDetailDiscountContainer;
