import { Input, TextField } from "@material-ui/core"
import React from "react";
import Star from '../../assets/icons/rating24.svg'
import Locations from '../../assets/icons/location24.svg'
import { MdContentCopy } from "react-icons/md";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WhatsappIcon,
  FacebookIcon,
  TelegramIcon,
  EmailIcon,
  LineIcon,
  TwitterIcon,
} from "react-share";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { genSaltSync } from "bcryptjs";
import CryptoJS from "crypto-js";
const MerchantDetails = ({ MerchantDetailsModal, close, merchant, type, handleDetailClick }) => {
  const [shareLink, setShareLink] = React.useState(null)
  React.useEffect(() => {
    if (MerchantDetailsModal) {
      document.body.style.overflow = 'hidden';
      return () => document.body.style.overflow = 'unset';
    }
  }, [MerchantDetailsModal]);

  React.useEffect(() => {
    if(type === 'share') {
      console.log(merchant.id, 'okok merchant idnya')
      var salt = genSaltSync(10);
      const encrypt = CryptoJS.AES.encrypt(
        JSON.stringify(merchant.id),
        salt
      ).toString();
      const link = `https://idmarket.taximaxim.com/share/merchant`
      const shareValue = `${salt}-share-${encrypt}`
      const param = new URLSearchParams({share: shareValue})
      setShareLink(`${link}?${param.toString()}`)
    }
  }, [type])

    //  console.log(merchant)

  return (
    <>
      <div onClick={close} className={MerchantDetailsModal ? "merchant-details-modal-container-open" : "merchant-details-modal-container-close"}>
      </div>
      <div className='merchant-details-modal-container'>
        {type === 'merchant' ?
          <div aria-hidden={!MerchantDetailsModal} className={MerchantDetailsModal === true ? 'transitionUp merchant-transition' : 'transitionDown'}>
            <div className='container-merchant-details' >
              <div style={{ fontWeight: 700, fontSize: 20, paddingLeft: 16 }}>
                {merchant?.name}
              </div>
              {(merchant?.desc || merchant?.desc !== '') && <div style={{ fontWeight: 400, fontSize: 14, padding: 16, color: '#969696' }}>
                {merchant?.desc}
              </div>}
              <div style={{ padding: 16 }}>
                <div>
                  <img src={Star} fill="#FF6000" />
                  <span style={{ marginLeft: 8 }} >{merchant?.avg_rating}</span>
                </div>
                <div style={{ marginTop: 10 }}>
                  <img src={Locations} fill='#FF6000' />
                  <span > {merchant?.address} </span>
                </div>
              </div>
              {/* <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <button style={{ backgroundColor: '#FFC900', border: 0, borderRadius: 8, width: '93%', fontSize: 14, alignSelf: 'center', padding: 15 }} onClick={() => handleDetailClick(merchant, "None", 'share')}>Share this merchant</button>
              </div> */}
            </div>
          </div>
          : (type === 'share' &&
            <div aria-hidden={!MerchantDetailsModal} className={MerchantDetailsModal === true ? 'transitionUp merchant-transition' : 'transitionDown'}>
              <div className='container-merchant-details'>
                <div className="content-merchant-share-header">
                  <div className="content-merchant-share-header-text">Share to</div>
                </div>
                <div className="content-merchant-share-body">
                  <div className="content-merchant-share-body-top">
                    <div className="content-merchant-share-body-component">
                      <div className="share-merchant-icon">
                        <WhatsappShareButton
                          url={'github.com'}
                          title={'test123'}
                          separator={`\n`}
                        >
                          <WhatsappIcon size={50} round />
                        </WhatsappShareButton>
                      </div>
                      <div className="share-merchant-text">
                        <span>Whatsapp</span>
                      </div>
                    </div>
                    <div className="content-merchant-share-body-component">
                      <div className="share-merchant-icon">
                        <FacebookShareButton
                          url={'github.com'}
                          title={'test123'}
                          separator={`\n`}

                        >
                          <FacebookIcon size={50} round />
                        </FacebookShareButton>
                      </div>
                      <div className="share-merchant-text">
                        <span>Facebook</span>
                      </div>
                    </div>
                    <div className="content-merchant-share-body-component">
                      <div className="share-merchant-icon">
                        <TelegramShareButton
                          url={'github.com'}
                          title={'test123'}
                          separator={`\n`}

                        >
                          <TelegramIcon size={50} round />
                        </TelegramShareButton>
                      </div>
                      <div className="share-merchant-text">
                        <span>Telegram</span>
                      </div>
                    </div>
                    <div className="content-merchant-share-body-component">
                      <div className="share-merchant-icon">
                        <EmailShareButton
                          url={'github.com'}
                          title={'test123'}
                          separator={`\n`}

                        >
                          <EmailIcon size={50} round />
                        </EmailShareButton>
                      </div>
                      <div className="share-merchant-text">
                        <span>Email</span>
                      </div>
                    </div>
                    <div className="content-merchant-share-body-component">
                      <div className="share-merchant-icon">
                        <TwitterShareButton
                          url={'github.com'}
                          title={'test123'}
                          separator={`\n`}

                        >
                          <TwitterIcon size={50} round />
                        </TwitterShareButton>
                      </div>
                      <div className="share-merchant-text">
                        <span>Twitter</span>
                      </div>
                    </div>
                  </div>
                  <div className="content-merchant-share-body-bottom">
                    <div className="content-merchant-share-body-bottom-wrapper">
                      <input type="text" value={shareLink} readOnly="true" />
                      <div className="content-merchant-share-footer-copy" onClick={() => {
                        navigator.clipboard.writeText(`Check this out ${shareLink}`)
                        toast('Udah ke copy nih', {
                          position: "bottom-center",
                          autoClose: 2000,
                          hideProgressBar: true,
                          closeOnClick: true,
                          // pauseOnHover: true,
                          // draggable: true,
                          // progress: undefined,
                          theme: "dark",
                        });
                      }}>
                        <MdContentCopy size={25} />
                      </div>
                    </div>
                  </div>
                </div>                
                <ToastContainer
                  position="bottom-center"
                  autoClose={2000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  // pauseOnFocusLoss
                  // draggable
                  // pauseOnHover
                  theme="dark"
                />
              </div>
            </div>
          )
        }

      </div>

      {/* </div> */}
    </>
  )
}

export default MerchantDetails