import React from 'react'
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { BiHome } from 'react-icons/bi';

import {
    AngleSmallLeft,
    Home,
} from '../../../../assets'

import '../../../../assets/icons/icons.css'
import './TopNav.css'

import MaximLogo from '../../../../assets/images/maxim-logo.svg'

const ReceiptTopNav = props => {
    const history = useHistory();

    const params = new URLSearchParams(window.location.search);

    const receipt = params.get('receipt');

    // console.log(Boolean(receipt), 'xixixi');

    return (
        <>
            {
                receipt ?
                    <>
                        <div className="white-space"></div>
                        <div className="topnav-history-container">
                            <div className="topnav-left-side" onClick={() => { history.goBack() }}>
                                <AngleSmallLeft id="back-small-icon" />
                            </div>
                            {/* <Link to={`/history`} className="topnav-right-side">
                                <AngleSmallLeft id="back-small-icon" />
                            </Link> */}
                            <div className="topnav-center-side">
                                <span className="topnav-title-text margin-left-10">{props.title}</span>
                            </div>
                            <Link to={`/home`} className="topnav-right-side">
                                <BiHome size={25} title="Home" color={'black'} />
                            </Link>
                        </div>
                    </>
                    :
                    <div className="receipt-topnav">
                        <img src={MaximLogo} alt="" />
                        <span className="receipt-topnav-title">Foods&Goods</span>
                    </div>
            }
        </>
    )
}

export default ReceiptTopNav
