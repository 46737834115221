import {
  GET_MERCHANTS,
  GET_MERCHANTS_SUCCESS,
  GET_MERCHANT_DETAILS,
  GET_MERCHANT_DETAILS_SUCCESS,
  GET_CATEGORY_MERCHANTS,
  CLEAR_CATEGORY_MERCHANT_STATE,
  GET_CATEGORY_MERCHANTS_SUCCESS,
  CLEAR_MERCHANT_STATE,
  SEARCH_MERCHANT_KEYWORD,
  SEARCH_MERCHANT_SUCCESS,
  SEARCH_MERCHANT_ITEM,
  CLEAR_SEARCH_STATE,
  CLEAR_SEARCH_CATEGORY_STATE,
  FILTER_MERCHANT_NEAR_ME_SUCCESS,
  FILTER_MERCHANT_DISTRICT_SUCCESS,
  CLEAR_FILTER_NEAR_ME_STATE,
  CLEAR_FILTER_DISTRICT_STATE,
  SET_TYPE_STATE,
  SEARCH_MERCHANT_CATEGORY_KEYWORD,
  SEARCH_MERCHANT_CATEGORY_SUCCESS,
  FILTER_MERCHANT_CATEGORY_NEAR_ME_SUCCESS,
  FILTER_MERCHANT_CATEGORY_DISTRICT_SUCCESS,
  CLEAR_FILTER_NEAR_ME_CATEGORY_STATE,
  CLEAR_FILTER_DISTRICT_CATEGORY_STATE,
  SET_IS_LOAD_MORE,
  CLEAR_MERCHANT_DETAILS,
} from "../../miscellaneous/constants";

const INITIAL_STATE = {
  total: 0,
  pageSize: 0,
  items: [],
};

const INITIAL_CATEGORY_STATE = {
  categoryId: null,
  total: 0,
  pageSize: 0,
  items: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (
  state = {
    total: 0,
    pageSize: 0,
    keyword: null,
    type: "FILTER_NEAR_ME",
    isLoadMore: false,
    items: [],
    searchedMerchantItems: [],
    searchedItems: INITIAL_STATE,
    filterNearMe: INITIAL_STATE,
    filterDistrict: INITIAL_STATE,

    merchantKeyword: null,
    searchedItemsCategory: INITIAL_CATEGORY_STATE,
    filterNearMeCategory: INITIAL_CATEGORY_STATE,
    filterDistrictCategory: INITIAL_CATEGORY_STATE,
    category: INITIAL_CATEGORY_STATE,

    details: [],
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MERCHANTS:
      return {
        ...state,
        pageSize: payload.pageSize,
      };
    case GET_MERCHANTS_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        total: payload.total,
        items: [...state.items, ...payload.merchants],
      };
    case GET_MERCHANT_DETAILS:
      return {
        ...state,
        merchantId: payload.merchantId,
      };
    case GET_MERCHANT_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        details: payload.details,
      };
    case GET_CATEGORY_MERCHANTS:
      return {
        ...state,
        categoryId: payload.categoryId,
        pageSize: payload.pageSize,
      };
    case GET_CATEGORY_MERCHANTS_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        category: {
          categoryId: payload.categoryId,
          total: payload.total,
          items:
            state.category.categoryId === payload.categoryId
              ? [...state.category.items, ...payload.merchants]
              : [...payload.merchants],
        },
      };
    case CLEAR_CATEGORY_MERCHANT_STATE:
      return {
        ...state,
        category: INITIAL_CATEGORY_STATE,
      };
    case SEARCH_MERCHANT_CATEGORY_KEYWORD:
      return {
        ...state,
        merchantKeyword: payload.keyword,
      };
    case SEARCH_MERCHANT_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        searchedItemsCategory: {
          categoryId: payload.categoryId,
          total: payload.total,
          items:
            state.searchedItemsCategory.categoryId === payload.categoryId
              ? [...state.searchedItemsCategory.items, ...payload.merchants]
              : [...payload.merchants],
        },
      };
    case FILTER_MERCHANT_CATEGORY_NEAR_ME_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        filterNearMeCategory: {
          categoryId: payload.categoryId,
          total: payload.total,
          items:
            state.filterNearMeCategory.categoryId === payload.categoryId
              ? [...state.filterNearMeCategory.items, ...payload.merchants]
              : [...payload.merchants],
        },
      };
    case FILTER_MERCHANT_CATEGORY_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        filterDistrictCategory: {
          categoryId: payload.categoryId,
          total: payload.total,
          items:
            state.filterDistrictCategory.categoryId === payload.categoryId
              ? [...state.filterDistrictCategory.items, ...payload.merchants]
              : [...payload.merchants],
        },
      };
    case CLEAR_MERCHANT_STATE:
      return {
        ...state,
        items: [],
      };
    case SEARCH_MERCHANT_KEYWORD:
      return {
        ...state,
        keyword: payload.keyword,
      };
    case SEARCH_MERCHANT_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        searchedItems: {
          total: payload.total,
          items: [...state.searchedItems.items, ...payload.merchants],
        },
      };
    case SEARCH_MERCHANT_ITEM:
      return {
        ...state,
        keyword: payload,
        searchedMerchantItems: {
          merchant_item_category: state.details?.merchant_item_category.map(
            (merchantCat) => {
              if (merchantCat.items?.length > 0) {
                return {
                  ...merchantCat,
                  items: merchantCat.items.filter(
                    (item) => item.name.toLowerCase().indexOf(payload) >= 0
                  ),
                };
              }
              return merchantCat;
            }
          ),
          merchant_items: state.details?.merchant_items.filter(
            (item) => item.name.toLowerCase().indexOf(payload) >= 0
          ),
        },
      };
    case CLEAR_SEARCH_STATE:
      return {
        ...state,
        searchedItems: INITIAL_STATE,
      };
    case CLEAR_SEARCH_CATEGORY_STATE:
      return {
        ...state,
        merchantKeyword: null,
        searchedItemsCategory: INITIAL_STATE,
      };
    case FILTER_MERCHANT_NEAR_ME_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        filterNearMe: {
          total: payload.total,
          items: [...state.filterNearMe.items, ...payload.merchants],
        },
      };
    case FILTER_MERCHANT_DISTRICT_SUCCESS:
      return {
        ...state,
        isLoadMore: false,
        filterDistrict: {
          total: payload.total,
          items: [...state.filterDistrict.items, ...payload.merchants],
        },
      };
    case CLEAR_FILTER_NEAR_ME_CATEGORY_STATE:
      return {
        ...state,
        filterNearMeCategory: INITIAL_CATEGORY_STATE,
      };
    case CLEAR_FILTER_DISTRICT_CATEGORY_STATE:
      return {
        ...state,
        filterDistrictCategory: INITIAL_CATEGORY_STATE,
      };
    case CLEAR_FILTER_NEAR_ME_STATE:
      return {
        ...state,
        filterNearMe: INITIAL_STATE,
      };
    case CLEAR_FILTER_DISTRICT_STATE:
      return {
        ...state,
        filterDistrict: INITIAL_STATE,
      };
    case SET_TYPE_STATE:
      return {
        ...state,
        type: payload.type,
      };
    case SET_IS_LOAD_MORE:
      return {
        ...state,
        isLoadMore: payload.load,
      };
    case CLEAR_MERCHANT_DETAILS:
      return {
        ...state,
        details: []
      }
    default:
      return state;
  }
};
