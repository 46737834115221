import React from 'react'

const BottomSpace = (props) => {
    return (
        <div className={props.class || "bottom-space"}>
            
        </div>
    )
}

export default BottomSpace
