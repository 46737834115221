import { En, Es, Id, Ms, Ph, Pt, Th, Zh } from "../../application/language";
const language = localStorage.getItem('language')


// export const t = language === 'en' ? En :
//                 language === 'es' ? Es : 
//                 language === 'id' ? Id :
//                 language === 'ms' ? Ms :
//                 language === 'ph' ? Ph :
//                 language === 'pt' ? Pt :
//                 language === 'th' ? Th : 
//                 language === 'zh' ? Zh :
//                 En

const translate = () => {
    switch (language) {
        case 'en':
            return En
        case 'es':
            return Es
        case 'id':
            return Id
        case 'ms':
            return Ms
        case 'ph':
            return Ph
        case 'pt':
            return Pt
        case 'th':
            return Th
        case 'zh':
            return Zh
        default:
            return En
    }

}
export const t = translate()
