import React, { useMemo } from "react";
// import HalalLogo from '../../assets/icons/halal-logo.png'

import {
  // Star,
  // District,
  PriceLabel,
  MerchantImage,
} from "../../assets/";

import Star from "../../assets/icons/rating.svg";
import District from "../../assets/icons/location.svg";
import RightArrow from "../../assets/icons/Angle-small-right.svg";

import "./Merchant.css";

import { formatCurrencyKStyle } from "../../miscellaneous/helpers/utils";
import { useHistory } from "react-router-dom";
import { t } from "../../miscellaneous/language";
import ReactGA from "react-ga4";

import infoIcon from "../../assets/icons/info.svg";
import { confirmAlert } from "react-confirm-alert";

const MerchantDetailItem = ({
  merchant_details,
  checkMerchantPartner,
  clickdetails,
}) => {
  const history = useHistory();
  const clickMerchant = (id, partner_level) => {
    const path = `/merchant/${id}`;
    if (path === window.location.pathname) {
      clickdetails(merchant_details, "none", "merchant");
    } else {
      history.push(path);
      console.log(partner_level);
      ReactGA.event(
        `Click ${partner_level === 1 ? "partner" : "non-partner"}`,
        {
          category: "Click Merchant",
          action: "Click Merchant",
          label: "Click Merchant", // optional
          value: 1, // optional, must be a number
        }
      );
    }
  };

  return (
    <div style={{ margin: 10, borderRadius: 8, backgroundColor: "white" }}>
      <div
        className="merchant-list-card"
        onClick={(e) => {
          merchant_details.is_closed === 1
            ? alert(t.alert.merchantClose)
            : clickMerchant(
                merchant_details.id,
                merchant_details.partner_level
              );
        }}
      >
        {merchant_details.is_closed === 0 ? (
          <div className="img-container merchant-list-img">
            <img
              src={merchant_details.image}
              alt=""
              className="merchant-list-img"
              onError={(event) => (event.target.src = MerchantImage)}
            />
            <span className="badge">
              {merchant_details.partner_level == 0 ? "non-partner" : "Partner"}
            </span>
          </div>
        ) : (
          <div
            className="img-container merchant-list-img"
            style={{ backgroundImage: "url(" + merchant_details.image + ")" }}
          >
            <p
              className="merchant-list-img-status"
              style={{
                color: "white",
                textAlign: "center",
                paddingTop: 40,
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              {merchant_details.is_closed === 0
                ? t.status.Open
                : t.status.Close}
            </p>
            <span className="badge">
              {merchant_details.partner_level == 0 ? "non-partner" : "Partner"}
            </span>
          </div>
        )}

        <div className="merchant-list-description-wrapper">
          <div className="merchant-list-description-container">
            <span className="merchant-list-name">{merchant_details.name}</span>
            <span className="merchant-list-desc">{merchant_details.desc}</span>
            <div className="merchant-list-detail-container">
              <div className="merchant-detail-list-rating">
                <img
                  src={Star}
                  id="merchant-icon-info"
                  fill="#FF6000"
                  className="merchant-icon-info"
                />
                <span>{merchant_details.avg_rating}</span>
              </div>
              <div className="merchant-detail-list-address ">
                <img
                  src={District}
                  id="merchant-icon-info"
                  className="merchant-icon-info"
                />
                {/* <span>{merchant_details?.address}</span> */}
                <span className="merchant-info-location">
                  {merchant_details?.address}
                </span>
              </div>
              {/* <div className="merchant-list-detail"><PriceLabel id="merchant-icon-info" /> <span>{formatCurrencyKStyle(merchant_details.min)} - {formatCurrencyKStyle(merchant_details.max)}</span></div> */}
            </div>
          </div>
          {/* <div className='merchant-list-description-container-arrow flex-1'>
                        <img src={RightArrow} width={30} height={30}/>                        
                    </div> */}
        </div>
      </div>
      {merchant_details.partner_level == 0 ? (
        <div className="alert-information">
          <div className="alert-detail">
            <center>
              <strong>
                <a>{t.text.info}</a>
              </strong>{" "}
              {t.text.nonPartnerInfo}{" "}
              <img
                src={infoIcon}
                onClick={() => checkMerchantPartner("merchants")}
              />
            </center>
          </div>
        </div>
      ) : merchant_details.partner_level == 2 ? (
        <div className="alert-information">
          <div className="alert-detail-plus">
            <a>{t.alert.partnerPlus}&nbsp;</a>
            {/* <img
              src={infoIcon}
              onClick={() => checkMerchantPartner("merchantsPlus")}
            /> */}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MerchantDetailItem;
