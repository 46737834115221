import PropType from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Router from './application/routers/Router';

import './App.css';
import React from 'react';
import ReactGA from "react-ga4";

function App({ store, persistor }) {
  const language = localStorage.getItem('language')
  if(!language) localStorage.setItem('language', 'en')
  
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  
  React.useEffect(() => {
    // Development
    // ReactGA.initialize('G-80N3NFTJY4')
    
    // Production
    ReactGA.initialize('G-Z05SCDTNNP')
    
    ReactGA.send({ hitType: "pageview", page: window.location.pathname })
  },[])
  return (
    <div className="container">
      <div className="main-container">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </div>
    </div>
  );
}

App.propTypes = {
  store: PropType.any.isRequired,
  persistor: PropType.any.isRequired
};

export default App;
