import React from 'react'
import { fadeInDown, fadeInUp } from 'react-animations'
import { StyleSheet, css } from 'aphrodite';
import { FaCheck, FaCheckCircle, FaChevronDown, FaTimes, FaWindowClose } from 'react-icons/fa'
import { fadeOutDown } from "react-animations";
import './language.css'

const LanguageChooser = ({ LanguageModal, setLanguageModal }) => {
    const listLanguage = [
        { id: 1, type: 'en', name: 'English' },
        { id: 1, type: 'es', name: 'Español' },
        { id: 1, type: 'id', name: 'Bahasa Indonesia' },
        { id: 1, type: 'ms', name: 'Bahasa Melayu' },
        { id: 1, type: 'ph', name: 'Tagalog' },
        { id: 1, type: 'pt', name: 'Português' },
        { id: 1, type: 'th', name: 'ภาษาไทย' },
        { id: 1, type: 'zh', name: '中文' },
    ]
    const language = localStorage.getItem('language')
    const handleLanguage = (type) => {
        localStorage.setItem('language', type)
        window.location.reload()
    }
    window.onclick = (e) => {
        console.log(e.target.className)
        if(e.target.className == 'language-chooser-modal-container') {
            setLanguageModal(false)
        }
    }
    React.useEffect(() => {
        if (LanguageModal) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }
    }, [LanguageModal])
    return (
        <>
                <div className={LanguageModal ? "language-chooser-modal-container-open" : "language-chooser-modal-container-close"}>
                </div>
                <div className='language-modal-container'>
                    <div  aria-hidden={!LanguageModal} className={LanguageModal === true ? 'transitionLanguageUp' : 'transitionLanguageDown'}>
                        <div className='container-chooser-language' >
                            <div className='container-close-language'>
                                <FaTimes onClick={() => setLanguageModal(false)} />
                            </div>
                            <div className='container-scroll-card'>
                                <div className='container-card'>
                                    {listLanguage.map((item, index) => {
                                        return (
                                            <div onClick={() => handleLanguage(item.type)} className={item.type === language ? 'card-language-choosed' : 'card-language'} >
                                                <p>{item.name}</p>
                                                {language === item.type && <FaCheckCircle />}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
        </>
    )
}

export default LanguageChooser