import React from 'react'
import PropType from 'prop-types';

import PromoDetailMerchantListContainer from './PromoDetailMerchantListContainer';

import {
    Calendar
} from '../../../assets';

import '../Promo.css';

const PromoDetail = ({ promo }) => {
    return (
        <div className="promo-detail-container">
            <div className="promo-detail-image-container">
                <img src={promo.image} alt="" className="promo-detail-image" />
            </div>
            <div className="promo-detail-text-container">
                <span className="promo-detail-title">{promo.name}</span>
                <span className="promo-detail-desc">{promo.desc}</span>
            </div>
            <div className="promo-detail-date">
                <span><Calendar id="calendar-small-icon" />{promo.start} sampai {promo.end}</span>
            </div>
            {promo.merchants.length === 0 ? <span><center>Tidak ada merchant pada promo ini.</center></span> : 
                <PromoDetailMerchantListContainer merchants={promo.merchants} />
            }
        </div>
    )
}

PromoDetail.propTypes = {
    promo: PropType.object.isRequired
}

export default PromoDetail
