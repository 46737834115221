import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { useCart } from '../../../application/hooks';
import { removeFromCart, addQtyItem, removeQtyItem, addQtyItemonDetail, addQtyItemAddon, editQtyItemAddon, setNoteItems } from '../../../application/actions/cartActions';
import { formatCurrency, generateUUID } from '../../../miscellaneous/helpers/utils';

import { AngleSmallLeft } from '../../../assets'
import { GoPlus, GoDash } from "react-icons/go";

import './Addon.css';
import { useState } from "react";
import AddonCard from "./AddonCard";
import { t } from '../../../miscellaneous/language';

const MerchantMenuListAddon = props => {
    const location = props.location.state;

    const [qty, setQty] = useState(location.qty || 1);
    const dispatch = useDispatch();
    const [addonChoosed, setAddonChoosed] = useState(location.addonChoosed || {});
    const [addonTotalPrice, setAddonTotalPrice] = useState(0);
    const [require, setRequire] = useState({})
    const [requireAlert, setRequireAlert] = useState(false)
    const [description, setDescription] = useState(location?.orderDesc);

    console.log(location, 'asdasdasdasd');

    const url = window.location.href;
    const lastIndex = url.lastIndexOf("/");
    const editString = url.substring(lastIndex + 1);

    // console.log(editString, 'kirakira');

    const store = useSelector((state) => ({
        merchants: state.merchants.details
    }), shallowEqual);

    console.log(props, 'ini adalah props asdasdsadasdsa');

    const checkRequire = (nameCat, numberRequire) => {
        setRequire((prevData) => ({
            ...prevData,
            [nameCat]: numberRequire > (addonChoosed[nameCat] ? addonChoosed[nameCat].length : 0)
        }))
    }

    const checkboxHandle = (checked, addon, catAddon) => {
        let processData = { ...addonChoosed }     
        const catAddonName = catAddon.name.split(' ').join('_') + '-*&id:' + catAddon.id
        const key = Object.keys(processData)
        console.log(catAddon.limit)
        // Variation_Category_1
        if (key.includes(catAddonName)) {
            if (checked) {
                if (processData[catAddonName].length < catAddon.limit || catAddon.limit === 0) {
                    processData[catAddonName].push(addon)
                    setAddonTotalPrice(prev => parseFloat(prev) + parseFloat(addon.price))
                }
            }
            else {
                processData[catAddonName] = processData[catAddonName].filter((item) => item.id !== addon.id)
                if (processData[catAddonName].length === 0) delete processData[catAddonName]
                setAddonTotalPrice(prev => parseFloat(prev) - parseFloat(addon.price));
            }
        }
        else {
            processData[catAddonName] = [addon]
            setAddonTotalPrice(prev => parseFloat(prev) + parseFloat(addon.price));
        }
        setAddonChoosed(processData)
    }

    const variableProps = {
        id: location.id,
        name: location.name,
        foto: location.image,
        price: location.price,
        total: location.total,
        orderDesc: location.orderDesc,
        item_cat_addon: location.item_cat_addon,
    }

    const [priceTotal, setPriceTotal] = useState(variableProps.price);

    const { addToCart, checkItemOnCart, itemQtyCounter, cart } = useCart(location.id);

    const mainFunction = {
        checkKeys: (keys, keysChoosed) => {
            if (keys.length !== keysChoosed.length) {
                return false
            }
            else {
                keys = keys.sort()
                keysChoosed = keysChoosed.sort()
                // console.log(keys, keysChoosed)
                let equalValues = true;
                for (let i = 0; i < keysChoosed.length; i++) {
                    if (keys[i] !== keysChoosed[i]) {
                        equalValues = false;
                        break;
                    }
                }
                return equalValues
            }
        },
        addItem: (id) => {
            const keysRequire = Object.keys(require)
            console.log(keysRequire, 'check son')
            if (keysRequire.length > 0) {
                const checkRequire = keysRequire.map((item) => require[item]).includes(true)
                // ini dipake
                if (!checkRequire) {
                    if (location.uuid_cart) {
                        if (itemQtyCounter(location.uuid_cart) >= 1) {
                            console.log(location.total_with_addon, 'asdfjbasdfijbsd');
                            dispatch(setNoteItems(location.uuid_cart, description));
                            // console.log(location.uuid_cart, description, 'kirakirakirakira');
                            dispatch(editQtyItemAddon(location.uuid_cart, qty, addonChoosed, (location.total_with_addon + addonTotalPrice)));
                            props.history.goBack();
                        }
                    }
                    else {
                        console.log('start')
                        console.log(cart)
                        const checkData = cart?.items?.map((item) => {
                            if(location.id === item.id) {
                                if(item?.addonChoosed) {
                                    let keysChoosed = Object.keys(item?.addonChoosed)
                                    let keys = Object.keys(addonChoosed)
                                    const checkKey = mainFunction.checkKeys(keys, keysChoosed)
                                    if (checkKey) {
                                        return keysChoosed.map((key) => {
                                            console.log(item.addonChoosed[key].sort((current, next) => current.id - next.id), addonChoosed[key].sort((current, next) => current.id - next.id), item.uuid_cart)
                                            if (JSON.stringify(item.addonChoosed[key].sort((current, next) => current.id - next.id)) === JSON.stringify(addonChoosed[key].sort((current, next) => current.id - next.id))) return item.uuid_cart
                                            else return false
                                        })
                                    }
                                    else {
                                        return [false]
                                    }
                                }
                                else {
                                    return [false]
                                }
                            }else {
                                return [false]
                            }
                            
                        })
                        const check = checkData.map((item) => {
                            if (!item.includes(false)) return item
                            else return false
                        }).filter(item => item !== false)
                        console.log(check, 'yoloooo')
                        if (check.length > 0 && itemQtyCounter(check[0][0]) >= 1) {
                            dispatch(addQtyItemAddon(check[0][0], qty, addonChoosed));
                            props.history.goBack();
                        }
                        else {
                            addToCart({ ...location, qty: qty, orderDesc: description, uuid_cart: generateUUID(), addonChoosed: addonChoosed, total_with_addon: variableProps.total + addonTotalPrice }, store.merchants);
                            props.history.goBack();
                        }
                    }
                }
                else {
                    setRequireAlert(true)
                }

            }
            else {
                setRequireAlert(true)
            }
        },
    }

    // console.log(variableProps.item_cat_addon, 'begitu saja');
    // console.log(addonChoosed, 'addon choosed');
    // console.log(require, 'asdasfa');
    // console.log(props, 'kirakira');

    return (
        <div className='merchant-menu-list-addon-container'>
            <div className='merchant-menu-list-header-card'>
                <div className="merchant-menu-list-header-card-button" onClick={() => props?.history?.goBack()}>
                    <AngleSmallLeft id="back-small-icon" />
                </div>
                <div className='merchant-menu-list-header-card-name'>
                    <b>{variableProps.name}</b>
                </div>

                <div className='merchant-menu-list-header-card-price'>
                    {variableProps.discount == 1 ?
                        <div className="merchant-menu-list-header-card-price-wrapper">
                            <p className="text-actual-price ">{variableProps.price}</p>
                            <p className="text-selling-price">&nbsp;{variableProps.total}</p>
                        </div>
                        :
                        <p className="text-selling-price ">{variableProps.total}</p>
                    }
                </div>
            </div>
            <hr />            

            <div className="merchant-menu-list-addon-space"></div>

            {
                variableProps.item_cat_addon.map((item, index) => {
                    // React.useEffect(() => {
                    //     item?.cat_addon?.name.split(' ').join('_')
                    // }, [addonChoosed])
                    console.log(item?.cat_addon, 'jalani semua')
                    return (
                        <>
                            <AddonCard item={item} addonChoosed={addonChoosed} checkboxHandle={checkboxHandle} checkRequire={checkRequire} requireAlert={requireAlert} requireData={require} />
                        </>
                    )
                })
            }


            <div className='merchant-menu-list-card'>
                <div className="merchant-menu-list-card-wrapper">
                    <div className='merchant-menu-list-card-title merchant-menu-list-card-vertical'>
                        {/* <div className='merchant-menu-list-card-title-main'>
                            <b>{t.text.info}</b>
                        </div> */}
                        <div className='merchant-menu-list-card-note-submain'>
                            <a style={{ color: 'gray' }}>{t.addon.optional}</a>
                        </div>
                        <blockquote>
                            <div style={{ borderBottom: "3px dashed #f5f5f5", marginBottom: '10px' }}></div>
                        </blockquote>
                        <div className='merchant-menu-list-card-item-desc'>
                            <input type="input" class="input-field-desc" placeholder="Name" name="name" id='name' value={description} onChange={(e) => setDescription(e.target.value)} required />
                            <label for="name" class="input-label-desc">{t.itemDetails.note}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='merchant-menu-list-card-bottom'>
                <div className='merchant-menu-list-card-bottom-header'>
                    <div className='merchant-menu-list-card-bottom-quantity'>
                        <p>{t.addon.qty}</p>
                    </div>
                    <div className='merchant-menu-list-card-bottom-button'>
                        <div className='merchant-menu-list-card-bottom-button-right'>
                            {/* <button onClick={() => setQty(qty + 1)}><AiOutlinePlus size={14} color={'orange'} /></button> */}
                            <button className="cart-detail-button-counter" onClick={() => setQty(qty - 1)} disabled={qty < 2 ? true : false}><GoDash className="button-counter-icon" /></button>
                            <a>&nbsp;&nbsp;{qty}&nbsp;&nbsp;</a>
                            {/* <button onClick={} disabled={qty > 1 ? false : true}><AiOutlineMinus size={14} color={'orange'} /></button> */}
                            <button className="cart-detail-button-counter" onClick={() => setQty(qty + 1)}><GoPlus className="button-counter-icon" /></button>
                        </div>
                    </div>
                </div>
                <div className='merchant-menu-list-card-bottom-big-button'>                    
                    <button onClick={() => mainFunction.addItem()}>{editString === "edit" ? t.addon.edititm : t.addon.additm} {((location.total_with_addon ? location.total_with_addon : location.total) + addonTotalPrice) * qty}</button>                    
                </div>
            </div>
        </div>
    )
}

export default MerchantMenuListAddon