import React from 'react'
import PromoBadge from '../components/common/Badge/PromoBadge'

import { formatCurrency } from '../../miscellaneous/helpers/utils';

import '../Merchant/Merchant.css'

const ItemResult = ({item, detailFunction}) => {
    
    return (
        <div className="explore-result-container" onClick={() => detailFunction(item, "None", 'item', item.merchants_id)}>
            <div className="merchant-menu-list-card">
                <div className="merchant-menu-list">
                    <div className="img-container">
                        <img src={item.image} class="merchant-menu-img" alt="" />
                    </div>
                    <div className="merchant-menu-list-detail">
                        <span className="merchant-menu-list-detail-name">{item.name}</span>
                        {item.discount === 1 ? <span className="merchant-menu-list-detail-discounted">{formatCurrency(item.total)}</span> : ''}
                        <span className="merchant-menu-list-detail-price">{item.discount === 1 ? <><s>{formatCurrency(item.price)}</s><PromoBadge /></> : formatCurrency(item.price)}</span>
                        <span className="explore-result-merchant-name-text">By <span id="orange-color">{item.merchant_name}</span></span>
                        <span className="explore-by-text"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ItemResult
