import React from 'react'
import { EmptyCartImg } from '../../../../assets'
import { useHistory } from 'react-router-dom';
import { t } from '../../../../miscellaneous/language';

const EmptyCart = () => {
    const history = useHistory();

    return (
        <div className="empty-cart-container">
            <EmptyCartImg id="empty-cart-img" />
            <div className="empty-cart-caption-container">
                <span>{t.text.emptyCart}</span>
                <button onClick={(e) => {history.push('/home')}}>{t.btn.shopping}</button>
            </div>
        </div>
    )
}

export default EmptyCart
