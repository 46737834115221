import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setRequestStatus } from '../../application/actions/miscActions';
import { getHistory, updateHistoryRating } from '../../application/actions/historyActions';

import HistoryItem from './HistoryItem'

import './History.css'
import { t } from '../../miscellaneous/language';

const HistoryList = () => {
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        history: state.history,
        requestStatus: state.app.requestStatus,
        isLoading: state.app.loading,
    }), shallowEqual);

    const updateRating = (starValue, orderId) => {
        dispatch(updateHistoryRating(starValue, orderId));
    }

    React.useEffect(() => {
        if (store.history.data.length === 0) {
            dispatch(getHistory());
        }
    }, [store.history.data.length, dispatch]);

    React.useEffect(() => () => {
        dispatch(setRequestStatus(''));
        // eslint-disable-next-line 
    }, []);

    return (
        <div className="history-container">
            {store.isLoading ? <span><center>{t.loading}</center></span> : <HistoryItem data={store.history.data} updateRating={updateRating} />}
        </div>
    )
}

export default HistoryList
