import React from "react"
import CryptoJS from "crypto-js";

const MerchantShareContainer = ({location}) => {
    const urlParam = new URLSearchParams(window.location.search);
    const code = decodeURIComponent(urlParam.get('share'))
    const share = code.split('-share-')[0]
    const shareMerchant = code.split('-share-')[1]

    React.useEffect(() => {
        const decrypt = CryptoJS.AES.decrypt(shareMerchant, share);
        const parsed = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8))
        localStorage.setItem('merchant-shared', parsed)
        const shared = localStorage.getItem('merchant-shared')
        if(shared){
            window.location = `maximzakaz://app?action=openhome`;
        }
    },[])
    return (
        <></>
    )
}

export default MerchantShareContainer