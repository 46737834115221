import React from "react";
import { useHistory } from 'react-router-dom';
import { GoPlus, GoDash, GoPencil } from "react-icons/go";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { confirmAlert } from 'react-confirm-alert';

import './ModalStyle.css';

// import { setNoteItems,  } from '../../../application/actions/cartActions';
import { addQtyItem, addQtyItemonDetail, setCartItems, removeFromCart, removeQtyItem } from "../../../../application/actions/cartActions";
import { useCart } from "../../../../application/hooks";
import { formatCurrency } from "../../../../miscellaneous/helpers/utils";
import { t } from "../../../../miscellaneous/language";
import Edit from '../../../../assets/icons/edit.svg';
import Editicon from '../../../../assets/icons/pencils.svg';

const MerchantItemAddExisting = ({ ItemsDetailsModal, items, onItemAddToCart, qty, setQty }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    // const [itemHandle, setItemHandle] = React.useState({});
    
    // console.log(itemHandle, 'testots');

    // console.log(ItemsDetailsModal, items);

    const store = useSelector((state) => ({
        cart: state.cart
    }), shallowEqual);
    
    // console.log(store.cart?.items, 'ini id');

    const { itemQtyCounter } = useCart(items?.id);

    const mainFunction = {
        AddonList: (data) => {
            history.push(`/merchant/${store?.cart?.merchant?.id}/addon`, data);
        },        
        Editaddon: (data) => {
            // console.log(data, 'xoxo');
            history.push(`/merchant/${store?.cart?.merchant?.id}/addon/edit`, data);
        },
        onItemAddToCart: (id) => {
            // console.log(id, 'namain')
            if (itemQtyCounter(id) >= 1) {
                dispatch(addQtyItem(id));
            }
        },
        onItemRemoveFromCart: (id) => {
            if (itemQtyCounter(id) > 1) {
                dispatch(removeQtyItem(id));
            } else {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className="alert">
                                <h1>{t.alert.cart}</h1>
                                <p>{t.alert.cartEmpty}</p>
                                <div className="alert-button-container">
                                    <button
                                        class="yes-button"
                                        onClick={() => {
                                            dispatch(removeFromCart(id));
                                            onClose();
                                        }}
                                    >
                                        {t.btn.yes}
                                    </button>
                                    <button
                                        class="no-button"
                                        onClick={onClose}
                                    >
                                        {t.btn.no}
                                    </button>
                                </div>
                            </div>
                        )
                    }
                })
            }
        },
    }
    // console.log(store, 'hihi');

    return (
        <div className='item-existing-modal-container'>
            <div className='item-details-modal-container'>
                <div aria-hidden={!ItemsDetailsModal} className={ItemsDetailsModal === true ? 'item-existing-modal-animation-up item-existing-modal-animation' : 'item-existing-modal-animation-down'}>
                    <div className='item-existing-modal-content'>
                        <div className="item-existing-modal-header">
                            <span>{items?.name}</span>
                        </div>
                        <hr className="splitter" />
                        {
                            store.cart?.items?.map((item) => {                                
                                if(item?.addonChoosed && item?.id === items?.id) return (
                                    <div className="item-existing-modal-body">
                                        <div className="item-existing-modal-body-text">
                                            {
                                                
                                                    Object?.keys(item?.addonChoosed).map((itm) => {
                                                        // console.log(Object?.keys(item?.addonChoosed), 'jajaja');
                                                        return (
                                                            <div className="item-existing-modal-body-category">
                                                                <span>{itm?.split('_').join(' ').split('-*&id:')[0]} :&nbsp;</span>
                                                                {
                                                                    <span>
                                                                        {
                                                                            item?.addonChoosed[itm]?.map((itm1, idx1) => {
                                                                                return (
                                                                                    <span>{(idx1 ? ', ' : '') + itm1?.name}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </span>
                                                                }
                                                            </div>
                                                        )

                                                    })                                                                                                        
                                            }
                                            <span className="item-content-align-right">{t.addon.price + ":"} {(items?.price * item?.qty)}</span>
                                        </div>
                                        <div className="item-existing-modal-body-button">
                                            <div className="item-existing-modal-body-button-wrapper">
                                                {/* <button className="item-existing-button" onClick={() => mainFunction.Editaddon(item)}><GoPencil className="item-existing-button-counter-icon" /></button> */}
                                                <button className="item-existing-button" onClick={() => mainFunction.Editaddon(item)}><img src={Editicon} width={15} height={15} /></button>
                                            </div>
                                            <div className="item-existing-modal-body-button-wrapper item-content-align-right">
                                                <button className="item-existing-button" onClick={() => mainFunction.onItemRemoveFromCart(item.uuid_cart)}><GoDash className="item-existing-button-counter-icon" /></button>
                                                <span className="item-existing-add-number">{item.qty}</span>
                                                <button className="item-existing-button" onClick={() => mainFunction.onItemAddToCart(item.uuid_cart)}><GoPlus className="item-existing-button-counter-icon" /></button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <hr className="splitter" />
                        <div className="item-existing-modal-footer">
                            <a onClick={() => mainFunction.AddonList(items)}>{t.addon.addcustome}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MerchantItemAddExisting