import React from 'react'
import { Helmet } from 'react-helmet'
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import Explore from '../Explore/Explore';

import {
    SearchTopNav,
    BottomSpace,
    BottomNav,
    MerchantDetailButton
} from '../components/common'
import { getMerchantDetails } from '../../application/actions/merchantActions';
import MerchantDetailItem from '../Merchant/MerchantDetailItem';
import ItemDetail from '../Merchant/ItemDetail';
import ItemsDetails from '../Merchant/ItemDetails'

const ExploreContainer = () => {
    const dispatch = useDispatch();
    
    const store = useSelector((state) => ({
        merchants: state.merchants,
        merchants_details: state.merchants.details,
        requestStatus: state.app.requestStatus,
        isLoading: state.app.loading
    }), shallowEqual);

    const [clickedDetail, setClickedDetail] = React.useState(false);
    const [detailData, setDetailData] = React.useState();
    const [selectedItemCat, setSelectedItemCat] = React.useState("None");
    const [typeModal, setTypeModal] = React.useState(null)

    const handleDetailClick = async (itemDetail, itemCat, type) => {
        if (type === 'item') {
            if (itemDetail && itemCat) {
                await window.scrollTo(0, 0);
                await dispatch(getMerchantDetails(itemDetail.merchant_id));
                setDetailData(itemDetail);
                setSelectedItemCat(itemCat);
                setClickedDetail(true);
                setTypeModal(type);
            }
        }
        else if (type === 'itemaddexsisting') {
            if(itemDetail && itemCat) {
                // await window.scrollTo(0, 0);
                setDetailData(itemDetail);
                setSelectedItemCat(itemCat);
                setClickedDetail(true);
                setTypeModal(type);
            }
        }
        else {
            setDetailData();
            setSelectedItemCat("None");
            setClickedDetail(false)
        }
    }

    React.useEffect(() => {
    }, [clickedDetail])

    return (
        <>
            <Helmet>
                <title>{'Foods&Goods - Telusur'}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={'Telusuri merchant dan juga barang'} />
            </Helmet>
            <SearchTopNav isClicked={clickedDetail} handleDetailClick={handleDetailClick}/>

            {/* {clickedDetail ? 
            <>
                <MerchantDetailItem merchant_details={store.merchants.details}/>
                <ItemDetail item={detailData} itemCat={selectedItemCat}/>
                <BottomSpace />
                <MerchantDetailButton />
            </> : 
            <> */}
                <Explore detailFunction={handleDetailClick}/>
                <BottomSpace />
                <BottomNav />
            {/* </>} */}
            <ItemsDetails type={typeModal} handleDetailClick={handleDetailClick} merchant={store.merchant_details} itemCat={selectedItemCat} is_load={store.isLoading} items={detailData} ItemsDetailsModal={clickedDetail} close={handleDetailClick} explore={true}/>
        </>
    )
}

export default ExploreContainer
