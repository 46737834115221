const En = {
    btn: {
        saveName: 'Simpan nama',
        yes: 'Ya',
        no: 'Tidak',
        add: 'Tambah',
        viewCart: 'Lihat keranjang saya',
        alertOk: 'Saya mengerti',
        confirmOrder: 'Konfirmasi pesanan Anda',
        shopping: `Ayo kita berbelanja!`,
        status: {
            orderStatus0: 'Segarkan status pesanan',
            orderStatus1: 'Periksa status pengiriman',
            orderStatus9: 'Pesanan dari merchant yang lain'
        }
    },
    text: {
        info: 'Catatan',
        nonPartnerInfo: 'Merchant ini bukan mitra',
        saveName: 'Hai. Sebelum melanjutkan, mohon masukkan nama Anda...',
        itemitem: 'Barang-barang',
        item: 'barang',
        items: 'barang-barang',
        empty: 'Habis',
        orderList: 'Daftar pesanan',
        billDetail: 'Detail tagihan',
        totalPrice: 'Total harga',
        totalDisc: 'Total diskon',
        platformFee: 'Biaya platform',
        parkingFee: 'Biaya parkir',
        deliveryFee: 'Biaya pengantaran dan penanganan',
        takeAwayCharge: "Biaya dibawa pulang",
        unavailable: "Tidak tersedia",
        toBePaid: 'Yang harus dibayarkan',
        emptyCart: 'Keranjang belanja Anda masih kosong :(',
        orderPlaced: {
            title: 'Pesanan telah sukses ditempatkan',
            desc: 'Menunggu merchant untuk konfirmasi pesanan Anda'
        },
        driverFound: {
            title: 'Pengemudi ditemukan',
            desc: 'Terima kasih telah menggunakan layanan Maxim'
        },
        driverResto: {
            title: 'pengemudi berada di merchant',
            desc: 'Pengemudi telah sampai di tempat merchant'
        },
        merchantCancel: {
            title: 'Pesanan anda dibatalkan',
            desc: 'Merchant membatalkan pesanan Anda'
        },
        merchantconfirm: {
            title: 'Persanan anda terkonfirmasi',
            desc: 'Merchant mengonfirmasi pesanan'
        },
        itemPickedUp: {
            title: 'pesanan anda sedang dikirimkan',
            desc: 'Pesanan ada sedang dalam proses'
        },
        completeStatus: {
            title: 'Pesanan anda telah selesai',
            desc: 'Terima kasih telah menggunakan layanan Maxim'
        },
        userCancel: {
            title: 'Pesanan anda dibatalkan',
            desc: 'Pengguna membatalkan pesanan Anda'
        },
        buyerName: 'Nama pembeli',
        receiveAt: 'Diterima pada',
        status: 'Status',
        paymentType: 'Jenis pembayaran',
        cash: 'Tunai',
        note: 'Catatan: ',
        noNote: 'Tidak ada catatan',
        noteMerchant: 'Catatan untuk merchant',
        MerchantDetailDiscountContainer: { recommend: 'Rekomendasi untuk Anda' },
        AddToCart: 'Tambahkan ke keranjang',
        deliverTo: 'Kirim ke',
        merchantName: 'Nama merchant',
        deliveryAddress: 'Alamat pengiriman',
        phoneNumber: 'Nomor telepon',
        merchantAddress: 'Alamat merchant',
        additional: 'Tambahan',
    },
    label: { user_name: 'Nama Anda: ' },
    placeholder: {
        name: 'Silakan masukkan nama Anda',
        searchHome: 'Mencari merchant atau barang ...',
        searchCategory: 'Pencarian untuk merchant',
        noteItemDetail: 'Tulis sesuatu yang detail disini',
        searchItem: 'Pencarian untuk barang ...',
        noteMerchant: 'Tuliskan komentar Anda atau masukan untuk merchant'
    },
    alert: {
        orderCancel: 'Pesanan ini telah dibatalkan',
        nonPartner: 'Bukan mitra',
        partnerPlus: 'Harga order merupakan perkiraan dan bisa saja berubah. Silakan minta pengemudi untuk menyediakan faktur untuk referensi pembayaran produk.',
        noteNonPartner: 'Merchant ini bukan mitra resmi Maxim, ketersediaan produk dan akurasi harga dapat berubah dan berbeda dengan kondisi sebenarnya. Lanjutkan?',
        cart: 'Keranjang',
        cartEmpty: 'Hanya tersisa satu untuk barang/menu yang ada di dalam keranjang anda, mengurangi jumlahnya berarti menghapus dari keranjang anda... Lanjutkan?',
        alert: 'Tanda',
        noteProcessOrder: 'Server sedang memproses permintaan anda, mohon menunggu... Jika proses terlalu lama, silakan restart aplikasi anda atau silakan periksa sambungan internet anda.',
        confirmOrder: 'Konfirmasi pesanan',
        noteConfirmOrder: 'Apakah anda yakin untuk melakukan pesanan? Silakan periksa kembali pesanan sebelum mengonfirmasinya.',
        noteCountDeliveryPrice: 'Terjadi kesalahan saat menghitung ongkos kirim, silakan restart aplikasi untuk melanjutkan.',
        noteDeliveryPrice: 'Terjadi kesalahan saat menentukan lokasi anda untuk penghitungan ongkos kirim, silakan restart aplikasi dan pastikan GPS anda menyala untuk melanjutkan.',
        notePlatformPrice: 'Terjadi kesalahan saat menghitung biaya platform, silakan restart aplikasi untuk melanjutkan.',
        noteNonPartnerReceipt: 'Merchant ini bukan merchant resmi Maxim, ketersediaan barang dan harga mungkin berbeda. Silakan melakukan pembayaran sesuai dengan harga yang tertulis dalam kuitansi.',
        merchantClose: 'Merchant tutup!'
    },
    helmet: {
        title: {
            start: 'Foods&Goods - Mulai',
            Home: 'Foods&Goods',
            Category: 'Foods&Goods - Kategori merchant',
            History: 'Foods&Goods - Riwayat',
            DetailCart: 'Foods&Goods - Detail keranjang',
            afterOrder: 'Foods&Goods - Setelah pesanan',
            HistoryDetail: 'Foods&Goods - Detail riwayat'
        },
        desc: {
            start: 'Mulai',
            Home: 'Foods&Goods',
            Category: 'Daftar merchant berdasarkan kategori',
            History: 'Daftar transaksi Anda sebelumnya',
            DetailCart: 'Detail barang-barang yang ada dalam keranjang Anda',
            afterOrder: 'Pesanan saya',
            HistoryDetail: 'Detail riwayat'
        }
    },
    topNav: { title: 'Foods&Goods' },
    backTopNav: { History: 'Riwayat' },
    loading: 'Memuat…',
    data: {
        noDataMerchant: 'Merchant tidak ditemukan',
        DataMerchant: 'Merchant ditemukan',
        notFound: 'Tidak ditemukan'
    },
    bottomNav: {
        Home: 'Beranda',
        Explore: 'Telusuri',
        Promo: 'Promo',
        History: 'Riwayat',
        Cart: 'Keranjang'
    },
    status: {
        Open: 'Buka',
        Close: 'Tutup',
        case0: 'Menunggu persetujuan dari toko',
        case1: 'Pesanan disetujui oleh toko',
        case2: 'Pengemudi Maxim sedang mengantarkan pesanan',
        case3: 'Pengemudi Maxim telah ditunjuk untuk mengantarkan pesanan',
        case4: 'Pengemudi sedang menunggu',
        case11: 'Pesanan selesai',
        case9: 'Pesanan dibatalkan',
        caseNotFound: 'Status pesanan tidak ditemukan',
        orderStatus0: 'Menunggu',
        orderStatus1: 'Disetujui oleh merchant',
        orderStatus2: 'Dijemput oleh pengemudi',
        orderStatus3: 'Pengemudi ditugaskan',
        orderStatus4: 'Pengemudi sedang menunggu',
        orderStatus9: 'Dibatalkan oleh merchant',
        orderStatus11: 'Selesai',
        orderStatus99: 'Dibatalkan oleh pengguna',
        orderStatusNotFound: 'Status tidak ditemukan',
        itemNotFound: 'Tidak ada barang yang ditemukan dalam kategori ini'
    },
    receiveAt: { statusAccepted: 'Belum diterima' },
    bottomConfirm: {
        payment: {
            title: 'Metode pembayaran digital',
            title2: 'Metode pembayaran lainnya',
            cash: 'Tunai',
            noncash: 'Non-tunai',
            paywith: 'Bayar dengan',
            soon: 'Segera'
        },
        btn: { confirm: 'Konfirmasi dan proses untuk memesan' }
    },
    itemDetails: { note: 'Catatan untuk merchant' },
    addon: {
        optional: 'Opsional',
        qty: 'Jumlah',
        additm: 'Tambah barang',
        edititm: 'Perbaharui barang',
        max: 'Pilih maks',
        edit: 'Edit',
        require: 'Wajib',
        selectrequire: 'Pilihan minimum',
        price: 'Harga',
        addcustome: 'Tambah pilihan lainnya',
        free: 'Gratis'
    },
    promo: { listMerchants: 'Daftar merchant' }
};
export default En;