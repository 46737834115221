import {
    GET_HISTORY,
    GET_HISTORY_SUCCESS,
    CLEAR_HISTORY_STATE,
    UPDATE_HISTORY_RATING
} from '../../miscellaneous/constants';

const INITIAL_STATE = {
    total: 0,
    pageSize: 1,
    data: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_HISTORY:
            return {
                ...state,
                pageSize: payload.pageSize
            }
        case GET_HISTORY_SUCCESS:
            return {
                ...state,
                total: payload.total,
                data: [...payload.history]
            } 
        case CLEAR_HISTORY_STATE:
            return {
                ...state,
                history: INITIAL_STATE
            }
        case UPDATE_HISTORY_RATING:
        default: return state;
    }
}