import  { 
    GET_PROMO_MERCHANTS, 
    GET_PROMO_MERCHANT_ITEMS, 
    GET_PROMO_NEW_MERCHANT
} from '../../../miscellaneous/constants';
    
import {
    select,
    call,
    put
} from 'redux-saga/effects';

import { actionMessage } from '../../../miscellaneous/helpers/utils';

import { setLoading, setRequestStatus } from '../../actions/miscActions';
import { 
    getPromoMerchantsSuccess,
    getPromoMerchantItemsSuccess,
    getPromoNewMerchantSuccess
} from '../../actions/promoActions';

import { 
    getPromoAPI,
    getPromoItemAPI,
    getPromoNewAPI
} from '../../../infrastructure/services/api/Promo';

import { history } from '../../routers/Router';

export const getUserData = ((state) => state.auth.userData);

function* initRequest() {
    yield put(setLoading(true));
    yield put(setRequestStatus(null));
}

function* handleError(e) {
    yield put(setLoading(false));
    yield put(setRequestStatus(e?.message || 'Failed to fetch data history'));
    console.log('ERROR: ', e);
}

function* handleAction(location, message, status) {
    if (location) yield call(history.push, location);
    yield call(actionMessage, message, status);
}

function* promoSaga({ type, payload }) {
    const userData = yield select(getUserData);

    switch (type) {
        case GET_PROMO_MERCHANTS:
            try {
                yield initRequest();

                const response = yield call(getPromoAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city, 
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.pageSize
                );
                console.log(response);

                if (response.result.length === 0) {
                    // handleError('No merchants found.');
                    yield put(getPromoMerchantsSuccess({
                        requestComplete: true,
                        merchants: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                } else {
                    yield put(getPromoMerchantsSuccess({
                        requestComplete: true,
                        merchants: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        case GET_PROMO_MERCHANT_ITEMS:
            try {
                yield initRequest();

                const response = yield call(getPromoItemAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city, 
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.pageSize
                );
                console.log(response);

                if (response.result.length === 0) {
                    // handleError('No merchants found.');
                    yield put(getPromoMerchantItemsSuccess({
                        requestComplete: true,
                        merchants: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                } else {
                    yield put(getPromoMerchantItemsSuccess({
                        requestComplete: true,
                        merchants: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        case GET_PROMO_NEW_MERCHANT:
            try {
                yield initRequest();

                const response = yield call(getPromoNewAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city, 
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.pageSize
                );
                console.log(response);

                if (response.result.length === 0) {
                    // handleError('No merchants found.');
                    yield put(getPromoNewMerchantSuccess({
                        requestComplete: true,
                        merchants: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                } else {
                    yield put(getPromoNewMerchantSuccess({
                        requestComplete: true,
                        merchants: response.result,
                        total: response.result.length
                    }));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        default: { throw new Error(`Unexpected action type ${type}`); }
    }
}

export default promoSaga;