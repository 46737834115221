import {
    LOADING,
    SET_REQUEST_STATUS
} from '../../miscellaneous/constants';

const INITIAL_STATE = {
    loading: false,
    requestStatus: ''
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case LOADING:
            return {
                ...state,
                loading: payload
            }
        case SET_REQUEST_STATUS:
            return {
                ...state,
                requestStatus: payload
            }
        default: return state;
    }
}