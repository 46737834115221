import React from 'react'
import { Cancel } from '../../assets'
import { t } from '../../miscellaneous/language'

const MerchantConfirmStatusCancel = ({updateStaticStatus}) => {
    return (
        <div className="after-order-card">
            <div className="after-order-status-container">
                <span className="after-order-title">{t.text.merchantCancel.title}</span>
                <span className="after-order-desc">{t.text.merchantCancel.desc}</span>
            </div>
            <div className="after-order-icon-container">
                <Cancel id="confirm-medium-icon" />
            </div>
        </div>
    )
}

export default MerchantConfirmStatusCancel