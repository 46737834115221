import { Input, TextField } from "@material-ui/core"
import React from "react";
import { GoPlus, GoDash } from "react-icons/go";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { addQtyItem, addQtyItemonDetail } from "../../application/actions/cartActions";
import { getMerchantDetails } from "../../application/actions/merchantActions";
import { useCart } from "../../application/hooks";
import { cloneObject, formatCurrency, generateUUID } from "../../miscellaneous/helpers/utils";
import { t } from "../../miscellaneous/language";
import MerchantItemAddExisting from "../components/common/Modal/MerchantItemAddExisting";

const ItemsDetails = ({ ItemsDetailsModal, handleDetailClick, items, itemCat, merchant, is_load, type, explore = false }) => {
    const history = useHistory()
    const close = handleDetailClick
    const { addToCart, checkItemOnCart, itemQtyCounter, checkItemOnCartNoAddon } = useCart(items?.id);
    const dispatch = useDispatch()
    const [qty, setQty] = React.useState(1);
    const [itemExplore, setItemExplore] = React.useState(null)

    const store = useSelector((state) => ({
        merchants: state.merchants.details,
        requestStatus: state.app.requestStatus,
        isLoading: state.app.loading,
        cart: state.cart,
    }), shallowEqual);

    React.useEffect(() => {
        // console.log(itemQtyCounter(items?.id), 'cek count');
        let uuidNoAddon = checkItemOnCartNoAddon(items?.id)?.uuid_cart
        if(uuidNoAddon) {
            if (itemQtyCounter(uuidNoAddon)) {
                setQty(itemQtyCounter(uuidNoAddon))
            }
            else {
                // console.log('set1qty');
                setQty(1)
            }
        }

        if(explore) {
            if(items?.item_cat_addon?.length > 0) {
                if(store.merchants?.merchant_item_category && items) {
                    setItemExplore(store.merchants.merchant_item_category.find((item) => item.id === items?.item_categories_id).items?.find((item) => item.id === items?.id))
                }
            }
        }
    }, [items, store.merchants])

    React.useEffect(() => {
        if (ItemsDetailsModal) {
            document.body.style.overflow = 'hidden';
            return () => document.body.style.overflow = 'unset';
        }
    }, [ItemsDetailsModal]);

    const onItemAddToCart = (id) => {
        let uuidNoAddon = checkItemOnCartNoAddon(id)?.uuid_cart
        if(uuidNoAddon) {
            id = uuidNoAddon
        }
        if (itemQtyCounter(id) >= 1) {
            dispatch(addQtyItemonDetail(id, qty));
        } else {
            addToCart({ ...items, qty: qty, orderDesc: null, uuid_cart: generateUUID() }, merchant ? merchant : store.merchants);
        }
        close()
    }

    // console.log(type, 'this is type')

    return (
        <>
            <div onClick={close} className={ItemsDetailsModal ? "item-details-modal-container-open" : "item-details-modal-container-close"}>
            </div>
            {
                type === "itemaddexsisting" ?
                    <MerchantItemAddExisting 
                        ItemsDetailsModal={ItemsDetailsModal}
                        items={items}
                        onItemAddToCart={onItemAddToCart}
                        qty={qty}
                        setQty={setQty}
                    />
                    : type === "item" ?
                        <div className='item-details-modal-container'>
                            <div aria-hidden={!ItemsDetailsModal} className={ItemsDetailsModal === true ? 'transitionUp item-transition' : 'transitionDown'}>
                                <div className='container-item-details' >
                                    <img src={items?.image ? items?.image : "https://id-marketplace.sgp1.cdn.digitaloceanspaces.com/files/merchant/70Ht3pFljemj63FACWmkjUitnLzBKHFo0RtzzYoz.png"} onError={(e) => (e.target.src = 'https://id-marketplace.sgp1.cdn.digitaloceanspaces.com/files/merchant/70Ht3pFljemj63FACWmkjUitnLzBKHFo0RtzzYoz.png')} alt={'Image for item: ' + items?.name} class="photo-item-details-container" />
                                    {/* <div className="photo-item-details-container"></div> */}
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <p className="item-details-text-name">{items?.name}</p>
                                        <div>
                                            <p className="item-details-text-price">{items?.is_empty === 0 ? 'Sold Out' : formatCurrency(items?.total)}</p>
                                            {items?.discount === 1 &&
                                                <p className="item-details-text-disc-price">{formatCurrency(items?.price)}</p>
                                            }
                                        </div>
                                    </div>
                                    <div >
                                        <div className="item-details-text-desc">{items?.desc}</div>
                                        {/* <input type="text" style={{ margin:16 }} /> */}
                                        {!(items?.item_cat_addon?.length > 0) && 
                                        <div class="group-input">
                                            <input type="input" class="input-field" placeholder="Name" name="name" id='name' required />
                                            <label for="name" class="input-label">{t.itemDetails.note}</label>
                                        </div>
                                        }
                                    </div>
                                    <div className="item-details-modal-button-container">
                                        {!(items?.item_cat_addon?.length > 0) && 
                                            <div className="item-details-modal-button-wrapper">
                                                <div>
                                                    {
                                                        qty > 1 ?
                                                            <button className="minus-plus-button"><GoDash onClick={() => setQty(qty - 1)} /></button>
                                                            :
                                                            <button className="minus-plus-button-readonly" readonly="true"><GoDash /></button>
                                                    }
                                                </div>
                                                <div className="item-details-modal-quantity">
                                                    <text className="item-details-modal-text">{qty}</text>
                                                    <text className="item-details-modal-text-gray">{((items?.price) * qty)}</text>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        // background: 'red'
                                                    }}>
                                                    <button className="minus-plus-button"><GoPlus onClick={() => setQty(qty + 1)} /></button>
                                                </div>
                                            </div>
                                        
                                        }
                                        <div className={(items?.is_empty === 0 ? 'disabled-addToCart-details container-text-button-addToCart-details' : "container-text-button-addToCart-details")} 
                                            style={(items?.item_cat_addon?.length > 0) ? {width:'100%'} : {}}
                                            onClick={() => {
                                                if(!explore && items?.is_empty === 1){
                                                (checkItemOnCartNoAddon(items?.id) ?
                                                (items?.item_cat_addon?.length > 0) ?
                                                handleDetailClick(items, itemCat, 'itemaddexsisting') :
                                                onItemAddToCart(items?.id)
                                                :
                                                (items?.is_empty === 1 || !is_load) &&  
                                                (items?.item_cat_addon?.length > 0) ?
                                                    history.push(`/merchant/${store?.merchants?.id}/addon`, items) :
                                                    onItemAddToCart(items?.id))
                                                }
                                                else if(explore && items?.is_empty === 1) {
                                                    // const dataItem = store.merchants.merchant_item_category.find((item) => item.id === items.item_categories_id).items.find((item) => item.id === items.id)
                                                // console.log(itemExplore, 'bijiq')
                                                    
                                                (items?.is_empty === 1 || !is_load) &&  
                                                (items?.item_cat_addon?.length > 0) ?
                                                    history.push(`/merchant/${store?.merchants?.id}/addon`, itemExplore) :
                                                    onItemAddToCart(items?.id)
                                                }
                                            }
                                            }>
                                            <p className="text-button-addToCart-details">{items?.is_empty === 0 ? t.text.empty : is_load ? 'Loading' : t.text.AddToCart}</p>
                                        </div>
                                    </div>
                                    {/* <div className='container-close-language'>
                                <FaTimes onClick={() => setLanguageModal(false)} />
                            </div>
                            <div className='container-scroll-card'>
                                <div className='container-card'>
                                    {listLanguage.map((item, index) => {
                                        return (
                                            <div onClick={() => handleLanguage(item.type)} className={item.type === language ? 'card-language-choosed' : 'card-language'} >
                                                <p>{item.name}</p>
                                                {language === item.type && <FaCheckCircle />}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> */}
                                </div>
                            </div>

                        </div>
                        :
                        <></>
            }
        </>
    )
}

export default ItemsDetails