import {
    applyMiddleware,
    compose,
    createStore
} from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './reducers/sagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appPersistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart', 'auth', 'checkout']
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const store = createStore(
        persistCombineReducers(appPersistConfig, rootReducer),
        composeEnhancer(applyMiddleware(sagaMiddleware))
    );

    const persistor = persistStore(store);
    sagaMiddleware.run(rootSaga);

    return { store, persistor }
}