import React from 'react'
import ReactLoading from 'react-loading'

import { t } from "../../../../miscellaneous/language";

import './loading.css'

const LoadingComponent = () => {
    return (
        <div className="loading-wrapper">            
            <div className="loading-content">
                <ReactLoading height={30} width={30} type="spinningBubbles" color="#FF6000" />
                {/* <span className="loading-content-text">{t.loading}</span> */}
            </div>
        </div>
    )
}

export default LoadingComponent