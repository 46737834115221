import React from 'react'

const EmptyCartImg = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} width={props.width} height={props.height} viewBox="0 0 283 198.721">
            <g id="Group_49" data-name="Group 49" transform="translate(-43 -141.279)">
                <g id="Cart" transform="translate(-713 -4.463)">
                <g id="Group_48" data-name="Group 48" transform="translate(829 178.963)">
                    <path id="Path_17" data-name="Path 17" d="M119.854,36.384H102.732C102.732,16.289,87.4,0,68.488,0S34.244,16.289,34.244,36.384H17.122C7.665,36.384,0,44.529,0,54.576v60.641c.019,16.737,12.784,30.3,28.537,30.32h79.9c15.752-.02,28.518-13.583,28.537-30.32V54.576C136.976,44.529,129.311,36.384,119.854,36.384ZM68.488,12.128c12.608,0,22.829,10.86,22.829,24.257H45.659C45.659,22.988,55.88,12.128,68.488,12.128Zm57.073,103.088c0,10.047-7.665,18.192-17.122,18.192h-79.9c-9.457,0-17.122-8.145-17.122-18.192V54.576a5.894,5.894,0,0,1,5.707-6.064H34.244V60.641a5.718,5.718,0,1,0,11.415,0V48.512H91.317V60.641a5.718,5.718,0,1,0,11.415,0V48.512h17.122a5.894,5.894,0,0,1,5.707,6.064Z" fill="none" stroke="#ff6000" stroke-width="1"/>
                    <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(-13 81.5)" fill="#fff" stroke="#ff6000" stroke-width="1" stroke-dasharray="4 2">
                    <circle cx="20" cy="20" r="20" stroke="none"/>
                    <circle cx="20" cy="20" r="19.5" fill="none"/>
                    </g>
                </g>
                </g>
                <g id="Ellipse_8" data-name="Ellipse 8" transform="translate(159 254)" fill="#ff6000" stroke="#ff6000" stroke-width="1">
                <circle cx="5" cy="5" r="5" stroke="none"/>
                <circle cx="5" cy="5" r="4.5" fill="none"/>
                </g>
                <g id="Path_19" data-name="Path 19" transform="translate(200 254)" fill="#ff6000">
                <path d="M 5 9.5 C 2.51869010925293 9.5 0.5 7.48130989074707 0.5 5 C 0.5 2.51869010925293 2.51869010925293 0.5 5 0.5 C 7.48130989074707 0.5 9.5 2.51869010925293 9.5 5 C 9.5 7.48130989074707 7.48130989074707 9.5 5 9.5 Z" stroke="none"/>
                <path d="M 5 1 C 2.794390201568604 1 1 2.794390201568604 1 5 C 1 7.205610275268555 2.794390201568604 9 5 9 C 7.205610275268555 9 9 7.205610275268555 9 5 C 9 2.794390201568604 7.205610275268555 1 5 1 M 5 0 C 7.761420249938965 0 10 2.238580226898193 10 5 C 10 7.761420249938965 7.761420249938965 10 5 10 C 2.238580226898193 10 0 7.761420249938965 0 5 C 0 2.238580226898193 2.238580226898193 0 5 0 Z" stroke="none" fill="#ff6000"/>
                </g>
                <path id="Path_18" data-name="Path 18" d="M1892,278.667s27.333-7.333,36.667,3.333" transform="translate(-1720)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <text id="_0" data-name="0" transform="translate(117 284)" fill="#ff6000" font-size="23" font-family="Roboto-Bold, Roboto" font-weight="700"><tspan x="0" y="0">0</tspan></text>
                <line id="Line_10" data-name="Line 10" x2="36" transform="translate(77.5 320.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <line id="Line_11" data-name="Line 11" x2="56" transform="translate(108.5 337.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <line id="Line_12" data-name="Line 12" x2="6" transform="translate(45.5 320.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <circle id="Ellipse_9" data-name="Ellipse 9" cx="2.5" cy="2.5" r="2.5" transform="translate(62 318)" fill="#ff6000"/>
                <line id="Line_13" data-name="Line 13" x2="6" transform="translate(74.5 337.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="2.5" cy="2.5" r="2.5" transform="translate(91 335)" fill="#ff6000"/>
                <line id="Line_14" data-name="Line 14" x2="36" transform="translate(284.5 320.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <line id="Line_15" data-name="Line 15" x2="6" transform="translate(252.5 320.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <circle id="Ellipse_11" data-name="Ellipse 11" cx="2.5" cy="2.5" r="2.5" transform="translate(269 318)" fill="#ff6000"/>
                <line id="Line_16" data-name="Line 16" x2="56" transform="translate(236.5 337.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <line id="Line_17" data-name="Line 17" x2="6" transform="translate(202.5 337.5)" fill="none" stroke="#ff6000" stroke-linecap="round" stroke-width="5"/>
                <circle id="Ellipse_12" data-name="Ellipse 12" cx="2.5" cy="2.5" r="2.5" transform="translate(219 335)" fill="#ff6000"/>
                <g id="Ellipse_13" data-name="Ellipse 13" transform="translate(78 296)" fill="#fff" stroke="#ff6000" stroke-width="1">
                <circle cx="6" cy="6" r="6" stroke="none"/>
                <circle cx="6" cy="6" r="5.5" fill="none"/>
                </g>
                <g id="Ellipse_14" data-name="Ellipse 14" transform="translate(46 239)" fill="#fff" stroke="#ff6000" stroke-width="1">
                <circle cx="8.5" cy="8.5" r="8.5" stroke="none"/>
                <circle cx="8.5" cy="8.5" r="8" fill="none"/>
                </g>
                <g id="Ellipse_15" data-name="Ellipse 15" transform="translate(309 279)" fill="#fff" stroke="#ff6000" stroke-width="1">
                <circle cx="8.5" cy="8.5" r="8.5" stroke="none"/>
                <circle cx="8.5" cy="8.5" r="8" fill="none"/>
                </g>
                <g id="Polygon_1" data-name="Polygon 1" transform="translate(87.273 171.322) rotate(30)" fill="#fff">
                <path d="M 18.12162780761719 15.5 L 0.87837153673172 15.5 L 9.5 0.9793626070022583 L 18.12162780761719 15.5 Z" stroke="none"/>
                <path d="M 9.5 1.958708763122559 L 1.756732940673828 15 L 17.24326705932617 15 L 9.5 1.958708763122559 M 9.5 0 L 19 16 L 0 16 L 9.5 0 Z" stroke="none" fill="#ff6000"/>
                </g>
                <g id="Polygon_2" data-name="Polygon 2" transform="translate(282.5 150.5) rotate(90)" fill="#fff">
                <path d="M 18.12162780761719 15.5 L 0.87837153673172 15.5 L 9.5 0.9793626070022583 L 18.12162780761719 15.5 Z" stroke="none"/>
                <path d="M 9.5 1.958708763122559 L 1.756732940673828 15 L 17.24326705932617 15 L 9.5 1.958708763122559 M 9.5 0 L 19 16 L 0 16 L 9.5 0 Z" stroke="none" fill="#ff6000"/>
                </g>
                <g id="Ellipse_16" data-name="Ellipse 16" transform="translate(260 177)" fill="#fff" stroke="#ff6000" stroke-width="1">
                <circle cx="6" cy="6" r="6" stroke="none"/>
                <circle cx="6" cy="6" r="5.5" fill="none"/>
                </g>
                <g id="Ellipse_17" data-name="Ellipse 17" transform="translate(130 171)" fill="#fff" stroke="#ff6000" stroke-width="1">
                <circle cx="3.5" cy="3.5" r="3.5" stroke="none"/>
                <circle cx="3.5" cy="3.5" r="3" fill="none"/>
                </g>
                <path id="Path_20" data-name="Path 20" d="M8.811-10.347h4.583v3.313H8.811v5.18H5.319v-5.18H.724v-3.313h4.6v-4.964H8.811Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 108.019, 155.881)" fill="#ff6000"/>
                <path id="Path_21" data-name="Path 21" d="M8.811-10.347h4.583v3.313H8.811v5.18H5.319v-5.18H.724v-3.313h4.6v-4.964H8.811Z" transform="translate(288 239)" fill="#ff6000"/>
                <path id="Path_22" data-name="Path 22" d="M8.811-10.347h4.583v3.313H8.811v5.18H5.319v-5.18H.724v-3.313h4.6v-4.964H8.811Z" transform="matrix(0.966, 0.259, -0.259, 0.966, 81.019, 229.881)" fill="#ff6000"/>
            </g>
            </svg>
    )
}

export default EmptyCartImg
