import axios from 'axios';

const baseURL = 'https://idmarket-api.taximaxim.com/api/v1/home/merchant'; // prod
// const baseURL = 'http://localhost:8001/api/v1/home/merchant'; // dev
const today = new Date()
const day = today.getDay()
const time = `${today.getHours() < 10 ? 0 : ''}${today.getHours()}:00:00`
console.log(time)

export const getMerchantsAPI = async (userID, lat, lon, city, host, name, addressName, type, page, param = null) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            // day,
            // time,
            ...(type === 'FILTER_NEAR_ME' ? { page, type: 0 } : 
                type === 'FILTER_DISTRICT' ? { page, district: param, type: 1 } : 
                type === 'SEARCH' ? { page, param } : 
                type === 'CATEGORY' ? { page, category: param } :
                { page })
        }
    });

    return res.data;
}

export const getMerchantsCategoryAPI = async (userID, lat, lon, city, host, name, addressName, type, page, category, param = null) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            category,
            // day,
            // time,
            ...(type === 'FILTER_NEAR_ME' ? { page, type: 0 } : 
                type === 'FILTER_DISTRICT' ? { page, district: param, type: 1 } : 
                type === 'SEARCH' ? { page, param, type: 1} : 
                { page })
        }
    });

    return res.data;
}

export const getMerchantDetailsAPI = async (merchantId) => {
    const res = await axios.get('https://idmarket-api.taximaxim.com/api/v1/merchants/' + merchantId);
    return res.data;
} // prod
// export const getMerchantDetailsAPI = async (merchantId) => {
//     const res = await axios.get('http://localhost:8001/api/v1/merchants/' + merchantId);
//     return res.data;
// } // dev