import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { exploreMerchantKeyword } from '../../../../application/actions/exploreActions';

import './SearchBar.css'
import {t} from '../../../../miscellaneous/language'

const ExploreSearch = () => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = React.useState('');

    const { isLoading } = useSelector((state) => ({ 
        isLoading: state.app.loading
    }));

    const onSearchChange = (e) => setSearchInput(e.target.value);

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            
            // dispatch(exploreMerchantKeyword(searchInput.trim().toLowerCase()));
        }
    }

    const onBlur = () => {
        if(searchInput.length !== 0) {
            dispatch(exploreMerchantKeyword(searchInput.trim().toLowerCase()));
        }
    }
    
    return (
        <div className="search-input-container">
            <input 
                type="text" 
                className="search-input explore-search-box" 
                onChange={onSearchChange}
                onKeyUp={onKeyUp}
                onBlur={onBlur}
                placeholder={t.placeholder.searchHome}
                readOnly={isLoading}
                value={searchInput}
            />
            {/* <BiSearch size={23} className="search-icon" onClick={onSearchChange} /> */}
        </div>
    )
}

export default ExploreSearch