import React, { useEffect, useState } from 'react'
import { Badge } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Location from '../../../../assets/icons/location(1).svg'

import './TopNav.css'

import {
    Cart
} from '../../../../assets/'

import { FaGlobe } from 'react-icons/fa'
import { t } from '../../../../miscellaneous/language';

const HomePageTopNav = ({ setLanguageModal, addressName }) => {
    const history = useHistory();
    const typeLanguage = localStorage.getItem('language');
    const [language, setLanguage] = useState();

    const { cart } = useSelector((state) => ({
        cart: state.cart
    }));

    const countSumOfQty = () => {
        let total = 0;
        for (var i in cart.items) {
            total += cart.items[i].qty;
        }
        return total;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const deepLinking = () => {
        console.log(`maximzakaz://app?action=openhome`);
        window.location = `maximzakaz://app?action=openhome`;
        // console.log(`maximzakaz://order?refOrgId=777&startLatitude=${store.cart.orders_response.merch_lat}&startLongitude=${store.cart.orders_response.merch_lon}&startAddressName=${store.cart.merchant.name}&endLatitude=${store.cart.orders_response.user_lat}&endLongitude=${store.cart.orders_response.user_lon}&endAddressName=${store.cart.orders.city}&description=${store.cart.orders_response.notes}&orderPrice=${store.cart.orders_response.total}&ifmaOrderID=${store.cart.orders_response.mztid}`);
        // window.location = `maximzakaz://order?refOrgId=777&startLatitude=${store.cart.orders_response.merch_lat}&startLongitude=${store.cart.orders_response.merch_lon}&startAddressName=${store.cart.merchant.name}&endLatitude=${store.cart.orders_response.user_lat}&endLongitude=${store.cart.orders_response.user_lon}&endAddressName=${store.cart.orders.city}&description=${store.cart.orders_response.notes}&orderPrice=${store.cart.orders_response.total}&ifmaOrderID=${store.cart.orders_response.mztid}`;
    }

    return (
        <>
            {/* old version */}
            {/* <div className="homepage-topnav">
                <span className="homepage-topnav-title">{t.topNav.title}</span>
                <div style={{ display:'flex' }}>
                    <div className='homepage-cart-container'>
                            <FaGlobe style={{ fontSize:25 }} onClick={(e) => setLanguageModal(true)} />
                    </div>                    
                </div>
            </div> */}

            {/* new version */}
            <div className="homepage-topnav">
                <div className='homepage-topnav-container'>
                    <div style={{ display:'grid', gridTemplateColumns:'20% 80%'}} onClick={deepLinking}>
                        <div className='home-page-topnav-container-icon'>
                            <img src={Location} />
                        </div>
                        <div className='home-page-topnav-container-title'>
                            <div className='home-page-topnav-container-title-deliver'>
                                <p>{t.text.deliverTo}</p>
                            </div>
                            <div className='homepage-topnav-container-title-address'>
                                <span>{addressName}</span>
                            </div>
                        </div>
                    </div>
                    <div className='homepage-topnav-container-button'>
                        <span onClick={(e) => setLanguageModal(true)}>
                            <p>{typeLanguage}</p>
                        </span>                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePageTopNav
