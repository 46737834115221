import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
// import Countdown from 'react-countdown';
import { AfterOrderButton, BackTitleTopNav } from '../components/common'

import { refreshOrderStatus } from '../../application/actions/cartActions';
import { AfterOrder } from '../'
import { t } from '../../miscellaneous/language'
const AfterOrderContainer = () => {
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        cart: state.cart,
        auth: state.auth
    }), shallowEqual);

    const [staticStatus, setStaticStatus] = React.useState(0);

    const updateStatus = (status) => {
        // if(staticStatus == 2) {
        //     setStaticStatus(0);
        // }
        // else {
        //     setStaticStatus(prevState => (
        //         prevState + 1
        //     ));
        // }
        setStaticStatus(status);
    }

    const refreshStatus = async () => {
        await dispatch(refreshOrderStatus(store.cart?.orders_response?.id));
        // setStaticStatus(store.cart?.orders_response?.status);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refreshStatus();
        }, 5000);

        return () => clearInterval(interval);
    }, [store, staticStatus])

    return (
        <>
           <Helmet>
                <title>{t.helmet.title.afterOrder}</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content={t.helmet.desc.afterOrder} />
            </Helmet>
            <BackTitleTopNav hideButton={true} title={store.cart.orders_response?.code} orderReg={store.cart.orders_response?.reg} />
            <AfterOrder order_data={store.cart?.orders_response?.status} updateStaticStatus={updateStatus}/>
            {/* {store.cart?.orders_response?.status == 0 ? 
            <div style={{position: 'absolute', display: 'flex', width: '100%', bottom: 120, justifyContent: 'center', alignItems: 'center'}}>
                <Countdown date={Date.parse(store.cart.orders_response?.created_at ? store.cart.orders_response?.created_at : '1970-01-01T00:00:00.000000Z') - (420 * 60000) + 120000} />
            </div>
            :
            <></>} */}
            {/* <BottomSpace class="gray-bottom-space" /> */}
            <AfterOrderButton status={store.cart?.orders_response?.status} refreshStatus={refreshStatus}/>
        </>
    )
}

export default AfterOrderContainer
