import React from 'react'

import ItemsAlreadyPickupStatus from './ItemsAlreadyPickupStatus'
import MerchantConfirmStatus from './MerchantConfirmStatus'
import OrderPlacedStatus from './OrderPlacedStatus'
import MerchantConfirmStatusCancel from './MerchantConfirmStatusCancel'
import UserConfirmStatusCancel from './UserConfirmStatusCancel'
import CompleteStatus from './CompleteStatus'

import './AfterOrder.css'
import OrderFoundDriver from './OrderFoundDriver'
import DriverMerchantPlace from './DriverMerchantPlace'


const AfterOrder = ({ order_data, updateStaticStatus }) => {

    return (
        <div className="after-order-container">
            {/* {
                order_data.status == 0 ? <OrderPlacedStatus /> : order_data.status == 1 ? <MerchantConfirmStatus /> : order_data.status == 2 ? <ItemsAlreadyPickupStatus /> : ''
            } */}

            {
                order_data === 0 ?
                    <>
                        <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                    </>
                    :
                    order_data === 1 ?
                        <>
                            <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                            <MerchantConfirmStatus updateStaticStatus={updateStaticStatus} />
                        </>
                        :
                        order_data === 2 ?
                            <>
                                <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                                <MerchantConfirmStatus updateStaticStatus={updateStaticStatus} />
                                <OrderFoundDriver updateStaticStatus={updateStaticStatus} />
                                <DriverMerchantPlace updateStaticStatus={updateStaticStatus} />
                                <ItemsAlreadyPickupStatus updateStaticStatus={updateStaticStatus} />
                            </>
                            :
                            order_data === 3 ?
                                <>
                                    <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                                    <MerchantConfirmStatus updateStaticStatus={updateStaticStatus} />
                                    <OrderFoundDriver updateStaticStatus={updateStaticStatus} />
                                </>
                                :
                                order_data === 4 ?
                                    <>
                                        <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                                        <MerchantConfirmStatus updateStaticStatus={updateStaticStatus} />
                                        <OrderFoundDriver updateStaticStatus={updateStaticStatus} />
                                        <DriverMerchantPlace updateStaticStatus={updateStaticStatus} />
                                    </>
                                    :
                                    order_data === 11 ?
                                        <>
                                            <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                                            <MerchantConfirmStatus updateStaticStatus={updateStaticStatus} />
                                            <OrderFoundDriver updateStaticStatus={updateStaticStatus} />
                                            <DriverMerchantPlace updateStaticStatus={updateStaticStatus} />
                                            <ItemsAlreadyPickupStatus updateStaticStatus={updateStaticStatus} />
                                            <CompleteStatus updateStaticStatus={updateStaticStatus} />
                                        </>
                                        :
                                        order_data === 99 ?
                                            <>
                                                <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                                                <UserConfirmStatusCancel updateStaticStatus={updateStaticStatus} />
                                            </>
                                            :
                                            order_data === 9 ?
                                                <>
                                                    <OrderPlacedStatus updateStaticStatus={updateStaticStatus} />
                                                    <MerchantConfirmStatusCancel updateStaticStatus={updateStaticStatus} />
                                                </>
                                                : ''
            }
        </div>
    )
}

export default AfterOrder
