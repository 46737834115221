import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const useDeliveryFeeCalculator = (startLatitude, startLongitude, startAddressName, endLatitude, endLongitude, endAddressName, host) => {
    const [deliveryFee, setDeliveryFee] = React.useState(null);
    const regex = /[!@#$%^&-=_{}":><;'.,]/g
    const replaceString = (string) => {
        return string.replace(regex, '')
    }
    const fetcher = async (url) => await axios.get(url, {
        params: {
            StartLatitude: startLatitude, 
            StartLongitude: startLongitude, 
            StartAddressName: replaceString(startAddressName),
            EndLatitude: endLatitude, 
            EndLongitude: endLongitude,
            EndAddressName: replaceString(endAddressName), 
            host
        }
    });

    const {
        data,
        error,
        mutate
    } = useSWR('https://idmarket-api.taximaxim.com/api/v1/delprice', fetcher); // prod
    // const {
    //     data,
    //     error,
    //     mutate
    // } = useSWR('http://localhost:8001/api/v1/delprice', fetcher); //dev

    React.useEffect(() => {
        if (data) {
            setDeliveryFee(data);
        }
        if (error) {
            setDeliveryFee(null);
        }
        // eslint-disable-next-line
    },[data, error]);

    return {
        deliveryFee,
        mutate
    }
}

export default useDeliveryFeeCalculator;
