import { useDispatch, useSelector } from 'react-redux';

import { addToCart as addToCartAction, removeFromCart } from '../actions/cartActions';

import { actionMessage } from '../../miscellaneous/helpers/utils';

const useCart = () => {
    const dispatch = useDispatch();
    const { cart } = useSelector((state) => ({ cart: state.cart }));    

    const checkItemOnCart = (id) => !!cart.items?.find((item) => item.uuid_cart === id);
    const checkItemOnCartNoAddon = (id) => cart.items?.find((item) => item.id === id);
    const itemQtyCounter = (id) => cart.items?.find((item) => item.uuid_cart === id)?.qty;
    const getItemNote = (id) => cart.items?.find((item) => item.uuid_cart === id)?.orderDesc;

    const addToCart = (merchantProduct, merchant) => {
        // if (checkItemOnCart(merchantProduct.id)) {
        //     dispatch(removeFromCart(merchantProduct.id));
        //     actionMessage('Item removed from your cart');
        // } else {
            dispatch(addToCartAction(merchantProduct, merchant));
            actionMessage('Added item to your cart', 'success');
        // }
    }

    return { cart, checkItemOnCart, itemQtyCounter, addToCart, getItemNote, checkItemOnCartNoAddon }
}

export default useCart;