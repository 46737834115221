import React, { useEffect } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { BiReceipt } from 'react-icons/bi';

import {
    AngleSmallLeft,
    Home,
} from '../../../../assets'

import '../../../../assets/icons/icons.css'

const BackTitleTopNav = (props) => {

    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="white-space"></div>
            <div className="topnav-container">
                {props?.hideButton ? (<>
                    <div className="topnav-center-side">
                        <span className="topnav-title-text margin-left-10">{props.title}</span>
                    </div>
                    <Link to={`/receipt/${props.orderReg ? props.orderReg : ''}`} className="topnav-right-side">
                        <BiReceipt size={25} title="Ringkasan Tagihan" />
                    </Link>
                    </>)
                    :
                    <>
                        <div className="topnav-left-side" onClick={() => {
                            if(props.isClicked) {
                                props.handleDetailClick();
                            }
                            else {
                                history.goBack(null)
                            }
                        }}>
                            <AngleSmallLeft id="back-small-icon" />
                        </div>
                        <div className="topnav-center-side">
                            <span className={"topnav-title-text"}>{props.title}</span>
                        </div>
                        <Link to="/home" className="topnav-right-side">
                            <Home id="home-small-icon" />
                        </Link>
                    </>
                }
            </div>
        </>
    )
}

export default BackTitleTopNav
