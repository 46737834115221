import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
    Home,
    AngleSmallLeft
} from '../../../../assets'

import '../../../../assets/icons/icons.css'
import { useDispatch, useSelector } from 'react-redux';
import { clearMerchantsDetails, searchMerchantItem } from '../../../../application/actions/merchantActions';
import { BiSearch } from 'react-icons/bi';
import { t } from '../../../../miscellaneous/language';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const MerchantDetailTopNav = (props) => {
    const [searchBar, setSearchBar] = useState(false)
    const [searchInput, setSearchInput] = React.useState('');
    const { isLoading } = useSelector((state) => ({ 
        isLoading: state.app.loading
    }));
    const dispatch = useDispatch()
    const history = useHistory();
    const location = useLocation();

    const [scrolled, setIsScrolled] = useState(false);

    const handleScroll=() => {
        const offset=window.scrollY;
        // console.log(offset, 'offset')
        if(offset > 30 ){
          setIsScrolled(true);
        }
        else{
          setIsScrolled(false);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',handleScroll);
    });
    
    let x=['topnav-container'];
    if(scrolled) {
        x.push('background-white topnav-scrolled');
    }

    const onSearchChange = (e) => {
        setSearchInput(e.target.value);
    }

    const onKeyUp = (e) => {
        if (e.keyCode === 13) {
            e.target.blur();
            
            console.log(searchInput);

            dispatch(searchMerchantItem(searchInput.trim().toLowerCase()));
        }
    }
    const useGoBack = (from, to) => {      
        // return () => {
        //   Get the previous route from the history stack
          const previousRoute = history.entries[history.index - 1];
      
          // Check if the previous route matches the from parameter
          if (previousRoute && previousRoute.pathname === from) {
            // Go back to the to parameter
            history.push(to);
          } else {
            // Go back to the previous route
            history.goBack();
          }
        // };
      };

    return (
        <>
            <div className="white-space"></div>
            <div className={searchBar ? "topnav-container background-white" : x.join(' ')}>
                <div className="topnav-left-side" onClick={() => {
                    if(props.isClicked) {
                        props.handleDetailClick();
                    }
                    else {
                        // Check if the previous route matches promo
                        if (location?.state?.from?.split('/').find((e) => e === 'promo')) {
                            // Go back
                            history.goBack();
                        } else {
                            // Go back to home
                            history.push('/home');
                        }
                        // dispatch(clearMerchantsDetails())
                    }
                }}>
                    <AngleSmallLeft id="back-small-icon" />
                </div>
                {/* <div className={x.join(" ")}> */}
                <div className='topnav-center-side'>  
                    {searchBar && 
                    <input 
                        type="text"
                        autoFocus={searchBar}
                        className='topnav-input' 
                        id="topnav-input" 
                        onChange={onSearchChange}
                        onKeyUp={onKeyUp}
                        placeholder={t.placeholder.searchItem}
                        value={searchInput}
                        readOnly={isLoading}
                     />
                    }              
                </div>
                <div className='topnav-right-side'>
                    <BiSearch size={23} onClick={() => {
                        setSearchInput('')
                        if(searchBar){
                            dispatch(searchMerchantItem(''));
                        }
                        setSearchBar(!searchBar)
                        }}/>
                </div>
                {/* <Link to="/home" className="topnav-right-side">
                </Link> */}
            </div>
        </>
    )
}

export default MerchantDetailTopNav
