import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const useCategory = (userId, lat, lon, city, host, name, addressName, page = 1) => {
    const [categories, setCategories] = React.useState(null);

    const fetcher = (url) => !categories && axios.get(url, {
        params: {
            userID: userId,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page
        }
    });


    const {
        data,
        error,
        mutate
    } = useSWR('https://idmarket-api.taximaxim.com/api/v1/home/categories', fetcher, {revalidateOnFocus:false}); // prod
    // const {
    //     data,
    //     error,
    //     mutate
    // } = useSWR('http://localhost:8001/api/v1/home/categories', fetcher, {revalidateOnFocus:false}); // dev

    React.useEffect(() => {
        if (data) {
            setCategories(data.data);
        }
        if (error) {
            setCategories(null);
        }
        // eslint-disable-next-line
    },[data, error]);

    return {
        categories,
        mutate
    }
}
// const useCategory = async(userId, lat, lon, city, host, name, addressName, page = 1) => {
//     // const [categories, setCategories] = React.useState(null);
//     let categories

//     try{
//         const res = await axios.get('https://idmarket-api.taximaxim.com/api/v1/home/categories', {
//             params: {
//                 userID: userId,
//                 lat,
//                 lon,
//                 city,
//                 host,
//                 name, 
//                 addressName, 
//                 page
//             }
//         })
       
//         categories = res.data
//         let result = {categories}
//         console.log(categories)
//         return {
//             result
//         }

//     }
//     catch(err){
//         console.log(err)
//     }

   
// }

export default useCategory;
