import React from 'react'

const Cart = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} viewBox="0 0 128 136">
            <g transform="translate(-829 -178.963)">
                <g transform="translate(829 178.963)">
                    <path d="M112,34H96C96,15.222,81.673,0,64,0S32,15.222,32,34H16C7.163,34,0,41.611,0,51v56.667C.018,123.307,11.946,135.981,26.667,136h74.667c14.72-.019,26.649-12.693,26.667-28.333V51C128,41.611,120.837,34,112,34ZM64,11.333c11.782,0,21.333,10.148,21.333,22.667H42.667c0-12.518,9.551-22.667,21.333-22.667Zm53.333,96.333c0,9.389-7.163,17-16,17H26.667c-8.837,0-16-7.611-16-17V51A5.508,5.508,0,0,1,16,45.333H32V56.667a5.343,5.343,0,1,0,10.667,0V45.333H85.333V56.667a5.343,5.343,0,1,0,10.667,0V45.333h16A5.508,5.508,0,0,1,117.333,51Z" transform="translate(0)"/>
                    <path d="M-3-32s.059,11.192,0,22.548C-3.833,10.717-21.983,13.1-25.411,13-33.636,13.2-47.9,6.473-48-9.375-46.694-32.3-25.5-32-25.5-32s.1,5.424,0,11.25c-8.438-.1-11.15,7.433-11.25,11.25A11.3,11.3,0,0,0-25.5,1.751,11.427,11.427,0,0,0-14.249-9.5,11.561,11.561,0,0,0-25.5-20.75c-.1-5.826,0-11.25,0-11.25Z" transform="translate(54.006 112.009) rotate(90)"/>
                </g>
            </g>
        </svg>
    )
}

export default Cart
