import React from 'react'

import {
    // Star,
    // District,
    PriceLabel,
    MerchantImage
} from '../../assets/'

import Star from '../../assets/icons/rating.svg'
import District from '../../assets/icons/location.svg'
import RightArrow from '../../assets/icons/Angle-small-right.svg'

import './Merchant.css'

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';
import { t } from '../../miscellaneous/language';
import ReactGA from "react-ga4";


const MerchantCart = ({ merchant_details }) => {
    const history = useHistory();
    const clickMerchant = (id, partner_level) => {
        history.push(`/merchant/${id}`)
        console.log(partner_level);
        ReactGA.event(`Click ${partner_level === 1 ? 'partner' : 'non-partner'}`, {
            category: "Click Merchant",
            action: "Click Merchant",
            label: "Click Merchant", // optional
            value: 1, // optional, must be a number
        });
    }

    return (
        <div className="merchant-cart-wrapper" onClick={(e) => { merchant_details.is_closed === 1 ? alert(t.alert.merchantClose) : clickMerchant(merchant_details.id, merchant_details.partner_level) }}>
            <div className='merchant-cart-content'>
                <div className="merchant-cart-content-left merchant-cart-content-flex-1">
                    <span className="merchant-cart-name">{merchant_details.name}</span>
                    <div className="merchant-cart-content">
                        <div className="merchant-cart-content-left-description merchant-cart-content-flex-1">
                            <img src={Star} width={15} height={15} />                            
                            <span>{merchant_details.avg_rating}</span>
                        </div>
                        <div className="merchant-cart-content-left-description merchant-cart-content-flex-7">
                            <img src={District} width={15} height={15} />                            
                            <span>{merchant_details.address}</span>
                        </div>
                    </div>
                </div>
                <div className='merchant-cart-content-right'>
                    <img src={RightArrow} width={30} height={30} />
                </div>
            </div>
        </div>
    )
}

export default MerchantCart