const En = {
    btn: {
        saveName: 'บันทึกชื่อ',
        yes: 'ใช่',
        no: 'ไม่',
        add: 'เพิ่ม',
        viewCart: 'ดูตะกร้าของฉัน',
        alertOk: 'ฉันเข้าใจ',
        confirmOrder: 'ยืนยันออเดอร์ของคุณ',
        shopping: `เริ่มช้อปปิ้งกันเถอะ!`,
        status: {
            orderStatus0: 'รีเฟรชสถานะออเดอร์',
            orderStatus1: 'ตรวจสอบสถานะการส่ง',
            orderStatus9: 'สั่งซื้อจากร้านอื่น'
        }
    },
    text: {
        info: 'หมายเหตุ',
        nonPartnerInfo: 'ร้านค้านี้ไม่ได้เป็นพาร์ทเนอร์',
        saveName: 'สวัสดี กรุณาใส่ชื่อของคุณเพื่อดำเนินการต่อ',
        itemitem: 'รายการ',
        item: 'รายการ',
        items: 'รายการ',
        empty: 'ขายหมดแล้ว',
        orderList: 'รายการออเดอร์',
        billDetail: 'รายละเอียดใบแจ้งหนี้',
        totalPrice: 'ราคารวม',
        totalDisc: 'จำนวนส่วนลดทั้งหมด',
        platformFee: 'ค่าธรรมเนียม',
        parkingFee: 'ค่าจอดรถ',
        deliveryFee: 'ค่าธรรมเนียมการจัดส่งและการจัดการ',
        takeAwayCharge: "ค่าบริการสั่งกลับบ้าน",
        unavailable: "ไม่พร้อมใช้งาน",
        toBePaid: 'ยอดสุทธิ',
        emptyCart: 'ยังไม่มีรายการในตะกร้าสินค้าของคุณ :(',
        orderPlaced: {
            title: 'สร้างออเดอร์สำเร็จ',
            desc: 'รอร้านค้ายืนยันออเดอร์ของคุณ'
        },
        driverFound: {
            title: 'พบคนขับแล้ว',
            desc: 'ขอบคุณที่ใช้บริการ Maxim'
        },
        driverResto: {
            title: 'คนขับอยู่ที่ร้านค้า',
            desc: 'คนขับถึงร้านค้าแล้ว'
        },
        merchantCancel: {
            title: 'ออเดอร์ของคุณถูกยกเลิก',
            desc: 'ร้านค้ายกเลิกออเดอร์ของคุณ'
        },
        merchantconfirm: {
            title: 'ออเดอร์ของคุณถูกยืนยันแล้ว',
            desc: 'ร้านค้ายืนยันออเดอร์ของคุณแล้ว'
        },
        itemPickedUp: {
            title: 'กำลังจัดส่งออเดอร์ของคุณ',
            desc: 'กำลังดำเนินการออเดอร์ของคุณ'
        },
        completeStatus: {
            title: 'ออเดอร์เสร็จสิ้นแล้ว',
            desc: 'ขอบคุณที่ใช้บริการ Maxim'
        },
        userCancel: {
            title: 'ออเดอร์ของคุณถูกยกเลิก',
            desc: 'ผู้ใช้ยกเลิกออเดอร์ของคุณ'
        },
        buyerName: 'ชื่อคนขาย',
        receiveAt: 'รับที่',
        status: 'สถานะ',
        paymentType: 'วิธีการชำระเงิน',
        cash: 'เงินสด',
        note: 'หมายเหตุ: ',
        noNote: 'ไม่มีหมายเหตุ',
        noteMerchant: 'หมายเหตุถึงร้านค้า',
        MerchantDetailDiscountContainer: { recommend: 'เมนูยอดฮิต' },
        AddToCart: 'เพิ่มในตะกร้า',
        deliverTo: 'ส่งที่',
        merchantName: 'ชื่อร้านค้า',
        deliveryAddress: 'ที่อยู่จัดส่ง',
        phoneNumber: 'เบอร์โทรศัพท์',
        merchantAddress: 'ที่อยู่ร้านค้า',
        additional: 'เพิ่มเติม',
    },
    label: { user_name: 'ชื่อของคุณ: ' },
    placeholder: {
        name: 'กรุณาใส่ชื่อ',
        searchHome: 'ค้นหาร้านค้าหรือสินค้า ...',
        searchCategory: 'ค้นหาร้านค้า',
        noteItemDetail: 'เขียนรายละเอียดที่นี่',
        searchItem: 'ค้นหาสินค้า ...',
        noteMerchant: 'ระบุรายละเอียดถึงร้านค้า'
    },
    alert: {
        orderCancel: 'ออเดอร์ถูกยกเลิก',
        nonPartner: 'ไม่ใช่พาร์ทเนอร์',
        partnerPlus: 'ราคาที่แสดงในแอปฯเป็นเพียงราคาประมาณการ และอาจมีการเปลี่ยนแปลงเล็กน้อย กรุณาตรวจสอบราคาอีกครั้งกับคนขับ และขอใบเสร็จเพื่อเป็นหลักฐานอ้างอิงในการชำระเงิน',
        noteNonPartner: 'ร้านค้านี้ไม่ได้เป็นพาร์ทเนอร์กับ Maxim อย่างเป็นทางการ จำนวนสินค้าและราคาอาจเปลี่ยนแปลงและแตกต่างจากสภาพความเป็นจริง ดำเนินการต่อหรือไม่?',
        cart: 'ตะกร้า',
        cartEmpty: 'ในตะกร้ามีสินค้า/เมนูเดียว การลดจำนวนสินค้าจะถูกลบออกจากตะกร้า ดำเนินการต่อหรือไม่?',
        alert: 'แจ้งเตือน',
        noteProcessOrder: 'โปรดรอสักครู่... เซิร์ฟเวอร์กำลังประมวลผลคำขอของคุณ หากใช้เวลานานเกินไป โปรดรีสตาร์ทแอปหรือตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ',
        confirmOrder: 'ยืนยันออเดอร์',
        noteConfirmOrder: 'คุณต้องการสร้างออเดอร์หรือไม่? กรุณาตรวจสอบออเดอร์อีกครั้งก่อนยืนยัน',
        noteCountDeliveryPrice: 'เกิดข้อผิดพลาดขณะคำนวณราคาจัดส่ง โปรดรีสตาร์ทแอปเพื่อดำเนินการต่อ',
        noteDeliveryPrice: 'เกิดข้อผิดพลาดขณะเรียกข้อมูลตำแหน่งของคุณเพื่อคำนวณราคาจัดส่ง โปรดรีสตาร์ทแอปและตรวจดูให้แน่ใจว่า GPS เปิดอยู่เพื่อดำเนินการต่อ',
        notePlatformPrice: 'เกิดข้อผิดพลาดขณะเรียกค่าธรรมเนียมแพลตฟอร์ม โปรดรีสตาร์ทแอปเพื่อดำเนินการต่อ',
        noteNonPartnerReceipt: 'ร้านค้านี้ไม่ได้เป็นพาร์ทเนอร์อย่างเป็นทางการของ Maxim สินค้าที่มีและราคาที่แสดงอาจแตกต่างกันไป กรุณาชำระเงินตามราคาที่ระบุไว้ในใบเสร็จ',
        merchantClose: 'ร้านปิดแล้ว!'
    },
    helmet: {
        title: {
            start: 'อาหารและสินค้า - เริ่มต้น',
            Home: 'อาหารและสินค้า',
            Category: 'อาหารและสินค้า - หมวดหมู่ร้านค้า',
            History: 'อาหารและสินค้า - ประวัติ',
            DetailCart: 'อาหารและสินค้า - รายละเอียดตะกร้า',
            afterOrder: 'อาหารและสินค้า - หลังจากสั่งซื้อ',
            HistoryDetail: 'อาหารและสินค้า - รายละเอียดประวัติ'
        },
        desc: {
            start: 'เริ่ม',
            Home: 'อาหารและสินค้า',
            Category: 'รายชื่อร้านค้าตามหมวดหมู่',
            History: 'รายการธุรกรรมก่อนหน้าของคุณ',
            DetailCart: 'รายละเอียดของสินค้าในตะกร้าของคุณ',
            afterOrder: 'ออเดอร์ของฉัน',
            HistoryDetail: 'รายละเอียดประวัติ'
        }
    },
    topNav: { title: 'อาหารและสินค้า' },
    backTopNav: { History: 'ประวัติ' },
    loading: 'กำลังโหลด…',
    data: {
        noDataMerchant: 'ไม่พบร้านค้า',
        DataMerchant: 'พบร้านค้า',
        notFound: 'ไม่พบ'
    },
    bottomNav: {
        Home: 'หน้าแรก',
        Explore: 'ค้นหา',
        Promo: 'โปรโมชั่น',
        History: 'ประวัติ',
        Cart: 'ตะกร้า'
    },
    status: {
        Open: 'เปิด',
        Close: 'ปิดแล้ว',
        case0: 'รอร้านค้าอนุมัติ',
        case1: 'ร้านค้าอนุมัติออเดอร์แล้ว',
        case2: 'คนขับ Maxim จัดส่งออเดอร์แล้ว',
        case3: 'คนขับ Maxim ได้รับออเดอร์แล้ว',
        case4: 'คนขับกำลังรอ',
        case11: 'ออเดอร์เสร็จสมบูรณ์',
        case9: 'ออเดอร์ถูกยกเลิก',
        caseNotFound: 'ไม่พบสถานะออเดอร์',
        orderStatus0: 'กำลังรอ',
        orderStatus1: 'ร้านค้าอนุมัติแล้ว',
        orderStatus2: 'คนขับรับแล้ว',
        orderStatus3: 'คนขับได้รับงานแล้ว',
        orderStatus4: 'คนขับกำลังรอ',
        orderStatus9: 'ร้านค้ายกเลิก',
        orderStatus11: 'เสร็จสิ้น',
        orderStatus99: 'ผู้ใช้ยกเลิก',
        orderStatusNotFound: 'ไม่พบสถานะ',
        itemNotFound: 'ไม่พบสินค้าในหมวดนี้'
    },
    receiveAt: { statusAccepted: 'ยังไม่ได้รับ' },
    bottomConfirm: {
        payment: {
            title: 'วิธีการชำระโดยไม่ใช้เงินสด',
            title2: 'วิธีการชำระเงินแบบอื่น',
            cash: 'เงินสด',
            noncash: 'บัตรเครดิต/เดบิต',
            paywith: 'ชำระด้วย',
            soon: 'เร็วๆนี้'
        },
        btn: { confirm: 'ยืนยันคำสั่งซื้อ' }
    },
    itemDetails: { note: 'หมายเหตุถึงร้านค้า' },
    addon: {
        optional: 'ไม่จำเป็น',
        qty: 'จำนวน',
        additm: 'เพิ่มรายการ',
        edititm: 'อัปเดทรายการ',
        max: 'เลือกสูงสุด',
        edit: 'แก้ไข',
        require: 'จำเป็น',
        selectrequire: 'เลือกอย่างต่ำ',
        price: 'ราคา',
        addcustome: 'เพิ่มลูกค้าอีกราย',
        free: 'ฟรี'
    },
    promo: { listMerchants: 'รายการร้านค้า' }
};
export default En;