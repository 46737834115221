import React from 'react'

const Cancel = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="39.6" height="39.6" viewBox="0 0 39.6 39.6">
            <g id="Group_152" data-name="Group 152" transform="translate(-300 -174)">
                <g id="Ellipse_65" data-name="Ellipse 65" class="cls-1" transform="translate(300 174)" fill="#fff" stroke="#ff6000" stroke-width="3px">
                <circle class="cls-3" cx="19.8" cy="19.8" r="19.8" stroke="none" />
                <circle class="cls-4" cx="19.8" cy="19.8" r="18.3" fill="none" />
                </g>
                <path id="Path_84" data-name="Path 84" class="cls-2" d="M1533.253,2021.434l6.75-7.65,5.974-6.771" transform="translate(-1220 -1820)" stroke="#ff6000" fill="none" stroke-linecap="round" stroke-width="4px" />
                <path id="Path_86" data-name="Path 86" class="cls-2" d="M1533.253,2021.434l6.75-7.65,5.974-6.771" transform="translate(2506.456 -1088.443) rotate(97)" stroke="#ff6000" fill="none" stroke-linecap="round" stroke-width="4px" />
            </g>
        </svg>
    )
}

export default Cancel
