import React from 'react'

const District = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id={props.id} viewBox="0 0 113.947 136">
            <g transform="translate(-1.945 0.002)">
                <path d="M29.331,6A21.331,21.331,0,1,0,50.663,27.331,21.331,21.331,0,0,0,29.331,6Zm0,32A10.666,10.666,0,1,1,40,27.331,10.666,10.666,0,0,1,29.331,38Z" transform="translate(29.587 29.007)"/>
                <path d="M58.918,136a29.866,29.866,0,0,1-24.427-12.466C12.9,93.745,1.945,71.352,1.945,56.971a56.973,56.973,0,0,1,113.947,0c0,14.381-10.953,36.773-32.547,66.561A29.866,29.866,0,0,1,58.918,136Zm0-123.63A44.666,44.666,0,0,0,14.3,56.983c0,11.389,10.726,32.45,30.2,59.3a17.82,17.82,0,0,0,28.841,0c19.469-26.852,30.2-47.913,30.2-59.3A44.666,44.666,0,0,0,58.918,12.367Z" transform="translate(0 0)"/>
            </g>
        </svg>
    )
}

export default District
