import axios from 'axios';

const baseURL = 'https://idmarket-api.taximaxim.com/api/v1/search'; // prod
// const baseURL = 'http://localhost:8001/api/v1/search';
const today = new Date()
const day = today.getDay()
const time = `${today.getHours() < 10 ? 0 : ''}${today.getHours()}:00:00`

export const searchMerchantAPI = async (userID, lat, lon, city, host, name, addressName, param, page) => {
    const res = await axios.get(baseURL, {
        params: {
            userID,
            lat,
            lon,
            city,
            host,
            name, 
            addressName, 
            page,
            param,
            // day,
            // time,
        }
    });

    return res.data;
}