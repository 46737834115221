import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setRequestStatus } from '../../application/actions/miscActions';
import { exploreMerchant } from '../../application/actions/exploreActions';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { 
    useDidMount
} from '../../application/hooks';

import './Explore.css';
import MerchantResult from './MerchantResult';
import ItemResult from './ItemResult';

const Explore = ({detailFunction}) => {
    const didMount = useDidMount();
    const dispatch = useDispatch();

    const store = useSelector((state) => ({
        explore: state.explore,
        requestStatus: state.app.requestStatus,
        keyword: state.explore.keyword,
        isLoading: state.app.loading,
    }));

    const [isOpen, setIsOpen] = useState({
        merchants: true,
        items: true
    })
    
    const toggle = (components) => {
        switch(components) {
            case 'merchants': {
                setIsOpen(prevState => ({
                    ...prevState,
                    merchants: !prevState.merchants
                }));
                break;
            }
            case 'items': {
                setIsOpen(prevState => ({
                    ...prevState,
                    items: !prevState.items
                }));
                break;
            }
            default: { break; }
        }
    }

    React.useEffect(() => {
        if (didMount && store.keyword) {
            dispatch(exploreMerchant(store.keyword));
        }
    }, [didMount, store.keyword, dispatch]);
    
    React.useEffect(() => () => {
        dispatch(setRequestStatus(''));
    // eslint-disable-next-line 
    }, []);

    if (!store.isLoading && !store.requestStatus) {
        return (
            <div 
                className="explore-merchant-list-container" 
                // onScroll={onHandleScroll}
            >
                {/* <div className="merchant-list-count">
                    <span>Found {store.explore.total} {store.explore.total > 1 ? 'merchants' : 'merchant'}</span>
                </div> */}
                {/* <ExploreMerchantList items={store.explore.items} /> */}
                

                <Accordion
                    expanded={isOpen.merchants}
                    onChange={() => toggle('merchants')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <span className="explore-result-type">Merchants</span>
                    </AccordionSummary>
                    {!store.explore.merchants?.data ? <span>Loading ...</span> : store.explore.merchants.data?.map((menuItem) => (
                        <AccordionDetails>
                            <MerchantResult item={menuItem}/>
                        </AccordionDetails>
                    ))}
                </Accordion>

                <Accordion
                    expanded={isOpen.items}
                    onChange={() => toggle('items')}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <span className="explore-result-type">Items</span>
                    </AccordionSummary>
                    {!store.explore.items?.data ? <span>Loading ...</span> : store.explore.items.data?.map((menuItem) => (
                        <AccordionDetails>
                            <ItemResult item={menuItem} detailFunction={detailFunction}/>
                        </AccordionDetails>
                    ))}
                </Accordion>
            </div>
        )
    }
    return <span><center>Loading ...</center></span>;
}

export default Explore;
