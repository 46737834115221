import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const useParkingFeeGenerator = (id, name, lat, lon, city, host) => {
    // console.log(id, name, lat, lon, city, host)
    const [parkingFee, setParkingFee] = React.useState(null);

    const fetcher = async (url) => await axios.get(url, {
        params: {
            userID: id, 
            name: name, 
            lat: lat,
            lon: lon, 
            city: city, 
            host: host
        }
    });

    const {
        data,
        error,
        mutate
    } = useSWR('https://idmarket-api.taximaxim.com/api/v1/home/parkingfee', fetcher); // prod
    // const {
    //     data,
    //     error,
    //     mutate
    // } = useSWR('http://localhost:8001/api/v1/home/platformfee', fetcher); // dev

    React.useEffect(() => {
        // console.log(data)
        if (data) {
            setParkingFee(data.data.result);
        }
        if (error) {
            setParkingFee(null);
        }
        // eslint-disable-next-line
    },[data, error]);

    return {
        parkingFee,
        mutate
    }
}

export default useParkingFeeGenerator;