import React from 'react'

const Star = (props) => {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 140.477 136" fill={props.fill}>
            <path d="M139.5,50.559a18.61,18.61,0,0,0-17.955-13.031H95.968L88.2,13.315a18.891,18.891,0,0,0-35.98,0L44.451,37.528H18.874A18.891,18.891,0,0,0,7.751,71.669L28.569,86.89,20.654,111.4a18.9,18.9,0,0,0,29.177,21.04l20.378-15,20.384,14.981A18.891,18.891,0,0,0,119.765,111.4L111.85,86.89l20.841-15.221a18.6,18.6,0,0,0,6.808-21.11Z" transform="translate(-0.008 -0.19)"/>
        </svg>
    )
}

export default Star