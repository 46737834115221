const En = {
    btn: {
        saveName: 'Salvar nome',
        yes: 'Sim',
        no: 'Não',
        add: 'Adicionar',
        viewCart: 'Ver meu carrinho',
        alertOk: 'Entendido',
        confirmOrder: 'Confirmar o pedido',
        shopping: `Vamos às compras!`,
        status: {
            orderStatus0: 'Atualizar o status do pedido',
            orderStatus1: 'Verificar o status da entrega',
            orderStatus9: 'Pedido de outros vendedores'
        }
    },
    text: {
        info: 'Nota',
        nonPartnerInfo: 'Este vendedor não é um parceiro',
        saveName: 'Olá! Antes de continuar, por favor, insira seu nome...',
        itemitem: 'Itens',
        item: 'ítem',
        items: 'itens',
        empty: 'Vendido',
        orderList: 'Lista de pedidos',
        billDetail: 'Detalhes da fatura',
        totalPrice: 'Valor total',
        totalDisc: 'Desconto total',
        platformFee: 'Taxa da plataforma',
        parkingFee: 'Taxa de estacionamento',
        deliveryFee: 'Taxa de entrega e manuseio',
        takeAwayCharge: "cobrança de comida para viagem",
        unavailable: "Indisponível",
        toBePaid: 'A pagar',
        emptyCart: 'Seu carrinho de compras ainda está vazio :(',
        orderPlaced: {
            title: 'Pedido criado com sucesso',
            desc: 'Aguardando que o comerciante confirme o seu pedido'
        },
        driverFound: {
            title: 'Motorista encontrado',
            desc: 'Obrigado por usar o serviço Maxim'
        },
        driverResto: {
            title: 'motorista no local do vendedor',
            desc: 'O motorista chegou ao local do vendedor'
        },
        merchantCancel: {
            title: 'Sua ordem foi cancelada',
            desc: 'O vendedor cancelou o seu pedido'
        },
        merchantconfirm: {
            title: 'Seu pedido foi confirmado',
            desc: 'O vendedor confirmou o seu pedido'
        },
        itemPickedUp: {
            title: 'seu pedido está sendo enviado',
            desc: 'Seu pedido está em andamento'
        },
        completeStatus: {
            title: 'Seu pedido foi concluído',
            desc: 'Obrigado por usar o serviço Maxim'
        },
        userCancel: {
            title: 'Seu pedido foi cancelado',
            desc: 'O usuário cancelou seu pedido'
        },
        buyerName: 'Nome do comprador',
        receiveAt: 'Receber em',
        status: 'Status',
        paymentType: 'Tipo de pagamento',
        cash: 'Em dinheiro',
        note: 'Observação: ',
        noNote: 'Sem observação',
        noteMerchant: 'Observação para o vendedor',
        MerchantDetailDiscountContainer: { recommend: 'Recomendado para você' },
        AddToCart: 'Adicionar ao carrinho',
        deliverTo: 'Entregar para',
        merchantName: 'Nome do comerciante',
        deliveryAddress: 'Endereço de Entrega',
        phoneNumber: 'Número de telefone',
        merchantAddress: 'Endereço do comerciante',
        additional: 'Adicional',
    },
    label: { user_name: 'Seu nome: ' },
    placeholder: {
        name: 'Por favor, insira seu nome',
        searchHome: 'Buscar por vendedor ou item ...',
        searchCategory: 'Buscar por vendedor',
        noteItemDetail: 'Escreva qualquer detalhe específico aqui',
        searchItem: 'Buscar por item ...',
        noteMerchant: 'Escreva seu comentário ou sugestão para o vendedor'
    },
    alert: {
        orderCancel: 'Este pedido foi cancelado',
        nonPartner: 'Não é um parceiro',
        partnerPlus: 'O preço do pedido é uma estimativa e pode mudar. Por favor, peça ao motorista para fornecer o recibo do pagamento do produto para referência.',
        noteNonPartner: 'Este comerciante não é um parceiro oficial da Maxim. A disponibilidade e a precisão dos preços podem mudar e diferir das condições atuais. Continuar?',
        cart: 'Carrinho',
        cartEmpty: 'Há apenas uma quantidade neste item/menu no seu carrinho, reduzir a quantidade significa remover itens do seu carrinho... Continuar?',
        alert: 'Alerta',
        noteProcessOrder: 'O servidor está processando a sua solicitação, por favor aguarde... Se o processo demorar demais, reinicie o seu aplicativo ou verifique a sua conexão com a Internet.',
        confirmOrder: 'Confirmar o pedido',
        noteConfirmOrder: 'Tem certeza que deseja finalizar seu pedido? Por favor, verifique o pedido novamente antes de confirmar.',
        noteCountDeliveryPrice: 'Ocorreu um erro ao calcular o preço de entrega, por favor, reinicie o aplicativo para continuar.',
        noteDeliveryPrice: 'Ocorreu um erro ao obter sua localização para calcular o preço de entrega, por favor, reinicie o aplicativo e certifique-se de que seu GPS esteja ligado para continuar.',
        notePlatformPrice: 'Ocorreu um erro ao recuperar a taxa de plataforma, por favor, reinicie o aplicativo para continuar.',
        noteNonPartnerReceipt: 'Este vendedor não é um parceiro oficial de Maxim. A disponibilidade de itens e os preços listados podem variar. Por favor, faça um pagamento de acordo com o preço indicado no recibo.',
        merchantClose: 'O vendedor está fechado!'
    },
    helmet: {
        title: {
            start: 'Foods&Goods - Início',
            Home: 'Foods&Goods',
            Category: 'Foods&Goods - categoria de vendedor',
            History: 'Foods&Goods - Histórico',
            DetailCart: 'Foods&Goods - Detalhes do carrinho',
            afterOrder: 'Foods&Goods - Após o Pedido',
            HistoryDetail: 'Foods&Goods - Detalhes do histórico'
        },
        desc: {
            start: 'Início',
            Home: 'Foods&Goods',
            Category: 'Lista de vendedores por categoria',
            History: 'Lista das suas operações anteriores',
            DetailCart: 'Detalhes dos itens no seu carrinho',
            afterOrder: 'Meu pedido',
            HistoryDetail: 'Detalhes do histórico'
        }
    },
    topNav: { title: 'Foods&Goods' },
    backTopNav: { History: 'Histórico' },
    loading: 'Carregando…',
    data: {
        noDataMerchant: 'Nenhum vendedor encontrado',
        DataMerchant: 'Vendedor encontrado',
        notFound: 'Não encontrado'
    },
    bottomNav: {
        Home: 'Principal',
        Explore: 'Explorar',
        Promo: 'Promoções',
        History: 'Histórico',
        Cart: 'Carrinho'
    },
    status: {
        Open: 'Abrir',
        Close: 'Encerrado',
        case0: 'Aguardando a aprovação da loja',
        case1: 'Pedido aprovado pela loja',
        case2: 'O motorista da Maxim entregou o pedido',
        case3: 'O motorista da Maxim foi designado para o pedido',
        case4: 'O motorista está aguardando',
        case11: 'Pedido concluído',
        case9: 'Pedido cancelado',
        caseNotFound: 'Status do pedido não encontrado',
        orderStatus0: 'Aguardando',
        orderStatus1: 'Aprovado pelo vendedor',
        orderStatus2: 'Retirado pelo motorista',
        orderStatus3: 'Motorista designado',
        orderStatus4: 'O motorista está aguardando',
        orderStatus9: 'Cancelado pelo vendedor',
        orderStatus11: 'Concluído',
        orderStatus99: 'Cancelado pelo usuário',
        orderStatusNotFound: 'Status não encontrado',
        itemNotFound: 'Nenhum item encontrado nessa categoria'
    },
    receiveAt: { statusAccepted: 'Ainda não recebido' },
    bottomConfirm: {
        payment: {
            title: 'Métodos de pagamento digitais (s)',
            title2: 'Outros meios de pagamento',
            cash: 'Em dinheiro',
            noncash: 'Pagamento eletrônico',
            paywith: 'Pagar com',
            soon: 'Em breve'
        },
        btn: { confirm: 'Confirmar e prosseguir para o pedido' }
    },
    itemDetails: { note: 'Observação para o vendedor' },
    addon: {
        optional: 'Opcional',
        qty: 'Quantidade',
        additm: 'Adicionar item',
        edititm: 'Atualizar item',
        max: 'Selecionar o máximo',
        edit: 'Editar',
        require: 'Obrigatório',
        selectrequire: 'Selecionar Mínimo',
        price: 'Preço',
        addcustome: 'Adicionar outro cliente',
        free: 'Grátis'
    },
    promo: { listMerchants: 'Lista de comerciantes' }
};
export default En;