import React from 'react'
import { useHistory } from 'react-router-dom';

import {
    Star,
    District,
    PriceLabel,
    MerchantImage
} from '../../assets'

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { t } from '../../miscellaneous/language';
import ReactGA from "react-ga4";
import { getMerchantDetails } from '../../application/actions/merchantActions';
import { useDispatch } from 'react-redux';

const MerchantResult = ({ item }) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const clickMerchant = (id, partner_level) => {
        dispatch(getMerchantDetails(id));
        history.push(`/merchant/${id}`)
        console.log(partner_level);
        ReactGA.event(`Click ${partner_level === 1 ? 'partner': 'non-partner'}`, {
            category: "Click Merchant",
            action: "Click Merchant",
            label: "Click Merchant", // optional
            value: 1, // optional, must be a number
        });
    }

    return (
        // <div className="explore-result-container">
            <div className="explore-merchant-list-card" onClick={(e) => { item.is_closed === 1 ? alert(t.alert.merchantClose) : clickMerchant(item.id, item.partner_level) }}>
                {item.is_closed === 0 ?
                    <div className="img-container merchant-list-img">
                        <img src={item.image} className="merchant-list-img" onError={(event) => event.target.src = MerchantImage} alt="" />
                        <span className={item.partner_level == 0 ? "badge-np" : "badge"}>
                            {item.partner_level == 0 ? "non-partner" : "partner"}
                        </span>
                        {/* <div className="merchant-list-img-status">status</div> */}
                    </div>
                    :
                    <div className="img-container merchant-list-img" style={{ backgroundImage: "url(" + item.image + ")" }} >
                        <p className="merchant-list-img-status" style={{ color: 'white', textAlign: 'center', paddingTop: 40, fontWeight: 'bold', fontSize: 20 }}>{item.is_closed === 0 ? t.status.Open : t.status.Close}</p>
                        <span className="badge">
                            {item.partner_level == 0 ? "non-partner" : "Partner"}
                        </span>
                    </div>                    
                }
                <div className="merchant-list-description-container">
                    <span className="merchant-list-name">{item.name}</span>
                    <span className="merchant-list-desc">{item.desc}</span>
                    <div className="border"></div>
                    <div className="merchant-list-detail-container">
                        <div className="merchant-list-detail"><span><Star id="merchant-icon-info" fill="#FF6000" /> {item.avg_rating}</span></div>
                        <div className="merchant-list-detail"><span><District id="merchant-icon-info" /> {item.district}</span></div>
                        <div className="merchant-list-detail"><span><PriceLabel id="merchant-icon-info" /> {formatCurrencyKStyle(item.min)} - {formatCurrencyKStyle(item.max)}</span></div>
                    </div>
                </div>
            </div>
        // </div>
    )
}

export default MerchantResult
