import React from 'react'

const MerchantMenuList = () => {
    return (
        <div className="merchant-menu-list-container">
            <div className="merchant-menu-list-wrapper-loading loading">

            </div>
        </div>
    )
}

export default MerchantMenuList