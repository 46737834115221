import {
    GET_PROMO_MERCHANTS,
    GET_PROMO_MERCHANTS_SUCCESS,
    GET_PROMO_MERCHANT_ITEMS,
    GET_PROMO_MERCHANT_ITEMS_SUCCESS,
    GET_PROMO_NEW_MERCHANT,
    GET_PROMO_NEW_MERCHANT_SUCCESS
} from '../../miscellaneous/constants';

const INITIAL_STATE = {
    requestComplete: false,
    total: 0,
    pageSize: 1,
    data: []
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = {
    merchants: INITIAL_STATE,
    items: INITIAL_STATE,
    new_merchants: INITIAL_STATE
}, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PROMO_MERCHANTS:
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    pageSize: payload.pageSize
                }
            }
        case GET_PROMO_MERCHANTS_SUCCESS:
            return {
                ...state,
                merchants: {
                    ...state.merchants,
                    requestComplete:payload.requestComplete,
                    total: payload.total,
                    data: [...state.merchants.data, ...payload.merchants]
                }
            }
        case GET_PROMO_MERCHANT_ITEMS:
            return {
                ...state,
                items: {
                    ...state.items,
                    pageSize: payload.pageSize
                }
            }
        case GET_PROMO_MERCHANT_ITEMS_SUCCESS:
            return {
                ...state,
                items: {
                    ...state.items,
                    total: payload.total,
                    requestComplete:payload.requestComplete,
                    data: [...state.items.data, ...payload.merchants]
                }
            }  
        case GET_PROMO_NEW_MERCHANT:
            return {
                ...state,
                new_merchants: {
                    ...state.new_merchants,
                    pageSize: payload.pageSize
                }
            }
        case GET_PROMO_NEW_MERCHANT_SUCCESS:
            return {
                ...state,
                new_merchants: {
                    ...state.new_merchants,
                    total: payload.total,
                    requestComplete:payload.requestComplete,
                    data: [...state.new_merchants.data, ...payload.merchants]
                }
            }  
        default: return state;
    }
}