import {
    SET_USERDATA,
    AUTHENTICATE_USER,
} from '../../miscellaneous/constants';

const INITIAL_STATE = {
    userData: null,
    isAuthenticated: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_USERDATA:
            return {
                ...state,
                userData: payload
            }
        case AUTHENTICATE_USER:
            return {
                ...state,
                isAuthenticated: true
            }
        default: return state;
    }
}