import React from 'react';

const useDidMount = (INITIAL_STATE = false) => {
    const [didMount, setDidMount] = React.useState(INITIAL_STATE);

    React.useEffect(() => {
      setDidMount(true);

        return () => { setDidMount(false); }
    }, []);

    return didMount;
}

export default useDidMount;