import React from "react";
import { Helmet } from "react-helmet";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { setRequestStatus } from "../../application/actions/miscActions";
import {
  getMerchantDetails,
  searchMerchantItem,
} from "../../application/actions/merchantActions";

import { useDidMount } from "../../application/hooks";
import { confirmAlert } from "react-confirm-alert";

import {
  BottomSpace,
  MerchantDetailTopNav,
  MerchantItemSearch,
  MerchantDetailButton,
} from "../components/common";

import {
  MerchantDetailDiscountContainer,
  MerchantDetailItem,
  MerchantMenuContainer,
  ItemsDetails,
  MerchantDetails,
} from "../";

import BottomNav from "../components/common/BottomNav/BottomNav";

import "../../App.css";
import { useHistory } from "react-router";
import { t } from "../../miscellaneous/language";
import MerchantItemAddExisting from "../components/common/Modal/MerchantItemAddExisting";

const MerchantDetailContainer = ({ match, location }) => {
  const { merchantId } = match.params;
  const history = useHistory();

  // console.log(history);

  const [clickedDetail, setClickedDetail] = React.useState(false);
  const [clickedDetailMerchant, setClickedDetailMerchant] =
    React.useState(false);
  const [detailData, setDetailData] = React.useState();
  const [selectedItemCat, setSelectedItemCat] = React.useState("None");
  const [typeModal, setTypeModal] = React.useState(null);

  const dispatch = useDispatch();
  const didMount = useDidMount(true);

  const store = useSelector(
    (state) => ({
      cart: state.cart,
      keyword: state.merchants.keyword,
      merchant_details: state.merchants.details,
      searched_merchant_items: state.merchants.searchedMerchantItems,
      requestStatus: state.app.requestStatus,
      isLoading: state.app.loading,
    }),
    shallowEqual
  );

  const handleDetailClick = async (itemDetail, itemCat, type) => {
    if (type === "merchant") {
      await window.scrollTo(0, 0);
      setDetailData(itemDetail);
      setClickedDetailMerchant(true);
      setTypeModal(type);
    } else if (type === "share") {
      await window.scrollTo(0, 0);
      setDetailData(itemDetail);
      setClickedDetailMerchant(true);
      setTypeModal(type);
    } else if (type === "item") {
      if (itemDetail && itemCat) {
        await window.scrollTo(0, 0);
        setDetailData(itemDetail);
        setSelectedItemCat(itemCat);
        setClickedDetail(true);
        setTypeModal(type);
      }
    } else if (type === "itemaddexsisting") {
      if (itemDetail && itemCat) {
        // await window.scrollTo(0, 0);
        setDetailData(itemDetail);
        setSelectedItemCat(itemCat);
        setClickedDetail(true);
        setTypeModal(type);
      }
    } else {
      // await dispatch(getMerchantDetails(itemDetail.merchants_id));
      delete location.state;
      setDetailData();
      setSelectedItemCat("None");
      setClickedDetail(false);
      setClickedDetailMerchant(false);
    }
  };

  const checkMerchhantPartner = (type) => {
    console.log(type, 'ini adalah type')
    switch (type) {
      case "merchants":
        if (store.merchant_details?.id == merchantId) {
          if (store.merchant_details?.partner_level == 0) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className="alert">
                    <h1>{t.alert.nonPartner}</h1>
                    <p>{t.alert.noteNonPartner}</p>
                    <div className="alert-button-container">
                      <button className="yes-button-full" onClick={onClose}>
                        {t.btn.yes}
                      </button>
                    </div>
                  </div>
                );
              },
            });
          }
        }
        break;

      case "merchantsPlus":
        console.log('masuk kesini bre');
        if (store.merchant_details?.id == merchantId) {
            if (store.merchant_details?.partner_level == 2) {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return (
                    <div className="alert">                      
                      <p>{t.alert.partnerPlus}</p>
                      <div className="alert-button-container">
                        <button className="yes-button-full" onClick={onClose}>
                          {t.btn.yes}
                        </button>
                      </div>
                    </div>
                  );
                },
              });
            }
          }
        break;
    }
  };

  React.useEffect(() => {
    // console.log(history.action)
    if ((didMount && !store.merchant_details?.id) || history.action === "POP") {
      dispatch(getMerchantDetails(merchantId));
    }
    if (location?.state !== "undefined") {
      if (history.action === "POP") {
        delete location?.state;
      }
      if (location?.state?.catChoosed === "None") {
        handleDetailClick(
          location.state.item,
          location.state.catChoosed,
          location.state.type
        );
      }
    }
    // checkMerchhantPartner();
  }, [
    didMount,
    merchantId,
    selectedItemCat,
    detailData,
    store.keyword,
    dispatch,
  ]);

  React.useEffect(
    () => () => {
      dispatch(searchMerchantItem(""));
      dispatch(setRequestStatus(""));
      if (localStorage.getItem("merchant-shared")) {
        localStorage.removeItem("merchant-shared");
      }
      // eslint-disable-next-line
    },
    []
  );

  console.log(store.cart, "2");

  return (
    <>
      <Helmet>
        <title>{`Foods&Goods - ${store.merchant_details?.name}`}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={store.merchant_details?.name} />
      </Helmet>
      <div className="wrapper-gray">
        <MerchantDetailTopNav
          title={store.merchant_details?.name}
          isClicked={clickedDetail}
          handleDetailClick={handleDetailClick}
        />
        <MerchantDetailItem
          checkMerchantPartner={checkMerchhantPartner}
          merchant_details={store.merchant_details}
          clickdetails={handleDetailClick}
        />
        {/* {clickedDetail ?             
                <>
                <ItemDetail item={detailData} itemCat={selectedItemCat}/>
                <BottomSpace class="gray-bottom-space" /> 
                </>    
                :             
                <>
                </>
                } */}
        {store.keyword == null || store.keyword === "" ? (
          <>
            <MerchantDetailDiscountContainer
              items={store.merchant_details.merchant_items}
              detailFunction={handleDetailClick}
              typeUI={"mdc"}
            />
            <MerchantMenuContainer
              items={store.merchant_details.merchant_item_category}
              detailFunction={handleDetailClick}
            />
            <BottomSpace class="merchant-bottom-space" />
          </>
        ) : (
          <>
            <MerchantDetailDiscountContainer
              items={store.searched_merchant_items?.merchant_items}
              search={true}
              detailFunction={handleDetailClick}
              typeUI={"mdc"}
            />
            <MerchantMenuContainer
              items={store.searched_merchant_items?.merchant_item_category}
              search={true}
              detailFunction={handleDetailClick}
            />
            <BottomSpace />
          </>
        )}
        <MerchantDetailButton />
        <BottomNav />
      </div>

      <ItemsDetails
        merchant={store.merchant_details}
        items={detailData}
        itemCat={selectedItemCat}
        ItemsDetailsModal={clickedDetail}
        handleDetailClick={handleDetailClick}
        type={typeModal}
      />

      <MerchantDetails
        type={typeModal}
        merchant={detailData}
        MerchantDetailsModal={clickedDetailMerchant}
        close={handleDetailClick}
        handleDetailClick={handleDetailClick}
      />
    </>
  );
};

export default MerchantDetailContainer;
