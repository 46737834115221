import React from "react";
import './Addon.css';

import { t } from '../../../miscellaneous/language';

const AddonCard = ({ item, addonChoosed, checkboxHandle, checkRequire, requireAlert, requireData }) => {
  React.useEffect(() => {
    checkRequire(item?.cat_addon ? item?.cat_addon?.name.split(' ').join('_') + '-*&id:' + item?.cat_addon?.id : null, item?.cat_addon ? item?.cat_addon?.require : null)
    console.log(item, 'ssss')
  }, [addonChoosed])
  const Require = (requireData[item?.cat_addon?.name.split(' ').join('_') + '-*&id:' + item?.cat_addon?.id] && requireAlert);
  console.log(item?.cat_addon, 'kira222');
  return (
    <>
      {
        // validation cat items that have items show
        item?.cat_addon?.item_addon?.length > 0 ? (
          <div className="merchant-menu-list-card">
            <div className="merchant-menu-list-card-wrapper">
              <div className="merchant-menu-list-card-title merchant-menu-list-card-vertical">
                <div className="merchant-menu-list-card-title-main">
                  <b>{item?.cat_addon?.name}</b>
                </div>
                <div className="merchant-menu-list-card-title-submain" style={Require ? { color: 'red' } : {}}>
                  <a>
                    {item?.cat_addon?.require > 0 ? "Required" : "Optional"}
                  </a>
                  <a> . </a>
                  {
                    item?.cat_addon?.limit > 0 ?
                      <>
                        <a style={{ color: "gray" }}>
                          {t.addon.max} {item?.cat_addon?.limit}
                        </a>
                        <a style={{ color: "gray" }}> . </a>
                      </>
                      :
                      <></>
                  }                                  
                  <a style={{ color: "gray" }}>{item?.cat_addon?.require > 0 ? [t.addon.selectrequire, " ", item?.cat_addon?.require] : ""} </a>
                </div>
              </div>
              <blockquote>
                <div style={{ borderBottom: "3px dashed #f5f5f5" }}></div>
              </blockquote>
              {item?.cat_addon?.item_addon?.map((item1, index1) => {
                function check() {
                  if (typeof addonChoosed[item?.cat_addon?.name.split(" ").join("_") + '-*&id:' + item?.cat_addon?.id]  === "undefined") {
                    return false;
                  } else if (typeof addonChoosed[item?.cat_addon?.name.split(" ").join("_") + '-*&id:' + item?.cat_addon?.id] !== "undefined") {
                    return addonChoosed[item?.cat_addon?.name.split(" ").join("_") + '-*&id:' + item?.cat_addon?.id].filter((choosed) => JSON.stringify(item1) === JSON.stringify(choosed)).length === 0 ? false : true;
                  }
                }
                // const check = (typeof (addonChoosed[item?.cat_addon?.name?.split(' ').join('_')]) === undefined) ?
                // false :
                // (addonChoosed[item?.cat_addon?.name?.split(' ').join('_')].filter(choosed => JSON.stringify(choosed) === JSON.stringify(item1)).length === 0) ? false : true
                // console.log(check, item.cat_addon.name, item1)
                return (
                  <div className="merchant-menu-list-card-item merchant-menu-list-card-vertical"
                  // onClick={(e) =>{checkboxHandle(!document.getElementById(`checkbox-addon-${index1}`).checked, item1, item?.cat_addon)}}
                  >
                    {
                      item1.is_empty == 1 ?
                      <>                  
                        <div className="merchant-menu-list-card-item-name">
                          <a>{item1?.name}</a>
                        </div>
                        <div className="merchant-menu-list-card-item-text-empty">
                          <a>{t.text.empty}</a>
                        </div>
                        {/* <div className="merchant-menu-list-card-item-checkbox">                                            
                          
                        </div> */}
                      </>
                      :
                      <>                  
                        <div className="merchant-menu-list-card-item-name">
                          <a>{item1?.name}</a>
                        </div>
                        <div className="merchant-menu-list-card-item-text">
                          {item1?.price === 0 ? <a>{t.addon.free}</a> : <a>{item1?.price}</a>}
                          {/* {console.log(item1?.price, 'sial sial')} */}
                        </div>
                        <div className="merchant-menu-list-card-item-checkbox">                                            
                          <input
                            type="checkbox"
                            // name="vehicle1"
                            className="checkbox-addon"
                            id={`checkbox-addon-${index1}`}
                            checked={check()}
                            onChange={(e) =>
                              {
                                if(item1.is_empty == 0) {
                                  checkboxHandle(e.target.checked, item1, item?.cat_addon)
                                }
                              }
                            }
                          />
                        </div>
                      </>
                    }

                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
};

export default AddonCard;
