import React from 'react'
import PropType from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { t } from '../../../miscellaneous/language';
import { getMerchantDetails } from '../../../application/actions/merchantActions';
import { useDispatch } from 'react-redux';

const PromoDetailMerchantListContainer = ({ merchants }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const clickMerchant = (merchant) => {
        dispatch(getMerchantDetails(merchant.id));
        history.push(`/merchant/${merchant.id}`, {from: location.pathname})
        // console.log(partner_level);
        // ReactGA.event(`Click ${partner_level === 1 ? 'partner': 'non-partner'}`, {
        //     category: "Click Merchant",
        //     action: "Click Merchant",
        //     label: "Click Merchant", // optional
        //     value: 1, // optional, must be a number
        // });
    }

    console.log(merchants?.length, 'merchant is closed');
    return (
        <div className="promo-detail-merchant-list-container">
            <span className="promo-detail-merchant-list-text">{t.promo.listMerchants}</span>
            {merchants.map((merchant, index) => (
                merchant.is_closed == 0?
                <div key={index} className="promo-detail-merchant-list-card" onClick={(e) => {clickMerchant(merchant)}}>
                    <img src={merchant.image} alt="" />
                    <span className="promo-detail-merchant-name">{merchant.name}</span>
                </div>
                :
                <div key={index} className="promo-detail-merchant-list-card" onClick={(e) => alert(t.alert.merchantClose)}>
                    <p className="merchant-list-img-status-promo">{t.status.Close}</p>
                    <img src={merchant.image} alt="" />
                    <span className="promo-detail-merchant-name">{merchant.name}</span>
                </div>
            ))}            
        </div>
    )
}

PromoDetailMerchantListContainer.propTypes = {
    merchants: PropType.array.isRequired
}

export default PromoDetailMerchantListContainer

