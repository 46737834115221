import React from 'react';
import useSWR from 'swr';
import axios from 'axios';

const usePublicLink = (orderId) => {
    const [orderData, setOrderData] = React.useState(null);

    const fetcher = (url) => axios.get(url, {});

    const {
        data,
        error,
        mutate
    } = useSWR('https://idmarket-api.taximaxim.com/api/v1/public/' + orderId, fetcher); // prod
    // const {
    //     data,
    //     error,
    //     mutate
    // } = useSWR('http://localhost:8001/api/v1/public/' + orderId, fetcher); // dev

    React.useEffect(() => {
        if (data) {
            setOrderData(data.data);
        }
        if (error) {
            setOrderData(null);
        }

        console.log(data)
        // eslint-disable-next-line
    },[data, error]);

    return {
        orderData,
        mutate
    }
}

export default usePublicLink;
