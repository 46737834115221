import React from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { calculateTotal, formatCurrency } from '../../../../miscellaneous/helpers/utils';

// import {
//     Cart
// } from '../../../../assets'

import Cart from '../../../../assets/icons/CartBlack.svg'

import './Button.css';
import { t } from '../../../../miscellaneous/language';

const MerchantDetailButton = () => {
    const history = useHistory();

    const { cart } = useSelector((state) => ({
        cart: state.cart
    }));

    const countSumOfQty = () => {
        let total = 0;
        for (var i in cart.items) {
            total += cart.items[i].qty;
        }
        return total;
    }

    const countCartSummary = () => {
        var totalPrice = 0
        var totalDiscount = 0
        // eslint-disable-next-line array-callback-return
        cart.items?.map((cartItem) => {
            totalPrice = totalPrice + (cartItem.total_with_addon ? cartItem.total_with_addon * cartItem.qty : cartItem.total * cartItem.qty)
          if (cartItem.discount) {
              totalDiscount = totalDiscount + (cartItem.discount_amount * cartItem.qty)
          }
        });

        return {
            totalPrice,
            totalDiscount
        }
      };

    return (
        <>
            {cart.items?.length === 0 ? <div className="none"></div> :
                <div className="merchant-detail-button-container" onClick={(e) => { history.push("/cart/detail") }}>
                    <div className='merchant-detail-button-container-count-item'>
                        <span className="merchant-detail-item-selected">{`${cart.items?.length === 0 ? 0 : countSumOfQty()} ${countSumOfQty() > 1 ? t.text.items : t.text.item}`} | {formatCurrency(countCartSummary().totalPrice)}</span>
                    </div>
                    <div className='merchant-detail-button-container-info'>
                        <div className='merchant-detail-button-container-into-wrap'>
                            <p className="merchant-detail-button">{t.btn.viewCart}</p>
                            <img src={Cart} id="cart-small-icon" />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default MerchantDetailButton
