import React from 'react'
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { t } from '../../../../miscellaneous/language';

const AfterOrderButton = ({status, refreshStatus}) => {
    const history = useHistory();
    const store = useSelector((state) => ({
        cart: state.cart
    }));

    const deepLinking = () => {
        console.log(`maximzakaz://app?action=openorder&id=${store.cart.orders_response.mztid}`);
        window.location = `maximzakaz://app?action=openorder&id=${store.cart.orders_response.mztid}`;
        // console.log(`maximzakaz://order?refOrgId=777&startLatitude=${store.cart.orders_response.merch_lat}&startLongitude=${store.cart.orders_response.merch_lon}&startAddressName=${store.cart.merchant.name}&endLatitude=${store.cart.orders_response.user_lat}&endLongitude=${store.cart.orders_response.user_lon}&endAddressName=${store.cart.orders.city}&description=${store.cart.orders_response.notes}&orderPrice=${store.cart.orders_response.total}&ifmaOrderID=${store.cart.orders_response.mztid}`);
        // window.location = `maximzakaz://order?refOrgId=777&startLatitude=${store.cart.orders_response.merch_lat}&startLongitude=${store.cart.orders_response.merch_lon}&startAddressName=${store.cart.merchant.name}&endLatitude=${store.cart.orders_response.user_lat}&endLongitude=${store.cart.orders_response.user_lon}&endAddressName=${store.cart.orders.city}&description=${store.cart.orders_response.notes}&orderPrice=${store.cart.orders_response.total}&ifmaOrderID=${store.cart.orders_response.mztid}`;
    }

    return (
        <>
            {
                (status === 2 || status === null ? '' : 
                    <div className="button-orange-container" onClick={() => {
                        if(status === 0) {
                            refreshStatus();
                        }
                        else if(status === 9) {
                            history.push('/home')
                        }
                        else {
                            deepLinking();
                        }
                    }}>
                        <button>{status === 0 ? t.btn.status.orderStatus0 : status === 1 ? t.btn.status.orderStatus1 : status === 9 ? t.btn.status.orderStatus9 : ''}</button>
                    </div>
                )
            }
        </>
    )
}

export default AfterOrderButton