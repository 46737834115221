import {
    SET_CART_ITEMS,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CLEAR_CART,
    ADD_QTY_ITEM,
    REMOVE_QTY_ITEM,
    SET_NOTE_ITEMS,
    SEND_ORDER,
    SEND_ORDER_SUCCESS,
    REFRESH_ORDER_STATUS,
    REFRESH_ORDER_STATUS_SUCCESS,
    ADD_QTY_ITEM_ON_DETAIL,
    ADD_QTY_ITEM_ADDON,
    EDIT_QTY_ITEM_ADDON
} from '../../miscellaneous/constants';

export const setCartItems = (items = []) => ({
    type: SET_CART_ITEMS,
    payload: items
});
  
export const addToCart = (merchantProduct, merchant) => ({
    type: ADD_TO_CART,
    payload: { merchantProduct, merchant }
});

export const removeFromCart = (id) => ({
    type: REMOVE_FROM_CART,
    payload: id
});

export const clearCart = () => ({
    type: CLEAR_CART
});

export const addQtyItem = (id) => ({
    type: ADD_QTY_ITEM,
    payload: id
});
export const addQtyItemonDetail = (id, qty) => ({
    type: ADD_QTY_ITEM_ON_DETAIL,
    payload: {
        id, qty
    }
});

export const addQtyItemAddon = (id, qty, data_addon) =>{
    console.log(data_addon, 'yyyy')
    return ({
    type: ADD_QTY_ITEM_ADDON,
    payload: {
        id, qty, addon:data_addon
    }
})};
export const editQtyItemAddon = (id, qty, data_addon, total_price) =>{
    console.log(data_addon, 'yyyy')
    return ({
    type: EDIT_QTY_ITEM_ADDON,
    payload: {
        id, qty, addon:data_addon, total_price
    }
})};

export const setNoteItems = (id, note) => ({
    type: SET_NOTE_ITEMS,
    payload: { id, note }
})

export const removeQtyItem = (id) => ({
    type: REMOVE_QTY_ITEM,
    payload: id
});

export const sendOrder = (userID, lat, lon, city, host, name, addressName, total_item, total_price, total_discount, delivery_price, total, notes, merchants_id, order_items, platform_fee, parking_fee, takeaway_charge) => ({
    type: SEND_ORDER,
    payload: { userID, lat, lon, city, host, name, addressName, total_item, total_price, total_discount, delivery_price, total, notes, merchants_id, order_items, platform_fee, parking_fee, takeaway_charge }
})

export const sendOrderSuccess = (order_data) => ({
    type: SEND_ORDER_SUCCESS,
    payload: order_data
});

export const refreshOrderStatus = (order_id) => ({
    type: REFRESH_ORDER_STATUS,
    payload: order_id
})

export const refreshOrderStatusSuccess = (order_data) => ({
    type: REFRESH_ORDER_STATUS_SUCCESS,
    payload: order_data
})