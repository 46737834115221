import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { clearFilterNearMeCategoryState } from '../../../../application/actions/merchantActions';

import {
    AngleSmallLeft,
    Home
} from '../../../../assets'

import '../../../../assets/icons/icons.css'


const CategoryTopNav = (props) => {
    const dispatch = useDispatch()
    // const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className="category-topnav-container">
                <Link className="topnav-left-side" to='/home' onClick={() => dispatch(clearFilterNearMeCategoryState())}>
                    <AngleSmallLeft id="back-small-icon" />
                </Link>
                {/* <div className="topnav-left-side" onClick={() => {
                    history.goBack()
                    dispatch(clearFilterNearMeCategoryState())
                    }}>
                </div> */}
                <div className="topnav-center-side">
                    <span className="topnav-title-text">{props.title}</span>
                </div>
                <Link to="/home" className="topnav-right-side">
                    <Home id="home-small-icon" />
                </Link>
            </div>
        </>
    )
}

export default CategoryTopNav
