import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { shallowEqual, useSelector } from 'react-redux';

import * as ROUTE from '../../../../miscellaneous/constants';

import {
    Home,
    // Explore, 
    // Promo, 
    History
} from '../../../../assets/'

import CartBottom from '../../../../assets/icons/CartBottom.svg'
import HistoryBottom from '../../../../assets/icons/HistoryBottom.svg'
import HomeBottom from '../../../../assets/icons/HomeBottomnav.svg'

import './BottomNav.css'
import { t } from '../../../../miscellaneous/language';

const BottomNav = () => {
    const location = useLocation();
    const history = useHistory();

    const { cart } = useSelector((state) => ({
        cart: state.cart
    }));

    const countSumOfQty = () => {
        let total = 0;
        for (var i in cart.items) {
            total += cart.items[i].qty;
        }
        return total;
    }

    // history.listen((e,x) => console.log(e, x, 'xe') )

    const store = useSelector((state) => ({
        auth: state.auth
    }), shallowEqual);
  
    React.useEffect(() => {
        const path = sessionStorage.getItem('path')
      if(path) {
        const prevPath = sessionStorage.getItem('prevPath')
        if(prevPath) {
            sessionStorage.removeItem('prevPath')
            sessionStorage.setItem('prevPath', path)
        }
        else {
            sessionStorage.setItem('prevPath', path)
        }
        sessionStorage.setItem('path', location.pathname)
      }
      else {
        sessionStorage.setItem('path', location.pathname)
      }
  }, [location.pathname])
    return (
        <div className="bottomnav-wrapper">
            <div className="bottomnav-container">
                <Link className={location.pathname === ROUTE.ROUTE_HOME ?
                    'bottomnav-link active' : 'bottomnav-link'}
                    to={ROUTE.ROUTE_HOME}
                >
                    <img src={HomeBottom} id="bottomnav-icon" />
                    <span className="bottomnav-text">{t.bottomNav.Home}</span>
                </Link>
                <Link className={location.pathname === ROUTE.ROUTE_HISTORY ?
                    'bottomnav-link active' : 'bottomnav-link'}
                    to={ROUTE.ROUTE_HISTORY}
                >
                    {store?.auth?.userData?.running_order ?
                        <Badge color="secondary" variant="dot">
                            <img src={HistoryBottom} id="bottomnav-icon" />
                        </Badge>
                        :
                        <img src={HistoryBottom} id="bottomnav-icon" />
                    }
                    <span className="bottomnav-text">{t.bottomNav.History}</span>
                </Link>
                <Link className={location.pathname === ROUTE.ROUTE_CART ?
                    'bottomnav-link active' : 'bottomnav-link'}
                    to={ROUTE.ROUTE_CART}
                >
                    <Badge badgeContent={countSumOfQty()} color="secondary">
                        <img src={CartBottom} id="bottomnav-icon" />
                    </Badge>
                    <span className="bottomnav-text">{t.bottomNav.Cart}</span>
                </Link>
            </div>
        </div>
    )
}

export default BottomNav
