import React from 'react'
import PropType from 'prop-types';

import {
    Star,
    District,
    PriceLabel,
    MerchantImage
} from '../../assets/'

import './Merchant.css';

import { formatCurrencyKStyle } from '../../miscellaneous/helpers/utils';
import { useHistory } from 'react-router-dom';

const MerchantLoadList = ({ merchants }) => {
    const history = useHistory();
    return (
        <>
            <div className="merchant-list-card-loading">
                <div className="merchant-list-img-merchant-loading loading">
                    {/* <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}></p> */}
                </div>


                <div className="merchant-list-description-container">
                    {/* <span className="merchant-list-name" ></span>
                    <span className="merchant-list-desc" ></span>
                    <div className="border"></div> */}
                    <div className="merchant-list-detail-container-loading">
                        <div className='component-card-loading'>
                            <div className='component-title-loading loading'></div>
                            <div className='component-description-loading loading'></div>
                        </div>
                        {/* <div className="merchant-list-detail" ><Star id="merchant-icon-info" fill="#FF6000" /> <span > - </span></div>
                        <div className="merchant-list-detail" ><District id="merchant-icon-info" /> <span > - </span></div>
                        <div className="merchant-list-detail" ><PriceLabel id="merchant-icon-info" /> <span >0 - 0</span></div> */}
                        {/* <div className="merchant-list-detail"><span className="merchant-list-detail-halal">NON HALAL</span></div> */}
                        {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? <HalalLogo id="halal-logo" /> : ""}</span></div> : ''} */}
                        {/* {merchant.type ? <div className="merchant-list-detail"><span className="merchant-list-detail-halal">{merchant.halal ? "halal" : "non-halal"}</span></div> : ''} */}
                    </div>                    
                </div>
            </div>
            <div className="merchant-list-card-loading">
                <div className="merchant-list-img-merchant-loading loading">
                    {/* <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}></p> */}
                </div>

                <div className="merchant-list-description-container">                    
                    <div className="merchant-list-detail-container-loading">
                        <div className='component-card-loading'>
                            <div className='component-title-loading loading'></div>
                            <div className='component-description-loading loading'></div>
                        </div>                        
                    </div>                    
                </div>
            </div>
            <div className="merchant-list-card-loading ">
                <div className="merchant-list-img-merchant-loading loading">
                    {/* <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}></p> */}
                </div>

                <div className="merchant-list-description-container">                    
                    <div className="merchant-list-detail-container-loading">
                        <div className='component-card-loading'>
                            <div className='component-title-loading loading'></div>
                            <div className='component-description-loading loading'></div>
                        </div>                        
                    </div>                    
                </div>
            </div>
            <div className="merchant-list-card-loading ">
                <div className="merchant-list-img-merchant-loading loading">
                    {/* <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}></p> */}
                </div>

                <div className="merchant-list-description-container">                    
                    <div className="merchant-list-detail-container-loading">
                        <div className='component-card-loading'>
                            <div className='component-title-loading loading'></div>
                            <div className='component-description-loading loading'></div>
                        </div>                        
                    </div>                    
                </div>
            </div>
            <div className="merchant-list-card-loading ">
                <div className="merchant-list-img-merchant-loading loading">
                    {/* <p  className="merchant-list-img-status" style={{color:'white', textAlign:'center', paddingTop:25, fontWeight:'bold', fontSize:20}}></p> */}
                </div>

                <div className="merchant-list-description-container">                    
                    <div className="merchant-list-detail-container-loading">
                        <div className='component-card-loading'>
                            <div className='component-title-loading loading'></div>
                            <div className='component-description-loading loading'></div>
                        </div>                        
                    </div>                    
                </div>
            </div>
        </>
    )
}

// FilterNearestMerchantItem.propTypes = {
//     merchants: PropType.array.isRequired
// }

export default MerchantLoadList
