import React from 'react'
import { Confirm } from '../../assets'
import { t } from '../../miscellaneous/language'

const MerchantConfirmStatus = ({updateStaticStatus}) => {
    return (
        <div className="after-order-card">
            <div className="after-order-status-container">
                <span className="after-order-title">{t.text.merchantconfirm.title}</span>
                <span className="after-order-desc">{t.text.merchantconfirm.desc}</span>
            </div>
            <div className="after-order-icon-container">
                <Confirm id="confirm-medium-icon" />
            </div>
        </div>
    )
}

export default MerchantConfirmStatus
