import React from 'react'
import { Confirm } from '../../assets'
import { t } from '../../miscellaneous/language'

const OrderFoundDriver = ({updateStaticStatus}) => {
    return (
        <div className="after-order-card">
            <div className="after-order-status-container">
                <span className="after-order-title">{t.text.driverFound.title}</span>
                <span className="after-order-desc">{t.text.driverFound.desc}</span>
            </div>
            <div className="after-order-icon-container">
                <Confirm id="order-medium-icon" />
            </div>
        </div>
    )
}

export default OrderFoundDriver
