import React from 'react';
import { useSelector } from 'react-redux';

import { useDidMount } from '../hooks';
import { getPromoDetailAPI } from '../../infrastructure/services/api/Promo';

const usePromoDetail = (id, pageSize) => {
    // Get and check if promo merchant exists in store
    const storePromoMerchant = useSelector((state) => state.promo.items.data.find((item) => item.id === id));
    const userData = useSelector((state) => state.auth.userData);

    const [promoMerchant, setPromoMerchant] = React.useState(storePromoMerchant);
    const [isLoading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const didMount = useDidMount(true);

    React.useEffect(() => {
        (async () => {
            try {
                if (!promoMerchant || promoMerchant.id !== id) {
                    setLoading(true);
                    const response = await getPromoDetailAPI(
                        userData.userID, userData.lat, userData.lon, userData.city, userData.host, userData.name, userData.addressName, 
                        id,
                        pageSize
                    );

                    if (response.result.length === 0) {
                        setError('Merchant not found.');
                    } else {
                        const data = { ...response.result };

                        if (didMount) {
                            setPromoMerchant(data);
                            setLoading(false);
                        }
                    }
                }
            } catch (error) {
                if (didMount) {
                    setLoading(false);
                    setError(error?.message || 'Something went wrong.');
                }
            }
        })();
        // eslint-disable-next-line
    }, [id, pageSize]);

    return { promoMerchant, isLoading, error }
}

export default usePromoDetail;