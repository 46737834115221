import  { 
    UPDATE_NAME
} from '../../../miscellaneous/constants';
    
import {
    call,
    put
} from 'redux-saga/effects';

import { actionMessage } from '../../../miscellaneous/helpers/utils';

import { setLoading, setRequestStatus } from '../../actions/miscActions';

import { history } from '../../routers/Router';
import { setUserdata } from '../../actions/authAction';
import { setUserdataAPI } from '../../../infrastructure/services/api/Auth';

// import Swal from 'sweetalert2'

// const Toast = Swal.mixin({
//     toast: true,
//     position: 'top',
//     timer: 5000,
//     timerProgressBar: true
// })

export const getUserData = ((state) => state.auth.userData);

function* initRequest() {
    yield put(setLoading(true));
    yield put(setRequestStatus(null));
}

function* handleError(e) {
    yield put(setLoading(false));
    yield put(setRequestStatus(e?.message || 'Order failed!'));
    console.log('ERROR: ', e);
}

function* handleAction(location, message, status) {
    if (location) yield call(history.push, location);
    yield call(actionMessage, message, status);
}

function* authSaga({ type, payload }) {
    switch (type) {
        case UPDATE_NAME:
            try {
                yield initRequest();

                const response = yield call(setUserdataAPI,
                    payload.userID, payload.lat, payload.lon, payload.city, payload.host, payload.name, payload.addressName
                );
                
                console.log(response);

                if(response.success) {
                    yield put(setUserdata(payload.userID, payload.lat, payload.lon, payload.city, payload.host, payload.name, payload.addressName, response.running_order));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        default: { throw new Error(`Unexpected action type ${type}`); }
    }
}

export default authSaga;