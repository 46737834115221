import  { EXPLORE_MERCHANT } from '../../../miscellaneous/constants';
import {
    select,
    call,
    put
} from 'redux-saga/effects';

import { actionMessage } from '../../../miscellaneous/helpers/utils';

import { setLoading, setRequestStatus } from '../../actions/miscActions';
import { 
    exploreMerchantSuccess
} from '../../actions/exploreActions';

import { searchMerchantAPI } from '../../../infrastructure/services/api/Search';

import { history } from '../../routers/Router';

export const getUserData = ((state) => state.auth.userData);

function* initRequest() {
    yield put(setLoading(true));
    yield put(setRequestStatus(null));
}

function* handleError(e) {
    yield put(setLoading(false));
    yield put(setRequestStatus(e?.message || 'Failed to fetch merchants'));
    console.log('ERROR: ', e);
}

function* handleAction(location, message, status) {
    if (location) yield call(history.push, location);
    yield call(actionMessage, message, status);
}

function* exploreSaga({ type, payload }) {
    const userData = yield select(getUserData);
    
    switch (type) {
        case EXPLORE_MERCHANT:
            try {
                yield initRequest();

                const response = yield call(searchMerchantAPI,
                    userData.userID, 
                    userData.lat, 
                    userData.lon, 
                    userData.city,
                    userData.host,
                    userData.name,
                    userData.addressName, 
                    payload.searchKey,
                    payload.pageSize
                );
                console.log(response);

                if (response.result.length === 0) {
                    handleError('No merchants found.');
                } else {
                    yield put(exploreMerchantSuccess({
                        explore: response.result,
                        total: response.total
                    }));
                    yield put(setRequestStatus(''));
                }

                yield put(setLoading(false));
            } catch (error) {
                console.log(error);
                yield handleError(error);
                yield handleAction(undefined, 'Something went wrong', 'error');
            }
            break;
        default: { throw new Error(`Unexpected action type ${type}`); }
    }
}

export default exploreSaga;