import React from "react";
import MerchantMenuCategory from "./MerchantMenuCategory";

import "./Merchant.css";
import { t } from "../../miscellaneous/language";
import { Swiper, SwiperSlide } from "swiper/react";
import MerchantMenuList from "./MerchantMenuList";
import MerchantMenuListLoading from "../components/common/Loading/MerchantMenuListLoading";
import { categoryItemShowFilter} from "../../miscellaneous/helpers/utils";

const MerchantMenuContainer = ({ items, detailFunction, search }) => {
  console.log(items);
  const [indexCat, setIndexCat] = React.useState(0);
  const [menu, setMenu] = React.useState(
    !items ? null : items.length > 0 && items.filter((catItem) => categoryItemShowFilter({category: catItem}))[indexCat].items
  );
  const [scrolled, setIsScrolled] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);

  // console.log(menu, indexCat)
  React.useEffect(() => {
    if (items && items.length > 0) {
      setMenu(items.filter((catItem) => categoryItemShowFilter({category: catItem}))[indexCat].items);
    }
  }, [indexCat, items]);

  const handleScroll = () => {
    const offset = window.scrollY;
    console.log(offset);
    if (offset > 515) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  let x = [
    !search
      ? "merchant-menu-container merchant-menu-container-absolute"
      : "merchant-menu-container merchant-menu-container-absolute",
  ];
  if (scrolled) {
    x.splice(
      x.findIndex(
        (className) =>
          className ===
          "merchant-menu-container merchant-menu-container-absolute"
      ),
      1,
      "merchant-menu-container merchant-menu-container-fixed"
    );
  }
  return (
    <>
      <div className={x.join(" ")}>
        <Swiper
          breakpoints={{
            500: {
              // width: 500,
              slidesPerView: 5,
            },
            425: {
              // width: 425,
              slidesPerView: 4,
            },
            375: {
              // width: 375,
              slidesPerView: 4,
            },
            320: {
              // width: 320,
              slidesPerView: 3,
            },
            280: {
              // width: 280,
              slidesPerView: 2,
            },
          }}
        >
          {!items ? (
            // <span>{t.loading}</span>
            <div className="merchant-menu-category-loading">
              <div className="merchant-menu-category-loading-component loading"></div>
              <div className="merchant-menu-category-loading-component loading"></div>
              <div className="merchant-menu-category-loading-component loading"></div>
              <div className="merchant-menu-category-loading-component loading"></div>
            </div>
          ) : (
            items?.filter((catItem) => categoryItemShowFilter({category:catItem})).map((catItems, index) => (
              <SwiperSlide>
                <MerchantMenuCategory
                  active={index === indexCat}
                  onClick={() => {
                    setIndexCat(index);
                    setIsLoaded(false);
                  }}
                  items={catItems}
                  detailFunction={detailFunction}
                />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
      <div
        style={{
          backgroundColor: "#ffffff",
          borderTopRightRadius: 8,
          borderTopLeftRadius: 8,
          width: "100%",
          minHeight: "calc(100vh - 200px)",
          paddingTop: 70,
        }}
      >
        <div
          className="menu-menu-list-container"
          style={{ height: "100%", marginBottom: 60 }}
        >
          {!items ? (
            // <span>{t.loading}</span>
            <>
              <MerchantMenuListLoading />
              <MerchantMenuListLoading />
              <MerchantMenuListLoading />
              <MerchantMenuListLoading />
            </>
          ) : (
            menu?.length !== 0 &&
            menu?.map((menuItem) => (
              <MerchantMenuList
                item={menuItem}
                detailFunction={detailFunction}
                categoryName={items[indexCat].name}
                loadFunction={{
                  isLoaded: isLoaded,
                  handleImageLoad: handleImageLoad,
                }}
              />
            ))
          )}
          {menu?.length === 0 && (
            <div className="merchant-menu-list-container">
              <div className="merchant-menu-list-wrapper">&nbsp; Not found</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MerchantMenuContainer;
