import React from "react";
import { Helmet } from "react-helmet";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { setRequestStatus } from "../../application/actions/miscActions";
import {
  searchMerchant,

  setTypeState,
  setIsLoadMore,

  clearFilterNearMeCategoryState,
} from "../../application/actions/merchantActions";

import {

  useCategory,

  useDidMount,
} from "../../application/hooks";

import {
  HomePageTopNav,
  BottomNav,

  BottomSpace,
  MerchantSearch,
  LanguageChooser,

} from "../components/common";

import {

  CategoryListContainer,
  MerchantList,
  MerchantItem,
  SearchMerchantItem,
  FilterNearestMerchantItem,
  FilterDistrictMerchantItem,
} from "../";



import "../../App.css";
import "./style.css";

import Banner from "../Promo/PromoDashboard/Banner";
import { getPromoMerchants } from "../../application/actions/promoActions";
import MerchantLoadList from "../Merchant/MerchantsLoadList";
import { t } from "../../miscellaneous/language";


const HomeContainer = () => {
  const dispatch = useDispatch();
  const didMount = useDidMount(true);

  const store = useSelector(
    (state) => ({
      merchants: state.merchants,
      requestStatus: state.app.requestStatus,
      keyword: state.merchants.keyword,
      isLoading: state.app.loading,
      auth: state.auth,
      promo: state.promo,
      next: state.merchants.next
    }),
    shallowEqual
  );
  // console.log(store.merchants);
  // console.log(store.auth.userData.addressName, 'ini adalah userData');

  const { categories } = useCategory(
    store.auth.userData.userID,
    store.auth.userData.lat,
    store.auth.userData.lon,
    store.auth.userData.city,
    store.auth.userData.host,
    store.auth.userData.name,
    store.auth.userData.addressName
  );

  const [filterState, setFilterState] = React.useState({
    nearMe: true,
    district: false,
  });

  React.useEffect(() => {
    if (store.promo.merchants.data?.length === 0 && !store.promo.merchants.requestComplete) {
      dispatch(getPromoMerchants());
    }


    // eslint-disable-next-line
  }, [store.promo.merchants.data,
  store.promo.items.data,
    dispatch]
  );

  React.useEffect(() => {
    if (didMount && store.keyword) {
      setFilterState({ nearMe: false });
      dispatch(searchMerchant(store.keyword));
    } else if (
      filterState.nearMe ||
      (store.keyword === "" && !filterState.district)
    ) {
      setFilterState({ nearMe: true });
      dispatch(setIsLoadMore(false));
      dispatch(setTypeState("FILTER_NEAR_ME"));
    }
  }, [
    didMount,
    store.keyword,
    filterState.nearMe,
    filterState.district,
    dispatch,
  ]);

  React.useEffect(
    () => () => {
      dispatch(setRequestStatus(""));
      dispatch(clearFilterNearMeCategoryState())
      // eslint-disable-next-line
    },
    []
  );

  const renderMerchants = (type) => {
    switch (type) {
      case "SEARCH":
        return (
          <SearchMerchantItem merchants={store.merchants.searchedItems.items} />
        );
      case "DEFAULT":
        return <MerchantItem merchants={store.merchants} />;
      case "FILTER_DISTRICT":
        return (
          <FilterDistrictMerchantItem
            merchants={store.merchants.filterDistrict.items}
          />
        );
      default:
        return (
          <FilterNearestMerchantItem
            merchants={store.merchants.filterNearMe.items}
          />
        );
    }
  };
  const [LanguageModal, setLanguageModal] = React.useState(false)
  // console.log(store.isLoading)
  // console.log(store.merchants)
  return (
    <>
      <Helmet>
        <title>{t.helmet.title.Home}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={t.helmet.desc.Home} />
      </Helmet>
      <HomePageTopNav setLanguageModal={setLanguageModal} addressName={store.auth.userData?.addressName} />
      <div className="">
        <MerchantSearch />
        <CategoryListContainer items={categories?.result} />
      </div>

      <Banner merchants={store.promo.merchants?.data} />
      <MerchantList

        {...store}>
        {renderMerchants(store.merchants.type)}
        {store.isLoading ?
          <>
            <MerchantLoadList />
          </>

          :
          <div style={{ height: 120 }}>

          </div>
        }
      </MerchantList>
      {/* <MerchantLoadList/>             */}
      <BottomNav />
      <BottomSpace />
      <LanguageChooser LanguageModal={LanguageModal} setLanguageModal={setLanguageModal} />
    </>
  );
};

export default HomeContainer;


