import axios from 'axios';

const baseURL = 'https://idmarket-api.taximaxim.com/api/v1/name'; // prod
// const baseURL = 'http://localhost:8001/api/v1/name';  // dev

export const setUserdataAPI = async (userID, lat, lon, city, host, name, addressName) => {
    const res = await axios.post(baseURL, {
        userID,
        lat,
        lon,
        city,
        host,
        name,
        addressName
    });

    return res.data;
}