import React from 'react'

const AngleSmallLeft = (props) => {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <title>165 arrow small left</title>
            <path d="M10.6,12.707a1,1,0,0,1,0-1.414l4.585-4.586a1,1,0,0,0-1.414-1.414L9.189,9.879a3,3,0,0,0,0,4.242l4.586,4.586a1,1,0,0,0,1.414-1.414Z"/>
        </svg>
    )
}

export default AngleSmallLeft
