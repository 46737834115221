import {
    SET_CART_ITEMS,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    CLEAR_CART,
    ADD_QTY_ITEM,
    REMOVE_QTY_ITEM,
    SET_NOTE_ITEMS,
    SEND_ORDER,
    SEND_ORDER_SUCCESS,
    REFRESH_ORDER_STATUS,
    REFRESH_ORDER_STATUS_SUCCESS,
    ADD_QTY_ITEM_ON_DETAIL,
    ADD_QTY_ITEM_ADDON,
    EDIT_QTY_ITEM_ADDON
} from '../../miscellaneous/constants';

const INITIAL_STATE = {
    merchant: null,
    items: []
}

console.log(INITIAL_STATE.items, 'log item reducer')

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_CART_ITEMS:
            return payload;
        case ADD_TO_CART:
            // return state.items?.some((merchantProduct) => merchantProduct.id === payload.merchantProduct.id)
            // ? {merchant: payload.merchant, items: [state.items]}
            // : 
            return (state.merchant?.id === payload.merchant.id ? {merchant: payload.merchant, items: [payload.merchantProduct, ...state.items]} : {merchant: payload.merchant, items: [payload.merchantProduct]});
        case REMOVE_FROM_CART:
            return {
                ...state,
                items: state.items.filter((merchantProduct) => merchantProduct.uuid_cart !== payload)
            };
        case ADD_QTY_ITEM:
            return {
                ...state,
                items: state.items.map((merchantProduct) => {
                    
                    if (merchantProduct.uuid_cart === payload) {
            console.log(merchantProduct.uuid_cart, payload, merchantProduct.uuid_cart === payload, 'xx')

                        return {
                            ...merchantProduct,
                            qty: merchantProduct.qty + 1
                        }
                    }
                    return merchantProduct;
                })
            };
        case ADD_QTY_ITEM_ON_DETAIL:
            // console.log(INITIAL_STATE.items, 'log item reducer')
            return {
                ...state,
                items: state.items.map((merchantProduct) => {
                    console.log(payload, 'log item on detail reducer')
                    if (merchantProduct.uuid_cart === payload.id) {
                        return {
                            ...merchantProduct,
                            qty: payload.qty
                        }
                    }
                    return merchantProduct;
                })
            };
        case ADD_QTY_ITEM_ADDON:
            // console.log(INITIAL_STATE.items, 'log item reducer')
            return {
                ...state,
                items: state.items.map((merchantProduct) => {
                    console.log(payload, 'log item on detail reducer')
                    if (merchantProduct.uuid_cart === payload.id) {
                        return {
                            ...merchantProduct,
                            qty: merchantProduct.qty+ payload.qty
                        }
                    }
                    return merchantProduct;
                })
            };
        case EDIT_QTY_ITEM_ADDON:
            // console.log(INITIAL_STATE.items, 'log item reducer')
            return {
                ...state,
                items: state.items.map((merchantProduct) => {
                    console.log(payload, 'log item on detail reducer')
                    if (merchantProduct.uuid_cart === payload.id) {
                        return {
                            ...merchantProduct,
                            qty: payload.qty,
                            addonChoosed: payload.addon,
                            total_with_addon : payload.total_price
                        }
                    }
                    return merchantProduct;
                })
            };
        case REMOVE_QTY_ITEM:
            console.log(state.items, payload, 'remove sss')

            return {
                ...state,
                items: state.items.map((merchantProduct) => {
                    if (merchantProduct.uuid_cart === payload) {
                        return {
                            ...merchantProduct,
                            qty: merchantProduct.qty - 1
                        }
                    }
                    return merchantProduct;
                })
            };
        case SET_NOTE_ITEMS:
            return {
                ...state,
                items: state.items.map((merchantProduct) => {
                    if (merchantProduct.uuid_cart === payload.id) {
                        return {
                            ...merchantProduct,
                            orderDesc: payload.note
                        }
                    }
                    return merchantProduct;
                })
            };
        case SEND_ORDER:
            return {
                ...state,
                orders: payload
            };
        case REFRESH_ORDER_STATUS_SUCCESS:
        case SEND_ORDER_SUCCESS:
            return {
                ...state,
                items: [],
                orders_response: payload
            }
        case REFRESH_ORDER_STATUS:
            return {
                ...state
            }
        case CLEAR_CART:
            return {
                ...state,
                items: []
            };
        default: return state;
    }
}