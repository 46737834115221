import {
    GET_HISTORY,
    GET_HISTORY_SUCCESS,
    CLEAR_HISTORY_STATE,
    UPDATE_HISTORY_RATING
} from '../../miscellaneous/constants';

export const getHistory = (pageSize = 1) => ({
    type: GET_HISTORY,
    payload: { pageSize }
});

export const getHistorySuccess = (history) => ({
    type: GET_HISTORY_SUCCESS,
    payload: history
});

export const clearHistoryState = () => ({
    type: CLEAR_HISTORY_STATE
});

export const updateHistoryRating = (starValue, orderId) => ({
    type: UPDATE_HISTORY_RATING,
    payload: { starValue, orderId }
})