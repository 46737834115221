import React from 'react'
import PropType from 'prop-types';
import Rating from 'react-rating';
import { Link } from 'react-router-dom';

import { formatCurrency } from '../../miscellaneous/helpers/utils';

import {
    MerchantImage,
    Star,
    StarBorder
} from '../../assets'

import Littlestar from '../../assets/icons/rating.svg';
import Tag from '../../assets/icons/prices.svg';
import Ratings from '../../assets/icons/rating25.svg';

import { useHistory } from 'react-router-dom';

import './History.css'
import { t } from '../../miscellaneous/language';

const HistoryItem = ({ data, updateRating }) => {
    const history = useHistory();

    const handleClick = (value, id) => {
        if (value === "redirect") {
            history.push(`/historydetails/` + id)
            // window.location = `/historydetails/` + id;
        }
        else {
            updateRating(value, id);
        }
    }

    const statusType = (type) => {
        switch (type) {
            case 0: {
                return t.status.case0
            }
            case 1: {
                return t.status.case1
            }
            case 2: {
                return t.status.case2
            }
            case 3: {
                return t.status.case3
            }
            case 4: {
                return t.status.case4
            }
            case 11: {
                return t.status.case11
            }
            case 9: {
                return t.status.case9
            }
            case 99: {
                return t.status.case9
            }
            default: { return t.status.caseNotFound }
        }
    }

    const jumpout = (data) => {
        window.location.href = "/receipt/" + data + "?receipt=1";
    }

    console.log(data, 'xixixi');
    return (
        <>
            {
                data.map((data, index) => (
                    <Link to={`/receipt/${data.reg}?receipt=1`} className="link-style">
                        <div className="history-card-new">
                            <div className="history-card-wrapper">
                                <div className="history-card-header">
                                    <div className="history-card-header-left">
                                        <span>{statusType(data.status)}</span>
                                    </div>
                                    <div className="history-card-header-right">
                                        <span>{data?.total_item} {data?.total_item > 1 ? "items" : "item"}</span>
                                        <div className="history-card-content-border-vertical"></div>
                                        <span>{formatCurrency(data.total_price)}</span>
                                    </div>
                                </div>
                                <div className="history-card-content-border"></div>
                                <div className="history-card-content">
                                    <div className="history-card-content-image">
                                        <img src={data?.merchant?.image} width={52} height={52} />
                                    </div>
                                    <div className="history-card-content-text">
                                        <span>{data?.merchant?.name}</span>
                                        <div className="history-card-content-etc">
                                            <div className="history-card-content-etc-1">
                                                <img src={Littlestar} />
                                                <span>&nbsp;&nbsp;{data?.merchant?.avg_rating}</span>
                                            </div>
                                            {/* <div className="history-card-content-etc-1">
                                <img src={Tag} />
                                <span>6K - 14K</span>
                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                {data.status === 2 || data.status === 11 ?
                                    <>
                                        <div className="rating">
                                            <Rating
                                                initialRating={data.rating}
                                                emptySymbol={<StarBorder id="star-border-medium-icon" />}
                                                fullSymbol={<Star id="star-medium-icon" />}
                                                onChange={(e) => handleClick(e, data.id)}
                                                readonly={(data.rating > 0 ? true : false)}
                                            />
                                        </div>
                                        <center><span className='rating-text'>Rate the merchant</span></center>
                                    </>
                                    : ''
                                }
                            </div>
                        </div>
                    </Link>
                ))
            }
        </>

        // <>
        //     {data.map((data, index) => (
        //         <div key={index} className={data.status === 2 || data.status === 9 ? 'history-card white' : 'history-card orange'}>
        //             {/* <div className="history-item-container" onClick={() => data.status !== 9 ? handleClick('redirect', data.id) : alert(t.alert.orderCancel)}> */}
        //             <div className="history-item-container">
        //                 <div className="history-detail-image-container">
        //                     <img src={data.merchant.image} alt="" onError={(event) => event.target.src = MerchantImage} />
        //                 </div>
        //                 <div className="history-detail">
        //                     <span className="history-detail-name">{data.merchant.name}</span>
        //                     <span className="history-detail-days">{data.day}</span>
        //                     <div className="border"></div>
        //                     <div className="history-detail-item-status">
        //                         <span className="history-detail-item">{data.order_items.length} | {formatCurrency(data.total_price)}</span>
        //                         <span className="history-detail-status">{statusType(data.status)}</span>
        //                     </div>
        //                 </div>
        //             </div>
        //             {data.status === 2 || data.status === 11 ?
        //                 <div className="rating">
        //                     <Rating
        //                         initialRating={data.rating}
        //                         emptySymbol={<StarBorder id="star-border-medium-icon" />}
        //                         fullSymbol={<Star id="star-medium-icon" />}
        //                         onChange={(e) => handleClick(e, data.id)}
        //                         readonly={(data.rating > 0 ? true : false)}
        //                     /> 
        //                 </div>
        //                 : ''
        //             }   
        //         </div>
        //     ))}
        // </>
    )
}

HistoryItem.propTypes = {
    data: PropType.array.isRequired
}

export default HistoryItem