import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { BottomSpace, ReceiptTopNav } from "../components/common";

import { Receipt } from "../";
import { usePublicLink } from "../../application/hooks";
import { t } from "../../miscellaneous/language";
import { confirmAlert } from "react-confirm-alert";
import HistoryTopNav from "../components/common/TopNav/HistoryTopNav";

const ReceiptContainer = ({ match }) => {
  const { orderId } = match.params;
  const { orderData } = usePublicLink(orderId);

  console.log(orderData?.data.merchant.partner_level == 0);

  const checkMerchhantPartner = () => {
    if (orderData?.data.merchant.partner_level == 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert">
              <h1>{t.alert.nonPartner}</h1>
              <p>{t.alert.noteNonPartnerReceipt}</p>
              <div className="alert-button-container">
                <button className="yes-button-full" onClick={onClose}>
                  {t.btn.yes}
                </button>
              </div>
            </div>
          );
        },
      });
    }
    if (orderData?.data.merchant.partner_level == 2) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert">              
              <p>{t.alert.partnerPlus}</p>
              <div className="alert-button-container">
                <button className="yes-button-full" onClick={onClose}>
                  {t.btn.yes}
                </button>
              </div>
            </div>
          );
        },
      });
    }
  };

  useEffect(() => {
    console.log("render");
    checkMerchhantPartner();
  }, [orderData]);

  return (
    <div>
      <Helmet>
        <title>{"Foods&Goods - Struk Pemesanan"}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={"Struk Pemesanan"} />
      </Helmet>
      <ReceiptTopNav title={"Receipt"} />
      <Receipt orderData={orderData} />
      <BottomSpace />
    </div>
  );
};

export default ReceiptContainer;
