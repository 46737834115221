import {
    EXPLORE_MERCHANT,
    EXPLORE_MERCHANT_KEYWORD,
    EXPLORE_MERCHANT_SUCCESS,
    CLEAR_EXPLORE_STATE
} from '../../miscellaneous/constants';

export const exploreMerchantKeyword = (keyword) => ({
    type: EXPLORE_MERCHANT_KEYWORD,
    payload: { keyword }
});

export const exploreMerchant = (searchKey, pageSize = 1) => ({
    type: EXPLORE_MERCHANT,
    payload: { searchKey, pageSize }
});
  
export const exploreMerchantSuccess = (merchants) => ({
    type: EXPLORE_MERCHANT_SUCCESS,
    payload: merchants
});
  
export const clearExploreState = () => ({
    type: CLEAR_EXPLORE_STATE
});