import React from 'react'

const Calendar = (props) => {
    return (
        <svg id={props.id} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39">
            <path d="M30.875,3.25H29.25V1.625a1.625,1.625,0,0,0-3.25,0V3.25H13V1.625a1.625,1.625,0,0,0-3.25,0V3.25H8.125A8.135,8.135,0,0,0,0,11.375v19.5A8.135,8.135,0,0,0,8.125,39h22.75A8.135,8.135,0,0,0,39,30.875v-19.5A8.135,8.135,0,0,0,30.875,3.25Zm0,32.5H8.125A4.875,4.875,0,0,1,3.25,30.875V16.25h32.5V30.875A4.875,4.875,0,0,1,30.875,35.75Z"/>
            <circle cx="2.438" cy="2.438" r="2.438" transform="translate(17.063 21.938)"/>
            <circle cx="2.438" cy="2.438" r="2.438" transform="translate(8.938 21.938)"/>
            <circle cx="2.438" cy="2.438" r="2.438" transform="translate(25.188 21.938)"/>
        </svg>
    )
}

export default Calendar
